import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { GET_FORECAST, GET_CALENDAR_ATTENDENCE, SUBMIT_ABSENCE, UPDATE_ABSENCE, GET_USER_ATTENDANCE_STATS, GET_USER_ATTENDANCE, CLEAR_USER_ATTENDANCE, CLEAR_FORECAST, DELETE_REPORTED_ATTENDANCE } from 'actions/absence';
import Moment from 'moment';
var INITIAL_STATE = {
  forecast: null,
  calendarAttendence: null,
  submitResult: false,
  userStatistics: null,
  userAttendance: null
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case GET_FORECAST:
      return _objectSpread(_objectSpread({}, state), {}, {
        forecast: action.payload
      });

    case CLEAR_FORECAST:
      return _objectSpread(_objectSpread({}, state), {}, {
        forecast: null
      });

    case SUBMIT_ABSENCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        submitResult: action.payload
      });

    case UPDATE_ABSENCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        submitResult: action.payload
      });

    case GET_CALENDAR_ATTENDENCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        calendarAttendence: action.payload
      });

    case GET_USER_ATTENDANCE:
      if (action.payload != null) {
        var attendance = action.payload;
        attendance.sort(function (a, b) {
          return new Date(b.startTime) - new Date(a.startTime);
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          userAttendance: attendance
        });
      }

      return state;

    case CLEAR_USER_ATTENDANCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        userAttendance: null
      });

    case GET_USER_ATTENDANCE_STATS:
      var data = {
        labels: [],
        datasets: []
      };
      var absent = {
        borderColor: "rgba(242, 82, 82, 1)",
        pointBackgroundColor: "rgba(242, 82, 82, 1)",
        backgroundColor: "rgba(242, 82, 82, 1)",
        label: 'minutes-absence',
        data: []
      };
      var attending = {
        borderColor: "rgba(25, 219, 108, 1)",
        pointBackgroundColor: "rgba(25, 219, 108, 1)",
        backgroundColor: "rgba(25, 219, 108, 1)",
        label: 'minutes-attending',
        data: []
      };
      var notReported = {
        borderColor: "rgba(126, 195, 221, 1)",
        pointBackgroundColor: "rgba(126, 195, 221, 1)",
        backgroundColor: "rgba(126, 195, 221, 1)",
        label: 'minutes-not-reported',
        data: []
      };
      action.payload.map(function (date) {
        data.labels.push(Moment(date.date).format('YYYY-MM-DD'));
        absent.data.push(date.absent);
        attending.data.push(date.attending);
        notReported.data.push(date.notReported);
      });
      data.datasets.push(absent);
      data.datasets.push(attending);
      data.datasets.push(notReported);
      return _objectSpread(_objectSpread({}, state), {}, {
        userStatistics: data
      });

    case DELETE_REPORTED_ATTENDANCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        calendarAttendance: null
      });

    default:
      return state;
  }
}