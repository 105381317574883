import api from 'lib/api';
export var GET_CURRENT_MEETINGS = 'MEETINGS_GET_MEETINGS';
export var GET_PAST_MEETINGS = 'MEETINGS_GET_PAST_MEETINGS';
export var GET_USER_MEETINGS = 'MEETINGS_GET_USER_MEETINGS';
export var GET_MEETING = 'MEETINGS_GET_SINGLE_MEETING';
export var CREATE_MEETING = 'MEETINGS_CREATE_MEETING';
export var GET_MEETING_INSTANCE = 'GET_MEETING_INSTANCE';
export var MEETING_INSTANCE_UPDATE = 'MEETING_INSTANCE_UPDATE';
export var MEETING_ADD_INSTANCES = 'MEETING_ADD_INSTANCES';
export var MEETING_MEETING_UPDATE = 'MEETING_MEETING_UPDATE';
export var ADD_MEETING_INSTANCE_RELATIONSHIP = 'MEETING_ADD_INSTANCE_RELATIONSHIP';
export var DELETE_MEETING_INSTANCE_RELATIONSHIP = 'DELETE_MEETING_INSTANCE_RELATIONSHIP';
export var MEETING_INSTANCE_UPDATE_DATE = 'MEETING_INSTANCE_UPDATE_DATE';
export var CREATE_MEETING_FORM_INSTANCE = 'CREATE_MEETING_FORM_INSTANCE';
export var MEETING_INSTANCE_SEND_REMINDER = 'MEETING_INSTANCE_SEND_REMINDER';
export var DELETE_MEETING_RELATIONSHIP = 'DELETE_MEETING_RELATIONSHIP';
export var CLEAR_MEETING = 'CLEAR_MEETING';
export var SAVE_WIZARD_MEETING = 'MEETINGS_SAVE_WIZARD_MEETING';
export var CLEAR_WIZARD_MEETING = 'MEETINGS_CLEAR_WIZARD_MEETING';
export var DELETE_MEETING_INSTANCE_PARTICIPANT = 'MEETINGS_DELETE_MEETING_INSTANCE_PARTICIPANT';
export var DELETE_MEETING = 'MEETINGS_DELETE_MEETING';
export var DELETE_MEETING_INSTANCE = 'MEETINGS_DELETE_MEETING_INSTANCE';
export var createMeeting = function createMeeting(meeting) {
  return {
    type: CREATE_MEETING,
    payload: new Promise(function (resolve) {
      api.post("meetings", meeting).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getCurrentMeetings = function getCurrentMeetings() {
  return {
    type: GET_CURRENT_MEETINGS,
    payload: new Promise(function (resolve) {
      api.get("meetings?filter=active").then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getPastMeetings = function getPastMeetings() {
  return {
    type: GET_PAST_MEETINGS,
    payload: new Promise(function (resolve) {
      api.get("meetings?filter=inactive").then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getUserMeetings = function getUserMeetings(user, start, end) {
  return {
    type: GET_USER_MEETINGS,
    payload: new Promise(function (resolve) {
      var url = "meetings?studentId=".concat(user, "&start=").concat(start);

      if (end != null) {
        url += "&end=".concat(end);
      }

      api.get(url).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getMeeting = function getMeeting(id) {
  return {
    type: GET_MEETING,
    payload: new Promise(function (resolve) {
      api.get("meetings/".concat(id)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getMeetingInstance = function getMeetingInstance(id, instanceId) {
  return {
    type: GET_MEETING_INSTANCE,
    payload: new Promise(function (resolve) {
      api.get("meetings/".concat(id, "/instance/").concat(instanceId)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var updateMeetingInstance = function updateMeetingInstance(instance) {
  return {
    type: MEETING_INSTANCE_UPDATE,
    payload: new Promise(function (resolve) {
      api.put('meetings/instance', instance).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var updateMeetingInstanceDate = function updateMeetingInstanceDate(instance, onError) {
  return {
    type: MEETING_INSTANCE_UPDATE_DATE,
    payload: new Promise(function (resolve, reject) {
      api.put('meetings/instance/date', instance).then(function (response) {
        resolve(response.data);
      })["catch"](function (reason) {
        if (onError != null) {
          onError(reason);
        }
      });
    })
  };
};
export var updateMeeting = function updateMeeting(values) {
  return {
    type: GET_MEETING,
    payload: new Promise(function (resolve) {
      api.put('meetings', values).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var addMeetingInstances = function addMeetingInstances(id, instances) {
  return {
    type: MEETING_ADD_INSTANCES,
    payload: new Promise(function (resolve) {
      api.post("meetings/".concat(id, "/instances"), instances).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var sendMeetingInstanceTimeBookingReminder = function sendMeetingInstanceTimeBookingReminder(id, instanceId) {
  return {
    type: MEETING_INSTANCE_SEND_REMINDER,
    payload: new Promise(function (resolve) {
      api.post("meetings/".concat(id, "/instance/").concat(instanceId, "/reminder"), {}).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var createMeetingInstanceRelationship = function createMeetingInstanceRelationship(meeting, instance, relation) {
  return {
    type: ADD_MEETING_INSTANCE_RELATIONSHIP,
    payload: new Promise(function (resolve) {
      api.post("meetings/".concat(meeting, "/instance/").concat(instance, "/relationships"), relation).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var deleteMeetingInstanceRelationship = function deleteMeetingInstanceRelationship(meetingId, instanceId, relationshipId) {
  return {
    type: DELETE_MEETING_INSTANCE_RELATIONSHIP,
    payload: new Promise(function (resolve) {
      api["delete"]("meetings/".concat(meetingId, "/instance/").concat(instanceId, "/relationship/").concat(relationshipId)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var deleteMeetingRelationship = function deleteMeetingRelationship(meetingId, relationshipId) {
  return {
    type: DELETE_MEETING_RELATIONSHIP,
    payload: new Promise(function (resolve) {
      api["delete"]("meetings/".concat(meetingId, "/relationship/").concat(relationshipId)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var deleteMeetingInstanceParticipant = function deleteMeetingInstanceParticipant(meetingId, instanceId, participantId) {
  return {
    type: DELETE_MEETING_INSTANCE_PARTICIPANT,
    payload: new Promise(function (resolve) {
      api["delete"]("meetings/".concat(meetingId, "/instance/").concat(instanceId, "/participant/").concat(participantId)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var deleteMeeting = function deleteMeeting(meetingId) {
  return {
    type: DELETE_MEETING,
    payload: new Promise(function (resolve) {
      api["delete"]("meetings/".concat(meetingId)).then(function (response) {
        if (response.status == 200) {
          resolve(meetingId);
        } else {
          resolve(false);
        }
      });
    })
  };
};
export var deleteMeetingInstance = function deleteMeetingInstance(meetingId, instanceId) {
  return {
    type: DELETE_MEETING_INSTANCE,
    payload: new Promise(function (resolve) {
      api["delete"]("meetings/".concat(meetingId, "/instance/").concat(instanceId)).then(function (response) {
        if (response.status == 200) {
          resolve(instanceId);
        } else {
          resolve(false);
        }
      });
    })
  };
};
export var createMeetingFormInstance = function createMeetingFormInstance(meetingId, instanceId, instance) {
  return {
    type: CREATE_MEETING_FORM_INSTANCE,
    payload: new Promise(function (resolve) {
      api.post("meetings/".concat(meetingId, "/instance/").concat(instanceId, "/formInstance"), instance).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var clearMeeting = function clearMeeting() {
  return {
    type: CLEAR_MEETING,
    payload: null
  };
};
export var saveWizardMeeting = function saveWizardMeeting(meeting) {
  return {
    type: SAVE_WIZARD_MEETING,
    payload: meeting
  };
};
export var clearWizardMeeting = function clearWizardMeeting() {
  return {
    type: CLEAR_WIZARD_MEETING,
    payload: true
  };
};