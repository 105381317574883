import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import api from 'lib/api';
export var SUBMIT_PRESCHOOL_DOCUMENT = 'SUBMIT_PRESCHOOL_DOCUMENT';
export var UPDATE_PRESCHOOL_DOCUMENT = 'UPDATE_PRESCHOOL_DOCUMENT';
export var SET_ACTIVE_PRESCHOOL_DOCUMENT = 'SET_ACTIVE_PRESCHOOL_DOCUMENT';
export var SET_ACTIVE_PRESCHOOL_DETAILS = 'SET_ACTIVE_PRESCHOOL_DETAILS';
export var GET_PRESCHOOL_DOCUMENTS = 'GET_PRESCHOOL_DOCUMENTS';
export var GET_DOCUMENTS_PAGE = 'GET_DOCUMENTS_BY_PAGE';
export var GET_SECTION_DOCUMENTS = 'GET_SECTION_DOCUMENTS';
export var GET_PLAN_DOCUMENTS = 'GET_PLAN_DOCUMENTS';
export var CLEAR_DOCUMENTS_FROM_STORE = 'CLEAR_DOCUMENTS_FROM_STORE';
export var CLEAR_SECTION_DOCUMENTS_FROM_STORE = 'CLEAR_SECTION_DOCUMENTS_FROM_STORE';
export var DELETE_DOCUMENT_FROM_STORE = 'DELETE_DOCUMENT_FROM_STORE';
export var DELETE_DOCUMENT = 'DOCUMENT_DELETE_DOCUMENT';
export var GET_DOCUMENT_READ_LIST = "GET_DOCUMENT_READ_LIST";
export var CLEAR_DOCUMENT_READ_LIST = "CLEAR_DOCUMENT_READ_LIST";
export var DELETE_DOCUMENTATION_FILE = 'DOCUMENTATION_DELETE_FILE';
export function submit_preschooldocument(values, callback) {
  return {
    type: SUBMIT_PRESCHOOL_DOCUMENT,
    payload: new Promise(function (resolve) {
      api.post('documentations', values).then(function (response) {
        resolve(_objectSpread(_objectSpread({}, values), {}, {
          id: response.data
        }));

        if (callback != null) {
          callback(response.data);
        }
      });
    })
  };
}
export function update_preschooldocument(values, callback) {
  return {
    type: UPDATE_PRESCHOOL_DOCUMENT,
    payload: new Promise(function (resolve) {
      api.put('documentations', values).then(function (response) {
        resolve(values);

        if (callback != null) {
          callback(response.data);
        }
      });
    })
  };
}
export function setActivePreschoolDocument(id) {
  return [{
    type: SET_ACTIVE_PRESCHOOL_DOCUMENT,
    id: id
  }, {
    type: SET_ACTIVE_PRESCHOOL_DETAILS,
    payload: new Promise(function (resolve) {
      if (id != null) {
        api.get("documentations/".concat(id)).then(function (response) {
          resolve(response.data);
        });
      }
    })
  }];
}
export function getPreschoolDetails(id) {
  return {
    type: SET_ACTIVE_PRESCHOOL_DETAILS,
    payload: new Promise(function (resolve, reject) {
      if (typeof id !== 'undefined' && id !== null) {
        api.get("documentations/".concat(id)).then(function (response) {
          if (response.status > 201) {
            reject(response);
            return true;
          }

          resolve(response.data);
        });
      } else {
        reject();
      }
    })
  };
}
export function getPreschoolDocuments() {
  return {
    type: GET_PRESCHOOL_DOCUMENTS,
    payload: new Promise(function (resolve) {
      api.get("documentations").then(function (response) {
        resolve(response.data);
      });
    })
  };
}
export function deleteDocumentFromStore(id) {
  return {
    type: DELETE_DOCUMENT_FROM_STORE,
    payload: id
  };
}
export function getDocumentsBySectionId(id, page) {
  var status = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var startDate = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var endDate = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
  var pageSize = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 15;
  return {
    type: GET_SECTION_DOCUMENTS,
    payload: new Promise(function (resolve) {
      var filter = '&$filter=';

      if (status != null) {
        filter += "eq(D.Status, ".concat(status, ")");
      }

      if (startDate != null) {
        if (filter.length > 10) {
          filter += ' and ';
        }

        filter += "D.Created > ".concat(startDate);
      }

      if (endDate != null) {
        if (filter.length > 10) {
          filter += ' and ';
        }

        filter += "D.Created < ".concat(endDate);
      }

      if (page != null) {
        api.get("documentations/section/".concat(id, "?$page=").concat(page, "&$pagesize=").concat(pageSize, "&$orderby=D.Created desc").concat(filter)).then(function (response) {
          resolve(response.data);
        });
      } else {
        api.get("documentations/section/".concat(id)).then(function (response) {
          resolve(response.data);
        });
      }
    })
  };
}
export function clearSectionDocumentsFromStore() {
  return {
    type: CLEAR_SECTION_DOCUMENTS_FROM_STORE,
    payload: true
  };
}
export function getDocumentsByPlanId(id) {
  return {
    type: GET_PLAN_DOCUMENTS,
    payload: new Promise(function (resolve) {
      api.get("documentations?$filter=eq(D.PlanId,".concat(id, ")&$orderby=D.Created desc")).then(function (response) {
        resolve(response.data);
      });
    })
  };
}
export var getDocumentReadList = function getDocumentReadList(documentationId) {
  return {
    type: GET_DOCUMENT_READ_LIST,
    payload: new Promise(function (resolve) {
      api.get("documentations/".concat(documentationId, "/reads")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var clearDocumentReadList = function clearDocumentReadList() {
  return {
    type: CLEAR_DOCUMENT_READ_LIST,
    payload: true
  };
};
export function getDocumentsPage(page) {
  var status = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var startDate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var endDate = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var pageSize = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 15;
  return {
    type: GET_DOCUMENTS_PAGE,
    payload: new Promise(function (resolve) {
      var filter = '&$filter=';

      if (status != null) {
        filter += "eq(D.Status, ".concat(status, ")");
      }

      if (startDate != null) {
        if (filter.length > 10) {
          filter += ' and ';
        }

        filter += "D.Created > ".concat(startDate);
      }

      if (endDate != null) {
        if (filter.length > 10) {
          filter += ' and ';
        }

        filter += "D.Created < ".concat(endDate);
      }

      api.get("documentations?$page=".concat(page, "&$pagesize=").concat(pageSize, "&$orderby=D.Created desc").concat(filter)).then(function (response) {
        resolve(response.data);
      });
    })
  };
}
;
export var deleteDocument = function deleteDocument(id) {
  return {
    type: DELETE_DOCUMENT,
    payload: new Promise(function (resolve) {
      api["delete"]("documentations/".concat(id)).then(function (response) {
        resolve(id);
      });
    })
  };
};
export var clearDocumentsFromStore = function clearDocumentsFromStore() {
  return {
    type: CLEAR_DOCUMENTS_FROM_STORE,
    payload: []
  };
};
export var deleteDocumentationFile = function deleteDocumentationFile(documentationId, fileId) {
  return {
    type: DELETE_DOCUMENTATION_FILE,
    payload: new Promise(function (resolve) {
      api["delete"]("documentations/".concat(documentationId, "/files/").concat(fileId)).then(function () {
        resolve(fileId);
      });
    })
  };
};