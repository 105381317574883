import api from 'lib/api';
import { loginRequest, getToken, http } from 'lib/msal';
export var getFile = function getFile(id, callback) {
  return {
    type: 'get_file',
    payload: new Promise(function (resolve) {
      api.get("fileItems/".concat(id)).then(function (response) {
        callback(response.data);
        resolve(response.data);
      });
    })
  };
};
export var getFileUrl = function getFileUrl(id, callback) {
  return {
    type: 'get_file_url',
    payload: new Promise(function (resolve) {
      // TODO: Test this
      var url = http.defaults.baseURL + "fileItems/".concat(id);
      getToken(loginRequest).then(function (response) {
        url += "?token=".concat(response.accessToken);
        resolve(url);
        callback(url);
      });
    })
  };
};
export var getPreschoolDocumentImage = function getPreschoolDocumentImage(id, callback) {
  return {
    type: 'get_preschool_document_image',
    payload: new Promise(function (resolve) {
      api.get("fileItems/".concat(id)).then(function (response) {
        callback(response.data);
        resolve({
          id: id,
          src: response.data
        });
      });
    })
  };
};
export var deleteFile = function deleteFile(file) {
  return {
    type: 'delete_file_item',
    payload: new Promise(function (resolve) {
      api["delete"]("fileitems/".concat(file.id)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};