import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import * as actions from 'actions/ScheduleFilter';
var INITIAL_STATE = {
  allFilter: false,
  activitiesFilter: {},
  userFilter: [],
  displayMySchedule: true,
  selectedPeople: 0,
  totalPeople: 0,
  totalOwners: 0,
  totalGroups: 0,
  allPeopleSelected: false,
  ownersSelected: 0,
  groupsSelected: 0,
  selectedGroups: {},
  customGroup: [],
  storedSearch: [],
  advancedCalendarActive: false
};

var isGroupActive = function isGroupActive(group, id) {
  if (Object.keys(group).length === 0) {
    return true;
  }

  return group[id];
};

export default function () {
  var _oSearch$students, _oSearch$students2, _oSearch$owners, _oSearch$owners2, _oSearch$students3, _oSearch$students4, _oSearch$owners3, _oSearch$owners4, _ref, _ref2, _action$payload$total, _action$payload$total2, _action$payload$data, _action$payload$count, _action$payload$count2, _action$payload$count3, _action$payload$count4, _action$payload2, _action$payload2$owne, _action$payload$data3, _action$payload$data4, _action$payload7, _action$payload7$data, _action$payload8, _action$payload8$sele, _action$payload9, _action$payload9$sele, _action$payload10, _action$payload10$sel;

  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actions.UPDATE_FILTER:
      if (action.payload.allData) {
        return _objectSpread(_objectSpread({}, state), {}, {
          activitiesFilter: action.payload.obj,
          allFilter: true
        });
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        activitiesFilter: _objectSpread(_objectSpread({}, state.activitiesFilter), action.payload.obj),
        allFilter: false
      });

    case actions.TOGGLE_ADVANCED_CALENDAR:
      return _objectSpread(_objectSpread({}, state), {}, {
        advancedCalendarActive: !state.advancedCalendarActive
      });

    case actions.CLEAR_FILTER:
      return _objectSpread(_objectSpread({}, state), {}, {
        activitiesFilter: action.payload.filterData,
        allFilter: false
      });

    case actions.TOGGLE_MY_SCHEDULE:
      return _objectSpread(_objectSpread({}, state), {}, {
        displayMySchedule: !state.displayMySchedule
      });

    /* handle group search function section */

    case actions.SET_TOTAL_AMOUNT:
      return _objectSpread(_objectSpread({}, state), {}, {
        totalPeople: state.totalPeople > action.payload.countTotal ? state.totalPeople : action.payload.countTotal
      });

    case actions.REMOVE_SEARCHED_GROUP:
      var oSearch = action.payload.obj;
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedPeople: state.selectedPeople - ((_oSearch$students = oSearch.students) === null || _oSearch$students === void 0 ? void 0 : _oSearch$students.length) <= 0 ? 0 : state.selectedPeople - ((_oSearch$students2 = oSearch.students) === null || _oSearch$students2 === void 0 ? void 0 : _oSearch$students2.length),
        ownersSelected: state.ownersSelected - ((_oSearch$owners = oSearch.owners) === null || _oSearch$owners === void 0 ? void 0 : _oSearch$owners.length) <= 0 ? 0 : state.ownersSelected - ((_oSearch$owners2 = oSearch.owners) === null || _oSearch$owners2 === void 0 ? void 0 : _oSearch$owners2.length),
        groupsSelected: state.groupsSelected - 1 <= 0 ? 0 : state.groupsSelected - 1,
        userFilter: state.userFilter.filter(function (x) {
          return x.id !== action.payload.group;
        }),
        storedSearch: state.storedSearch.filter(function (x) {
          return x.id !== action.payload.group;
        }),
        totalPeople: state.totalPeople - ((_oSearch$students3 = oSearch.students) === null || _oSearch$students3 === void 0 ? void 0 : _oSearch$students3.length) <= 0 ? 0 : state.totalPeople - ((_oSearch$students4 = oSearch.students) === null || _oSearch$students4 === void 0 ? void 0 : _oSearch$students4.length),
        totalOwners: state.totalOwners - ((_oSearch$owners3 = oSearch.owners) === null || _oSearch$owners3 === void 0 ? void 0 : _oSearch$owners3.length) <= 0 ? 0 : state.totalOwners - ((_oSearch$owners4 = oSearch.owners) === null || _oSearch$owners4 === void 0 ? void 0 : _oSearch$owners4.length),
        totalGroups: state.totalGroups - 1 <= 0 ? 0 : state.totalGroups - 1
      });

    case actions.TOGGLE_ALL_USERS:
      if (state.allPeopleSelected) {
        return _objectSpread(_objectSpread({}, state), {}, {
          selectedPeople: 0,
          userFilter: [],
          allPeopleSelected: false
        });
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        allPeopleSelected: !state.allPeopleSelected
      });

    case actions.SET_CUSTOM_GROUP:
      return _objectSpread(_objectSpread({}, state), {}, {
        customGroup: action.payload.group
      });

    case actions.SET_SEARCH_GROUP:
      return _objectSpread(_objectSpread({}, state), {}, {
        storedSearch: [].concat(_toConsumableArray(state.storedSearch), [action.payload.group.userId])
      });

    case actions.SET_ALL_SEARCHED:
      return _objectSpread(_objectSpread({}, state), {}, {
        userFilter: [].concat(_toConsumableArray(state.userFilter), _toConsumableArray(action.payload.data)),
        selectedPeople: (_ref = state.selectedPeople + action.payload.total) !== null && _ref !== void 0 ? _ref : 0,
        ownersSelected: (_ref2 = state.ownersSelected + action.payload.totalOwners) !== null && _ref2 !== void 0 ? _ref2 : 0,
        groupsSelected: state.groupsSelected + action.payload.data.length,
        selectedGroups: _objectSpread(_objectSpread({}, state.selectedGroups), action.payload.groupsChecked)
      });

    case actions.SET_ALL_GROUPS:
      var items = JSON.parse(JSON.stringify(action.payload.data)).map(function (section) {
        section.owners = [];
        return section;
      });

      if (action.payload.total > state.totalPeople) {
        var _ref3, _ref4, _ref5, _ref6, _ref7, _ref8;

        return _objectSpread(_objectSpread({}, state), {}, {
          userFilter: [].concat(_toConsumableArray(state.userFilter), _toConsumableArray(items)),
          customGroup: action.payload.data,
          totalPeople: (_ref3 = state.totalPeople + action.payload.total) !== null && _ref3 !== void 0 ? _ref3 : 0,
          groupsSelected: (_ref4 = state.groupsSelected + action.payload.totalGroups) !== null && _ref4 !== void 0 ? _ref4 : 0,
          selectedPeople: (_ref5 = state.selectedPeople + action.payload.total) !== null && _ref5 !== void 0 ? _ref5 : 0,
          totalGroups: (_ref6 = state.totalGroups + action.payload.totalGroups) !== null && _ref6 !== void 0 ? _ref6 : 0,
          totalOwners: (_ref7 = state.totalOwners + action.payload.totalOwners) !== null && _ref7 !== void 0 ? _ref7 : 0,
          ownersSelected: (_ref8 = state.ownersSelected + action.payload.totalOwners) !== null && _ref8 !== void 0 ? _ref8 : 0,
          selectedGroups: _objectSpread(_objectSpread({}, state.selectedGroups), action.payload.groupsChecked)
        });
      }

      if (!action.payload.totalPeople) {
        return _objectSpread(_objectSpread({}, state), {}, {
          userFilter: [].concat(_toConsumableArray(state.userFilter), _toConsumableArray(items)),
          customGroup: action.payload.data,
          selectedPeople: state.totalPeople,
          ownersSelected: state.totalOwners,
          groupsSelected: state.totalGroups,
          selectedGroups: _objectSpread(_objectSpread({}, state.selectedGroups), action.payload.groupsChecked)
        });
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        userFilter: [].concat(_toConsumableArray(state.userFilter), _toConsumableArray(items)),
        customGroup: action.payload.data,
        selectedPeople: (_action$payload$total = action.payload.total) !== null && _action$payload$total !== void 0 ? _action$payload$total : 0,
        ownersSelected: (_action$payload$total2 = action.payload.totalOwners) !== null && _action$payload$total2 !== void 0 ? _action$payload$total2 : 0,
        selectedGroups: _objectSpread(_objectSpread({}, state.selectedGroups), action.payload.groupsChecked)
      });

    case actions.ADD_SECTION:
      if ((_action$payload$data = action.payload.data) !== null && _action$payload$data !== void 0 && _action$payload$data.id) {
        var _action$payload, _action$payload$data2;

        var item = JSON.parse(JSON.stringify(action.payload.data));
        item.owners = [];
        return _objectSpread(_objectSpread({}, state), {}, {
          selectedGroups: action.payload.checkBox ? _objectSpread(_objectSpread({}, state.selectedGroups), {}, _defineProperty({}, action === null || action === void 0 ? void 0 : (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : (_action$payload$data2 = _action$payload.data) === null || _action$payload$data2 === void 0 ? void 0 : _action$payload$data2.id, true)) : state.selectedGroups,
          totalGroups: state.totalGroups + 1,
          groupsSelected: state.groupsSelected + 1,
          totalOwners: state.totalOwners + item.owners.filter(function (x) {
            return x.id !== action.payload.currentUser;
          }).length,
          selectedPeople: action.payload.checkBox ? state.selectedPeople + item.students.length : state.selectedPeople,
          totalPeople: state.totalPeople + item.students.length,
          customGroup: action.payload.search ? state.customGroup : state.customGroup.filter(function (x) {
            return x.id !== action.payload.data.id;
          }).concat(action.payload.data),
          userFilter: action.payload.checkBox ? state.userFilter.filter(function (x) {
            return x.id !== action.payload.data.id;
          }).concat(item) : state.userFilter,
          storedSearch: !action.payload.search ? state.storedSearch : state.storedSearch.filter(function (x) {
            return x.id !== action.payload.data.id;
          }).concat(action.payload.data)
        });
      }

      return state;

    case actions.SELECT_ALL_USER:
      var countStudents = (_action$payload$count = (_action$payload$count2 = action.payload.countObject) === null || _action$payload$count2 === void 0 ? void 0 : _action$payload$count2.studentsCount) !== null && _action$payload$count !== void 0 ? _action$payload$count : action.payload.newArr.students.length;
      var countOwners = (_action$payload$count3 = (_action$payload$count4 = action.payload.countObject) === null || _action$payload$count4 === void 0 ? void 0 : _action$payload$count4.ownersCount) !== null && _action$payload$count3 !== void 0 ? _action$payload$count3 : (_action$payload2 = action.payload) === null || _action$payload2 === void 0 ? void 0 : (_action$payload2$owne = _action$payload2.owners) === null || _action$payload2$owne === void 0 ? void 0 : _action$payload2$owne.length;
      return _objectSpread(_objectSpread({}, state), {}, {
        ownersSelected: countOwners ? state.ownersSelected + countOwners : state.ownersSelected,
        selectedPeople: countStudents ? state.selectedPeople + countStudents : state.selectedPeople,
        userFilter: state.userFilter.filter(function (x) {
          return x.id !== action.payload.id;
        }).concat(action.payload.newArr),
        selectedGroups: _objectSpread(_objectSpread({}, state.selectedGroups), {}, _defineProperty({}, action.payload.id, true)),
        groupsSelected: state.groupsSelected + 1
      });

    case actions.REMOVE_ALL_USER:
      if ((_action$payload$data3 = action.payload.data) !== null && _action$payload$data3 !== void 0 && (_action$payload$data4 = _action$payload$data3.students) !== null && _action$payload$data4 !== void 0 && _action$payload$data4.length) {
        var _action$payload3, _action$payload3$data, _action$payload4, _action$payload4$owne, _action$payload5, _action$payload5$owne, _action$payload6, _action$payload6$owne;

        return _objectSpread(_objectSpread({}, state), {}, {
          selectedGroups: _objectSpread(_objectSpread({}, state.selectedGroups), {}, _defineProperty({}, action === null || action === void 0 ? void 0 : (_action$payload3 = action.payload) === null || _action$payload3 === void 0 ? void 0 : (_action$payload3$data = _action$payload3.data) === null || _action$payload3$data === void 0 ? void 0 : _action$payload3$data.id, false)),
          ownersSelected: (_action$payload4 = action.payload) !== null && _action$payload4 !== void 0 && (_action$payload4$owne = _action$payload4.owners) !== null && _action$payload4$owne !== void 0 && _action$payload4$owne.length ? state.ownersSelected - ((_action$payload5 = action.payload) === null || _action$payload5 === void 0 ? void 0 : (_action$payload5$owne = _action$payload5.owners) === null || _action$payload5$owne === void 0 ? void 0 : _action$payload5$owne.length) < 0 ? 0 : state.ownersSelected - ((_action$payload6 = action.payload) === null || _action$payload6 === void 0 ? void 0 : (_action$payload6$owne = _action$payload6.owners) === null || _action$payload6$owne === void 0 ? void 0 : _action$payload6$owne.length) : state.ownersSelected,
          allPeopleSelected: false,
          selectedPeople: state.selectedPeople - action.payload.data.students.length <= 0 ? 0 : state.selectedPeople - action.payload.data.students.length,
          userFilter: _toConsumableArray(action.payload.removed),
          groupsSelected: state.groupsSelected - 1 <= 0 ? 0 : state.groupsSelected - 1
        });
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        groupsSelected: state.groupsSelected - 1 <= 0 ? 0 : state.groupsSelected - 1,
        selectedGroups: _objectSpread(_objectSpread({}, state.selectedGroups), {}, _defineProperty({}, action === null || action === void 0 ? void 0 : (_action$payload7 = action.payload) === null || _action$payload7 === void 0 ? void 0 : (_action$payload7$data = _action$payload7.data) === null || _action$payload7$data === void 0 ? void 0 : _action$payload7$data.id, false)),
        userFilter: _toConsumableArray(action.payload.removed)
      });

    case actions.CHANGE_SELECTED_PEOPLE:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedPeople: state.selectedPeople + 1,
        allPeopleSelected: false,
        selectedGroups: _objectSpread(_objectSpread({}, state.selectedGroups), {}, _defineProperty({}, action === null || action === void 0 ? void 0 : (_action$payload8 = action.payload) === null || _action$payload8 === void 0 ? void 0 : (_action$payload8$sele = _action$payload8.selectedUser) === null || _action$payload8$sele === void 0 ? void 0 : _action$payload8$sele.id, true)),
        userFilter: state.userFilter.filter(function (x) {
          return x.id !== action.payload.selectedUser.id;
        }).concat(action.payload.selectedUser),
        groupsSelected: isGroupActive(state.selectedGroups, action === null || action === void 0 ? void 0 : (_action$payload9 = action.payload) === null || _action$payload9 === void 0 ? void 0 : (_action$payload9$sele = _action$payload9.selectedUser) === null || _action$payload9$sele === void 0 ? void 0 : _action$payload9$sele.id) ? state.groupsSelected + 1 : state.groupsSelected
      });

    case actions.CHANGE_SELECTED_OWNER:
      return _objectSpread(_objectSpread({}, state), {}, {
        ownersSelected: state.ownersSelected + 1,
        allPeopleSelected: false,
        selectedGroups: _objectSpread(_objectSpread({}, state.selectedGroups), {}, _defineProperty({}, action === null || action === void 0 ? void 0 : (_action$payload10 = action.payload) === null || _action$payload10 === void 0 ? void 0 : (_action$payload10$sel = _action$payload10.selectedUser) === null || _action$payload10$sel === void 0 ? void 0 : _action$payload10$sel.id, true)),
        userFilter: state.userFilter.filter(function (x) {
          return x.id !== action.payload.selectedUser.id;
        }).concat(action.payload.selectedUser)
      });

    case actions.REMOVE_SELECTED_PEOPLE:
      if (action.payload.selectedUser.students.length === 0 && action.payload.selectedUser.owners.length === 0) {
        return _objectSpread(_objectSpread({}, state), {}, {
          allPeopleSelected: false,
          groupsSelected: state.groupsSelected - 1 <= 0 ? 0 : state.groupsSelected - 1,
          selectedGroups: _objectSpread(_objectSpread({}, state.selectedGroups), {}, _defineProperty({}, action.payload.selectedUser.id, false)),
          userFilter: state.userFilter.filter(function (x) {
            return x.id !== action.payload.selectedUser.id;
          }),
          selectedPeople: state.selectedPeople - 1 <= 0 ? 0 : state.selectedPeople - 1
        });
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        allPeopleSelected: false,
        groupsSelected: state.groupsSelected,
        selectedGroups: _objectSpread(_objectSpread({}, state.selectedGroups), {}, _defineProperty({}, action.payload.selectedUser.id, true)),
        userFilter: state.userFilter.filter(function (x) {
          return x.id !== action.payload.selectedUser.id;
        }).concat(action.payload.selectedUser),
        selectedPeople: state.selectedPeople - 1 <= 0 ? 0 : state.selectedPeople - 1
      });

    case actions.REMOVE_SELECTED_OWNER:
      if (action.payload.selectedUser.students.length === 0 && action.payload.selectedUser.owners.length === 0) {
        return _objectSpread(_objectSpread({
          allPeopleSelected: false
        }, state), {}, {
          groupsSelected: state.groupsSelected - 1 <= 0 ? 0 : state.groupsSelected - 1,
          selectedGroups: _objectSpread(_objectSpread({}, state.selectedGroups), {}, _defineProperty({}, action.payload.selectedUser.id, false)),
          userFilter: state.userFilter.filter(function (x) {
            return x.id !== action.payload.selectedUser.id;
          }),
          ownersSelected: state.ownersSelected - 1 < 0 ? 0 : state.ownersSelected - 1
        });
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        allPeopleSelected: false,
        groupsSelected: state.groupsSelected,
        selectedGroups: _objectSpread(_objectSpread({}, state.selectedGroups), {}, _defineProperty({}, action.payload.selectedUser.id, true)),
        userFilter: state.userFilter.filter(function (x) {
          return x.id !== action.payload.selectedUser.id;
        }).concat(action.payload.selectedUser),
        ownersSelected: state.ownersSelected - 1 < 0 ? 0 : state.ownersSelected - 1
      });

    case actions.REMOVE_ALL:
      return _objectSpread(_objectSpread({}, state), {}, {
        userFilter: [],
        ownersSelected: 0,
        selectedPeople: 0,
        groupsSelected: 0,
        allPeopleSelected: false,
        selectedGroups: {}
      });
    // Clear everything, used for when changing school

    case actions.CLEAR_ALL:
      return _objectSpread(_objectSpread({}, state), {}, {
        userFilter: [],
        ownersSelected: 0,
        selectedPeople: 0,
        groupsSelected: 0,
        allPeopleSelected: false,
        selectedGroups: {},
        customGroup: [],
        totalPeople: 0,
        totalOwners: 0,
        totalGroups: 0,
        storedSearch: [],
        advancedCalendarActive: false
      });

    default:
      return state;
  }
}