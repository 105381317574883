import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { GET_CONVERSATIONS, GET_CONVERSATIONS_REFERENCE, GET_CONVERSATION, GET_ARCHIVED_CONVERSATIONS, RESET_CONVERSATION, GET_CONVERSATION_MESSAGES, GET_USER_CONTACTS, SEARCH_CONVERSATIONS, CLEAR_SEARCH_CONVERSATIONS, UPDATE_CONVERSATION, ARCHIVE_CONVERSATION, GET_USER_GUARDIAN, FLAG_MESSAGE, STAGE_CONVERSATION, SUBMIT_CONVERSATION_MESSAGE, SUBMIT_CONVERSATION } from 'actions/conversation';
var INITIAL_STATE = {
  conversations: null,
  conversation: null,
  archived: null,
  messages: [],
  guardians: [],
  contacts: null,
  staged: null,
  "new": null,
  searchResults: null,
  referenceConversations: null
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case GET_CONVERSATIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        conversations: action.payload
      });

    case GET_CONVERSATIONS_REFERENCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        referenceConversations: action.payload
      });

    case GET_CONVERSATION:
      return _objectSpread(_objectSpread({}, state), {}, {
        conversation: action.payload
      });

    case GET_ARCHIVED_CONVERSATIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        archived: action.payload
      });

    case GET_CONVERSATION_MESSAGES:
      if (action.payload != null && action.payload.length > 0) {
        var _state$messages;

        return _objectSpread(_objectSpread({}, state), {}, {
          messages: (_state$messages = state.messages).unshift.apply(_state$messages, _toConsumableArray(action.payload))
        });
      }

      return state;

    case SUBMIT_CONVERSATION_MESSAGE:
      if (state.conversation != null) {
        var conversationMessage = _objectSpread({}, state.conversation);

        conversationMessage.messages.push(action.payload);
        return _objectSpread(_objectSpread({}, state), {}, {
          conversation: conversationMessage
        });
      }

      return state;

    case GET_USER_CONTACTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        contacts: action.payload
      });

    case SEARCH_CONVERSATIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        searchResults: action.payload
      });

    case CLEAR_SEARCH_CONVERSATIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        searchResults: INITIAL_STATE.searchResults
      });

    case STAGE_CONVERSATION:
      return _objectSpread(_objectSpread({}, state), {}, {
        staged: action.payload
      });

    case SUBMIT_CONVERSATION:
      return _objectSpread(_objectSpread({}, state), {}, {
        "new": action.payload
      });

    case UPDATE_CONVERSATION:
      var update = _objectSpread({}, state.conversations);

      var found = update.findIndex(function (conversation) {
        return conversation.id == action.payload.id;
      });

      if (found != null) {
        update.splice(found, 1, action.payload);
        return _objectSpread(_objectSpread({}, state), {}, {
          conversations: update
        });
      }

      return state;

    case ARCHIVE_CONVERSATION:
      var remove = _toConsumableArray(state.conversations);

      var archived = _toConsumableArray(state.archived);

      var foundConversation = remove.findIndex(function (conversation) {
        return conversation.id == action.payload.id;
      });

      if (archived == null) {
        archived = [];
      }

      if (foundConversation != null) {
        remove.splice(foundConversation, 1);
        archived.push(action.payload);
        return _objectSpread(_objectSpread({}, state), {}, {
          conversations: remove,
          archived: archived
        });
      }

      return state;

    case RESET_CONVERSATION:
      return _objectSpread(_objectSpread({}, state), {}, {
        conversation: INITIAL_STATE.conversation
      });

    case FLAG_MESSAGE:
      if (state.conversation != null) {
        if (state.conversation.messages != null && state.conversation.messages.length > 0) {
          var conversation = _objectSpread({}, state.conversation);

          var foundMessage = conversation.messages.findIndex(function (message) {
            return message.id == action.payload.messageId;
          });

          if (foundMessage > -1) {
            var newMessage = _objectSpread({}, conversation.messages[foundMessage]);

            newMessage.flagID = action.payload.flagID;
            newMessage.text = '';
            conversation.messages.splice(foundMessage, 1, newMessage);
            return _objectSpread(_objectSpread({}, state), {}, {
              conversation: conversation
            });
          }
        }
      }

      return state;

    case GET_USER_GUARDIAN:
      var currentGuardians = state.guardians;
      var foundInGuardians = currentGuardians.findIndex(function (userGuardians) {
        return userGuardians.userId == action.payload.userId;
      });

      if (foundInGuardians > -1) {
        currentGuardians.splice(foundInGuardians, 1, action.payload);
      } else {
        currentGuardians.push(action.payload);
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        guardians: currentGuardians
      });

    default:
      return state;
  }
}