import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { CLEAR_NOTIFICATIONS_FROM_STORE, GET_NOTIFICATIONS, GET_USER_UNREAD_NOTIFICATIONS, GET_NOTIFICATION_SETTINGS, GET_USER_NOTIFICATION_SETTINGS } from 'actions/notifications';
var INITIAL_STATE = {
  notifications: [],
  notificationSettings: [],
  userNotificationSettings: [],
  userUnread: null
};
export default function (state, action) {
  var type = action.type,
      payload = action.payload;

  if (typeof state === 'undefined') {
    return INITIAL_STATE;
  }

  switch (type) {
    case GET_NOTIFICATIONS:
      if (typeof payload === 'undefined' && payload === null) {
        return state;
      } // Order notifications by the id property in a DESC order


      payload.map(function (referenceType) {
        if (typeof referenceType.notifications !== 'undefined' && Array.isArray(referenceType.notifications)) {
          referenceType.notifications.sort(function (a, b) {
            return b.id - a.id;
          });
        }
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        notifications: payload
      });

    case CLEAR_NOTIFICATIONS_FROM_STORE:
      return _objectSpread(_objectSpread({}, state), {}, {
        notifications: []
      });

    case GET_NOTIFICATION_SETTINGS:
      return _objectSpread(_objectSpread({}, state), {}, {
        notificationSettings: action.payload
      });

    case GET_USER_NOTIFICATION_SETTINGS:
      return _objectSpread(_objectSpread({}, state), {}, {
        userNotificationSettings: action.payload
      });

    case GET_USER_UNREAD_NOTIFICATIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        userUnread: action.payload
      });

    default:
      return state;
  }
}