import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

export default function (_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      if (action.payload != null) {
        if (typeof action.payload == 'string') {
          try {
            var newAction = _objectSpread(_objectSpread({}, action), {}, {
              payload: JSON.parse(action.payload)
            });

            dispatch(newAction);
          } catch (error) {
            next(action);
          }
        } else {
          next(action);
        }
      } else {
        return next(action);
      }
    };
  };
}
;