import api from 'lib/api';
export var CHECK_PLAGIARISM = 'CHECK_PLAGIARISM';
export var VALIDATE_PLAGIARISM_LICENSE = 'VALIDATE_PLAGIARISM_LICENSE';
export var checkPlagiarism = function checkPlagiarism(data, callback) {
  return {
    type: CHECK_PLAGIARISM,
    payload: new Promise(function (resolve) {
      var endpoint = 'Plagiarism/multi';
      api.post(endpoint, data).then(function (response) {
        resolve(response.data);

        if (callback != null) {
          callback(response.data);
        }
      });
    })
  };
};
export var validatePlagiarismLicense = function validatePlagiarismLicense(schoolId) {
  return {
    type: VALIDATE_PLAGIARISM_LICENSE,
    payload: new Promise(function (resolve) {
      var endpoint = 'Plagiarism/' + schoolId + '/checkForValidPlagiarismLicense';
      api.get(endpoint).then(function (response) {
        resolve(response.data);
      });
    })
  };
};