import api from 'lib/api';
export var GET_COURSE_DETAILS = 'GET_COURSE_DETAILS';
export var SET_ACTIVE_COURSE = 'SET_ACTIVE_COURSE';
export var GET_COURSES = 'GET_COURSES';
export var GET_COURSE_BY_COURSECODE = 'GET_COURSE_BY_COURSECODE';
export var ADD_COURSE = 'ADD_COURSES';
export var PUT_COURSE = 'PUT_COURSES';
export var CLEAR_COURSES_FROM_STORE = 'CLEAR_COURSES_FROM_STORE';
export var GET_STANDARD_COURSES = 'GET_STANDARD_COURSES';
export var addCourse = function addCourse(data, callback) {
  return {
    type: ADD_COURSE,
    payload: new Promise(function (resolve, reject) {
      api.post('courses', data).then(function (response) {
        if (response.status != 200) {
          reject(response);
        } else {
          resolve(response.data);

          if (callback != null) {
            callback(response);
          }
        }
      });
    })
  };
};
export var updateCourse = function updateCourse(data, callback) {
  return {
    type: PUT_COURSE,
    payload: new Promise(function (resolve, reject) {
      api.put('courses', data).then(function (response) {
        if (response.status > 300) {
          reject(response);
        } else {
          resolve(response.data);

          if (callback != null) {
            callback(response);
          }
        }
      });
    })
  };
};
export var getCourse = function getCourse(id) {
  return {
    type: GET_COURSE_DETAILS,
    payload: new Promise(function (resolve, reject) {
      api.get("courses/".concat(id)).then(function (response) {
        if (response.status != 200) {
          reject(response);
        } else {
          resolve(response.data);
        }
      });
    })
  };
};
export var getCourseByCourseCode = function getCourseByCourseCode(courseCode) {
  return {
    type: GET_COURSE_BY_COURSECODE,
    payload: new Promise(function (resolve, reject) {
      api.get("courses/courseCode/".concat(courseCode)).then(function (response) {
        if (response.status != 200) {
          reject(response);
        } else {
          resolve(response.data);
        }
      });
    })
  };
};
export var setActiveCourse = function setActiveCourse(id, callback) {
  return [{
    type: SET_ACTIVE_COURSE,
    id: id
  }];
};
export var getCourses = function getCourses(type) {
  return {
    type: GET_COURSES,
    payload: new Promise(function (resolve, reject) {
      api.get("courses?type=".concat(type)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getStandardCourses = function getStandardCourses() {
  return {
    type: GET_STANDARD_COURSES,
    payload: new Promise(function (resolve, reject) {
      api.get("courses?type=PREDEFINED_COURSE").then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var clearCoursesFromStore = function clearCoursesFromStore() {
  return {
    type: CLEAR_COURSES_FROM_STORE,
    payload: []
  };
};