/* Import dependencies */
import api from 'lib/api';
/* Define action type */

export var SUBMIT_PRESCHOOL_GOAL = 'SUBMIT_PRESCHOOL';
export var DELETE_PRESCHOOL_GOAL = 'DELETE_PRESCHOOL';
export var SEARCH_PRESCHOOL_GOALS = 'SEARCH_PRESCHOOL_GOALS';
export var FETCH_PRESCHOOL_GOALS = 'FETCH_PRESCHOOL_GOALS';
export var GET_PRESCHOOL_GOALS_STATISTICS = 'PRESCHOOL_GOALS_GET_GOALS_STATISTICS';
export var GET_PRESCHOOL_GOAL_PLANS_AND_DOCUMENTATIONS = 'PRESCHOOL_GOALS_GET_GOAL_PLANS_AND_DOCUMENTATIONS';
export var RESET_PRESCHOOL_GOAL_PLANS_AND_DOCUMENTATIONS = 'RESET_PRESCHOOL_GOAL_PLANS_AND_DOCUMENTATIONS';
export var GET_PRESCHOOL_GOALS_USER_STATISTICS = 'PRESCHOOL_GOALS_GET_USER_STATISTICS';
export function submit_preschoolgoal(values) {
  return {
    type: SUBMIT_PRESCHOOL_GOAL,
    value: values
  };
}
export function fetch_preschoolgoals() {
  return {
    type: FETCH_PRESCHOOL_GOALS,
    payload: new Promise(function (resolve, reject) {
      api.get('goals').then(function (response) {
        resolve(response.data);
      });
    })
  };
}
export function delete_preschoolgoal(values) {
  return {
    type: DELETE_PRESCHOOL_GOAL,
    value: values
  };
}
export function search_preschoolgoals(search) {
  return {
    type: SEARCH_PRESCHOOL_GOALS,
    value: search
  };
}
export var getGoalsStatisticsForUsers = function getGoalsStatisticsForUsers(sectionId, start, end) {
  return {
    type: GET_PRESCHOOL_GOALS_STATISTICS,
    payload: new Promise(function (resolve) {
      api.get("goals/statistics/sections/".concat(sectionId, "?from=").concat(start, "&to=").concat(end)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getGoalsStatisticsForUser = function getGoalsStatisticsForUser(studentId, start, end) {
  return {
    type: GET_PRESCHOOL_GOALS_USER_STATISTICS,
    payload: new Promise(function (resolve) {
      api.get("goals/statistics/users/".concat(studentId, "?from=").concat(start, "&to=").concat(end)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getLinkedPlansAndDocumententationsForGoal = function getLinkedPlansAndDocumententationsForGoal(goalId, studentId, from, to) {
  return {
    type: GET_PRESCHOOL_GOAL_PLANS_AND_DOCUMENTATIONS,
    payload: new Promise(function (resolve) {
      api.get("goals/".concat(goalId, "/plansAndDocumentations?from=").concat(from, "&to=").concat(to, "&studentId=").concat(studentId)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var resetLinkedPlansAndDocumentations = function resetLinkedPlansAndDocumentations() {
  return {
    type: RESET_PRESCHOOL_GOAL_PLANS_AND_DOCUMENTATIONS,
    payload: true
  };
};