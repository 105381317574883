import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import api from 'lib/api';
export var SUBMIT_POST = 'SUBMIT_POST';
export var UPDATE_POST = 'UPDATE_POST';
export var SET_ACTIVE_POST = 'SET_ACTIVE_POST';
export var SET_ACTIVE_POST_DETAILS = 'SET_ACTIVE_POST_DETAILS';
export var GET_POSTS = 'GET_POSTS';
export var GET_POSTS_NEXT_PAGE = 'GET_POSTS_NEXT_PAGE';
export var GET_SECTION_POSTS = 'GET_SECTION_POSTS';
export var DELETE_POST_FROM_STORE = 'DELETE_POST_FROM_STORE';
export var CLEAR_POSTS_FROM_STORE = 'CLEAR_POSTS_FROM_STORE';
export var CLEAR_SECTION_POSTS_FROM_STORE = 'CLEAR_SECTION_POSTS_FROM_STORE';
export var GET_POST_READ_LIST = 'POSTS_GET_READ_LIST';
export var DELETE_POST = 'POST_DELETE_POST';
export var CLEAR_POST_READ_LIST = 'CLEAR_POST_READ_LIST';
export var SET_PAGINATION_INFO = 'SET_PAGINATION_INFO';
export var DELETE_POST_FILE = 'POST_DELETE_POST_FILE';
export function submit_post(values, callback) {
  return {
    type: SUBMIT_POST,
    payload: new Promise(function (resolve) {
      api.post('posts', values).then(function (response) {
        resolve(_objectSpread(_objectSpread({}, values), {}, {
          id: response.data
        }));

        if (callback != null) {
          callback(response.data);
        }
      });
    })
  };
}
export function update_post(values, callback) {
  return {
    type: UPDATE_POST,
    payload: new Promise(function (resolve) {
      api.put('posts', values).then(function (response) {
        if (callback != null) {
          callback(response.data);
        }

        resolve(values);
      });
    })
  };
}
export function setActivePost(id) {
  return [{
    type: SET_ACTIVE_POST,
    id: id
  }];
}
export function getPostDetails(id) {
  return {
    type: SET_ACTIVE_POST_DETAILS,
    payload: new Promise(function (resolve, reject) {
      if (typeof id !== 'undefined' && id !== null) {
        api.get("posts/".concat(id)).then(function (response) {
          if (response.status > 201) {
            reject(response);
            return true;
          }

          resolve(response.data);
        });
      }
    })
  };
}
export function getPosts(callback) {
  return {
    type: GET_POSTS,
    payload: new Promise(function (resolve) {
      api.get("posts").then(function (response) {
        resolve(response.data);

        if (callback) {
          callback(response.data);
        }
      });
    })
  };
}
export function setPostCollectionInfo(info) {
  return {
    type: SET_PAGINATION_INFO,
    payload: new Promise(function (resolve) {
      resolve(info);
    })
  };
}
;
export function getPostsBySectionId(id, page) {
  var status = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var startDate = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var endDate = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
  var pageSize = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 15;
  return {
    type: GET_SECTION_POSTS,
    payload: new Promise(function (resolve) {
      var url = "posts?sectionId=".concat(id);

      if (page != null) {
        url += "&pageIndex=".concat(page, "&pageSize=").concat(pageSize);
      }

      if (status != null) {
        url += "&filter=".concat(status);
      }

      if (startDate != null) {
        url += "&startDate=".concat(startDate);
      }

      if (endDate != null) {
        url += "&endDate=".concat(endDate);
      }

      api.get(url).then(function (response) {
        if (response.data.length > 0) {
          resolve(response.data);
        } else {
          resolve([]);
        }
      });
    })
  };
}
;
export function getPostsPage(page) {
  var status = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var startDate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var endDate = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var pageSize = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 15;
  return {
    type: GET_POSTS_NEXT_PAGE,
    payload: new Promise(function (resolve) {
      var url = "posts?pageIndex=".concat(page, "&pageSize=").concat(pageSize);

      if (status != null) {
        url += "&filter=".concat(status);
      }

      if (startDate != null) {
        url += "&startDate=".concat(startDate);
      }

      if (endDate != null) {
        url += "&endDate=".concat(endDate);
      }

      api.get(url).then(function (response) {
        if (response.data.length > 0) {
          resolve(response.data);
        } else {
          resolve([]);
        }
      });
    })
  };
}
;
export function deletePostFromStore(id) {
  return {
    type: DELETE_POST_FROM_STORE,
    payload: id
  };
}
export var deletePost = function deletePost(id) {
  return {
    type: DELETE_POST,
    payload: new Promise(function (resolve) {
      api["delete"]("posts/".concat(id)).then(function (response) {
        resolve(id);
      });
    })
  };
};
export var getPostReadList = function getPostReadList(post) {
  return {
    type: GET_POST_READ_LIST,
    payload: new Promise(function (resolve) {
      api.get("posts/".concat(post, "/reads")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export function clearSectionPostsFromStore() {
  return {
    type: CLEAR_SECTION_POSTS_FROM_STORE,
    payload: true
  };
}
;
export var clearPostsFromStore = function clearPostsFromStore() {
  return {
    type: CLEAR_POSTS_FROM_STORE,
    payload: true
  };
};
export var clearPostReadList = function clearPostReadList() {
  return {
    type: CLEAR_POST_READ_LIST,
    payload: true
  };
};
export var deletePostFile = function deletePostFile(postId, fileId) {
  return {
    type: DELETE_POST_FILE,
    payload: new Promise(function (resolve) {
      api["delete"]("posts/".concat(postId, "/files/").concat(fileId)).then(function () {
        resolve(fileId);
      });
    })
  };
};