import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { SET_SEARCH_TERM, SEARCH_EDUCATION_GROUPS, SEARCH_MENTOR_GROUPS, SEARCH_GROUPS, SEARCH_MENTORS, SEARCH_HALDOR_ASSIGNMENTS_IN_GROUP, SEARCH_MICROSOFT_ASSIGNMENTS_IN_GROUP, SEARCH_CLEAR_RESULTS, SEARCH_STUDENTS, SEARCH_PEOPLE, SEARCH_STAFF, SEARCH_TEACHER, SEARCH_ASSIGNMENTS, SEARCH_PLANS, SEARCH_GUARDIANS, SEARCH_ALL, SEARCH_POSTS, CLEAR_SEARCH, SEARCH_MY_SECTIONS } from 'actions/search';
var INITIAL_STATE = {
  term: null,
  educationGroups: null,
  mentorGroups: null,
  groups: null,
  mentors: null,
  microsoftAssignments: [],
  haldorAssignments: [],
  assignments: [],
  plans: [],
  students: null,
  people: null,
  guardians: [],
  staff: [],
  courses: [],
  teachers: [],
  userSearch: [],
  posts: []
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_SEARCH_TERM:
      return _objectSpread(_objectSpread({}, state), {}, {
        term: action.payload
      });

    case SEARCH_EDUCATION_GROUPS:
      return _objectSpread(_objectSpread({}, state), {}, {
        educationGroups: action.payload
      });

    case SEARCH_MENTOR_GROUPS:
      return _objectSpread(_objectSpread({}, state), {}, {
        mentorGroups: action.payload
      });

    case SEARCH_GROUPS:
      return _objectSpread(_objectSpread({}, state), {}, {
        groups: action.payload
      });

    case SEARCH_MY_SECTIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        groups: action.payload
      });

    case SEARCH_MENTORS:
      return _objectSpread(_objectSpread({}, state), {}, {
        mentors: action.payload
      });

    case SEARCH_MICROSOFT_ASSIGNMENTS_IN_GROUP:
      return _objectSpread(_objectSpread({}, state), {}, {
        microsoftAssignments: action.payload
      });

    case SEARCH_HALDOR_ASSIGNMENTS_IN_GROUP:
      return _objectSpread(_objectSpread({}, state), {}, {
        haldorAssignments: action.payload
      });

    case SEARCH_CLEAR_RESULTS:
      return INITIAL_STATE;

    case SEARCH_STUDENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        students: action.payload
      });

    case SEARCH_PEOPLE:
      return _objectSpread(_objectSpread({}, state), {}, {
        people: action.payload
      });

    case SEARCH_STAFF:
      return _objectSpread(_objectSpread({}, state), {}, {
        staff: action.payload
      });

    case SEARCH_TEACHER:
      return _objectSpread(_objectSpread({}, state), {}, {
        teachers: action.payload
      });

    case SEARCH_ALL:
      return _objectSpread(_objectSpread({}, state), {}, {
        userSearch: action.payload
      });

    case SEARCH_ASSIGNMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        assignments: action.payload
      });

    case SEARCH_PLANS:
      return _objectSpread(_objectSpread({}, state), {}, {
        plans: action.payload
      });

    case SEARCH_GUARDIANS:
      return _objectSpread(_objectSpread({}, state), {}, {
        guardians: action.payload
      });

    case SEARCH_POSTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        posts: action.payload
      });

    case CLEAR_SEARCH:
      return _objectSpread(_objectSpread({}, state), {}, {
        educationGroups: INITIAL_STATE.educationGroups,
        mentorGroups: INITIAL_STATE.mentorGroups,
        groups: INITIAL_STATE.groups,
        mentors: INITIAL_STATE.mentors,
        microsoftAssignments: INITIAL_STATE.microsoftAssignments,
        haldorAssignments: INITIAL_STATE.haldorAssignments,
        students: INITIAL_STATE.students,
        people: INITIAL_STATE.people,
        staff: INITIAL_STATE.staff,
        teachers: INITIAL_STATE.teachers,
        userSearch: INITIAL_STATE.userSearch,
        assignments: INITIAL_STATE.assignments,
        plans: INITIAL_STATE.plans,
        guardians: INITIAL_STATE.guardians,
        term: INITIAL_STATE.term,
        posts: INITIAL_STATE.posts
      });

    default:
      return state;
  }
}