import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import * as actions from 'actions/schedule';
import { SUBMIT_ATTENDANCE } from 'actions/absence';
var INITIAL_STATE = {
  scheduleToday: [],
  userSchedule: [],
  usersSchedule: {},
  userActivites: [],
  activeScheduleItem: null,
  schedulePositions: [],
  groupActivities: [],
  selectedLessons: [],
  nonOwnerActivities: [],
  studentActivities: [],
  groupSchedules: []
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actions.SCHEDULE_TODAY:
      return _objectSpread(_objectSpread({}, state), {}, {
        scheduleToday: action.payload
      });

    case actions.CLEAR_SCHEDULE_FROM_STORE:
      return _objectSpread(_objectSpread({}, state), {}, {
        scheduleToday: []
      });

    case actions.CLEAR_SCHEDULE_ITEM:
      return _objectSpread(_objectSpread({}, state), {}, {
        activeScheduleItem: null
      });

    case actions.CLEAR_SEARCHED_SCHEDULES:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedLessons: []
      });

    case actions.GET_SCHEDULE_ITEM:
      return _objectSpread(_objectSpread({}, state), {}, {
        activeScheduleItem: action.payload
      });

    case actions.GET_SCHEDULES_BY_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedLessons: [].concat(_toConsumableArray(state.selectedLessons), [action.payload])
      });

    case actions.GET_SCHEDULE_POSITION_WITH_NO_REPORTED_ATTENDANCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        schedulePositions: action.payload
      });

    case SUBMIT_ATTENDANCE:
      var schedulePositions = state.schedulePositions;
      var scheduleId = action.payload.result;
      var foundIndex = schedulePositions.findIndex(function (sch) {
        return sch.id == scheduleId;
      });

      if (foundIndex > -1) {
        schedulePositions.splice(foundIndex, 1);
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        schedulePositions: schedulePositions
      });

    case actions.CLEAR_SCHEDULE_POSITION_WITH_NO_REPORTED_ATTENDANCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        schedulePositions: []
      });

    case actions.GET_SCHEDULE_FOR_GROUPS:
      return _objectSpread(_objectSpread({}, state), {}, {
        groupSchedules: action.payload.documents
      });

    case actions.GET_SCHEDULE_FOR_USER:
      var usersSchedule = state.usersSchedule;
      usersSchedule[action.payload.userId] = action.payload.documents;
      return _objectSpread(_objectSpread({}, state), {}, {
        userSchedule: action.payload.documents,
        usersSchedule: usersSchedule
      });

    case actions.GET_ACTIVITIES_BY_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        studentActivities: action.payload.documents
      });

    case actions.GET_ACTIVITIES_FOR_USER:
      return _objectSpread(_objectSpread({}, state), {}, {
        userActivites: action.payload.documents
      });

    case actions.GET_ACTIVITIES_FOR_GROUPS:
      return _objectSpread(_objectSpread({}, state), {}, {
        groupActivities: action.payload.documents
      });

    case actions.CLEAR_ACTIVITIES_FOR_OTHERS_STORE:
      return _objectSpread(_objectSpread({}, state), {}, {
        groupActivities: [],
        groupSchedules: []
      });

    default:
      return state;
  }
}