import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import api from 'lib/api';
export var GET_SECTIONS = 'GET_SECTIONS';
export var GET_SECTION = 'GET_SECTION';
export var GET_FILTERED_SECTION = 'GET_FILTERED_SECTION';
export var GET_SECTION_DETAILS = 'GET_SECTION_DETAILS';
export var SET_ACTIVE_SECTION = 'SET_ACTIVE_SECTION';
export var GET_EDUCATION_GROUPS = 'GET_EDUCATION_GROUPS';
export var GET_EDUCATION_GROUPS_LEGACY = 'GET_EDUCATION_GROUPS_LEGACY';
export var GET_ARCHIVED_EDUCATION_GROUPS = 'GET_ARCHIVED_EDUCATION_GROUPS';
export var GET_MENTOR_GROUPS = 'GET_MENTOR_GROUPS';
export var GROUPS_LOADING = 'GROUPS_FETCH_IS_LOADING';
export var CLEAR_SECTIONS_FROM_STORE = 'CLEAR_SECTIONS_FROM_STORE';
export var GET_SCHOOL_BY_GROUP_GRAPH_ID = 'GET_SCHOOL_BY_GROUP_GRAPH_ID';
export var GET_SECTION_BY_GRAPH_ID = 'GET_SECTION_BY_GRAPH_ID';
export var SET_ARCHIVING_SECTION = 'SET_ARCHIVING_SECTION';
export var GET_SECTION_ATTENDANCE_STATS = 'SECTIONS_GET_SECTION_ATTENDANCE_STATISTICS';
export var CLEAR_SECTION_ATTENDANCE_STATS = 'CLEAR_SECTION_ATTENDANCE_STATS';
export var GET_GROUP_ABSENCE = 'SECTIONS_GET_GROUP_ABSENCE';
export var GET_SECTION_GUARDIANS = 'SECTIONS_GET_SECTION_GUARDIANS';
export var GET_STUDENT_ATTENDANCE_STATS = 'GET_STUDENT_ATTENDANCE_STATS';
export var CLEAR_STUDENT_ATTENDANCE_STATS = 'CLEAR_STUDENT_ATTENDANCE_STATS';
export var INFORM_ADD_TAB = 'INFORM_ADD_TAB';
export var GET_SECTION_TEAMS_CHANNEL = 'GET_SECTION_TEAMS_CHANNEL';
export var GET_SECTION_ASESSMENT_STATISTICS = 'SECTIONS_GET_SECTION_ASSESSMENT_STATISTICS';
export var GET_ARCHIEVED_MENTOR_GROUPS = 'GET_ARCHIEVED_MENTOR_GROUPS';
export var TOGGLE_SELECTED_SECTION = 'SECTIONS_TOGGLE_SELECTED_SECTION';
export var CLEAR_SELECTED_SECTIONS = 'SECTIONS_CLEAR_SELECTED_SECTIONS';
export var GET_GROUP_STUDENT_ADJUSTMENTS = 'SECTIONS_GET_GROUP_STUDENT_ADDITIONAL_ADJUSTMENTS';
export var GET_GROUP_STUDENT_ADJUSTMENTS_MULTIPLE = 'SECTIONS_GET_GROUP_STUDENT_ADDITIONAL_ADJUSTMENTS_MULTIPLE';
export var GET_BOOKMARKED_PLANS = 'GET_BOOKMARKED_PLANS';
export var getBookmarkedPlans = function getBookmarkedPlans(callback) {
  return {
    type: GET_BOOKMARKED_PLANS,
    payload: new Promise(function (resolve) {
      api.get('sections/bookmarked').then(function (response) {
        resolve(response.data);

        if (callback != null) {
          callback(response.data);
        }
      });
    })
  };
};
export var getSections = function getSections(callback) {
  return {
    type: GET_SECTIONS,
    payload: new Promise(function (resolve) {
      api.get('sections').then(function (response) {
        resolve(response.data);

        if (callback != null) {
          callback(response.data);
        }
      });
    })
  };
};
export var getSchoolByGroupGraphId = function getSchoolByGroupGraphId(graphId) {
  return {
    type: GET_SCHOOL_BY_GROUP_GRAPH_ID,
    payload: new Promise(function (resolve) {
      api.get("sections/".concat(graphId, "/school")).then(function (response) {
        if (response.status == 204) {
          resolve(null);
          return;
        }

        resolve(response.data);
      });
    })
  };
};
export var getSectionByGraphId = function getSectionByGraphId(graphId) {
  var additionalParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return {
    type: GET_SECTION_BY_GRAPH_ID,
    payload: new Promise(function (resolve) {
      var params = '';

      if (additionalParams != null) {
        params = '?expand=' + additionalParams;
      }

      api.get("sections/".concat(graphId).concat(params)).then(function (response) {
        if (response.status == 204) {
          resolve(null);
          return;
        }

        resolve(response.data);
      });
    })
  };
};
export var getMentorGroups = function getMentorGroups(callback) {
  return {
    type: GET_MENTOR_GROUPS,
    payload: new Promise(function (resolve) {
      api.get('sections?type=MENTOR_GROUP').then(function (response) {
        resolve(response.data);

        if (callback != null) {
          callback(response.data);
        }
      });
    })
  };
};
export var getArchievedMentorGroups = function getArchievedMentorGroups(callback) {
  return {
    type: GET_ARCHIEVED_MENTOR_GROUPS,
    payload: new Promise(function (resolve) {
      api.get('sections?type=MENTOR_GROUP&archived=true').then(function (response) {
        resolve(response.data);

        if (callback != null) {
          callback(response.data);
        }
      });
    })
  };
};
export var getLegacyEducationGroups = function getLegacyEducationGroups() {
  return {
    type: GET_EDUCATION_GROUPS_LEGACY,
    payload: new Promise(function (resolve) {
      api.get('sections/legacy?type=EDUCATION_GROUP').then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getEducationGroups = function getEducationGroups() {
  return {
    type: GET_EDUCATION_GROUPS,
    payload: new Promise(function (resolve) {
      api.get('sections?type=EDUCATION_GROUP').then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getArchivedEducationGroups = function getArchivedEducationGroups(callback) {
  return {
    type: GET_ARCHIVED_EDUCATION_GROUPS,
    payload: new Promise(function (resolve) {
      api.get('sections?type=EDUCATION_GROUP&archived=true').then(function (response) {
        resolve(response.data);

        if (callback != null) {
          callback(response.data);
        }
      });
    })
  };
};
export var getSection = function getSection(id) {
  var owners = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var additionalParams = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  return {
    type: GET_SECTION,
    payload: new Promise(function (resolve, reject) {
      var params = null;

      if (owners) {
        params = 'expand=owners;members;courses;historic_students;' + additionalParams;
      } else {
        params = 'expand=members;courses;historic_students';
      }

      api.get("sections/".concat(id, "?").concat(params)).then(function (response) {
        var data = response.data;

        if (data && data.courses != null && data.courses.length > 0) {
          data.courses.forEach(function (element) {
            var sortedList = [];

            if (element.details != null && element.details.length > 0) {
              var activeDetailIndex = element.details.findIndex(function (element) {
                return element.endDate == null;
              });

              if (activeDetailIndex > -1) {
                var activeDetail = element.details[activeDetailIndex];
                element.details.splice(activeDetailIndex, 1);
                element.details.unshift(activeDetail);
              }
            }
          });
        }

        resolve(response.data);

        if (callback != null) {
          callback(response.data);
        }
      })["catch"](function (e) {
        reject(e);
      });
    })
  };
};
export var getFilteredSection = function getFilteredSection(id, startDate, endDate) {
  return {
    type: GET_FILTERED_SECTION,
    payload: new Promise(function (resolve) {
      api.get("sections/".concat(id, "/start/").concat(startDate, "/end/").concat(endDate)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var appendSectionDetails = function appendSectionDetails(id) {
  return {
    type: GET_SECTION_DETAILS,
    payload: new Promise(function (resolve) {
      api.get("sections/".concat(id)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var setActiveSection = function setActiveSection(id) {
  return {
    type: SET_ACTIVE_SECTION,
    payload: id
  };
};
export var clearSectionsFromStore = function clearSectionsFromStore() {
  return {
    type: CLEAR_SECTIONS_FROM_STORE,
    payload: []
  };
};
export var updateArchived = function updateArchived(group) {
  return {
    type: SET_ARCHIVING_SECTION,
    payload: new Promise(function (resolve) {
      var values = _objectSpread({}, group);

      values.archived = !group.archived;
      delete values.events;
      api.put("sections", values).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getSectionAttendance = function getSectionAttendance(sectionId, start, end) {
  return {
    type: GET_SECTION_ATTENDANCE_STATS,
    payload: new Promise(function (resolve) {
      api.get("attendance/Statistics?sectionId=".concat(sectionId, "&startDate=").concat(start, "&endDate=").concat(end)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getStudentAttendance = function getStudentAttendance(studentId, start, end, sectionId) {
  return {
    type: GET_STUDENT_ATTENDANCE_STATS,
    payload: new Promise(function (resolve) {
      if (sectionId != 0 && sectionId != null) {
        api.get("attendance/Statistics?studentId=".concat(studentId, "&startDate=").concat(start, "&endDate=").concat(end, "&sectionId=").concat(sectionId)).then(function (response) {
          resolve(response.data);
        });
        return;
      }

      api.get("attendance/Statistics?studentId=".concat(studentId, "&startDate=").concat(start, "&endDate=").concat(end)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var clearStudentAttendance = function clearStudentAttendance() {
  return {
    type: CLEAR_STUDENT_ATTENDANCE_STATS,
    payload: true
  };
};
export var clearSectionAttendanceStats = function clearSectionAttendanceStats() {
  return {
    type: CLEAR_SECTION_ATTENDANCE_STATS,
    payload: true
  };
};
export var getGroupAbsenceForToday = function getGroupAbsenceForToday(id, start, end) {
  return {
    type: GET_GROUP_ABSENCE,
    payload: new Promise(function (resolve) {
      api.get("sections/".concat(id, "/absences?startDate=").concat(start, "&endDate=").concat(end)).then(function (response) {
        var values = {
          groupId: id,
          value: response.data
        };
        resolve(values);
      });
    })
  };
};
export var getSectionAssessmentStatistics = function getSectionAssessmentStatistics(sectionId, start, end) {
  var subjects = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  return {
    type: GET_SECTION_ASESSMENT_STATISTICS,
    payload: new Promise(function (resolve, reject) {
      var url = "assessments/v2/sections/".concat(sectionId, "/statistics?startDate=").concat(start, "&endDate=").concat(end);
      api.post(url, subjects).then(function (response) {
        resolve(response.data);
      })["catch"](function (error) {
        reject(error);
      });
    })
  };
};
export var getSectionGuardians = function getSectionGuardians(id) {
  return {
    type: GET_SECTION_GUARDIANS,
    payload: new Promise(function (resolve) {
      api.get("sections/".concat(id, "/guardians")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getTeamChannels = function getTeamChannels(groupId) {
  return {
    type: GET_SECTION_TEAMS_CHANNEL,
    payload: new Promise(function (resolve) {
      api.get("sections/".concat(groupId, "/channels")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var informAboutTabData = function informAboutTabData(sectionId, tabId) {
  return {
    type: INFORM_ADD_TAB,
    payload: new Promise(function (resolve) {
      api.put("sections/".concat(sectionId, "/tabs/").concat(tabId), null).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var toggleSelectedSection = function toggleSelectedSection(section) {
  var groupAssignment = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return {
    type: TOGGLE_SELECTED_SECTION,
    payload: {
      section: section,
      groupAssignment: groupAssignment
    }
  };
};
export var clearSelectedSections = function clearSelectedSections() {
  return {
    type: CLEAR_SELECTED_SECTIONS,
    payload: true
  };
};
export var getStudentAdditionalAdjustmentsForGroup = function getStudentAdditionalAdjustmentsForGroup(groupId) {
  return {
    type: GET_GROUP_STUDENT_ADJUSTMENTS,
    payload: new Promise(function (resolve) {
      api.get("sections/".concat(groupId, "/studentAdditionalAdjustments")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getStudentAdditionalAdjustmentsForMultipleGroups = function getStudentAdditionalAdjustmentsForMultipleGroups(group) {
  return {
    type: GET_GROUP_STUDENT_ADJUSTMENTS_MULTIPLE,
    payload: new Promise(function (resolve) {
      api.get("StudentAdditionalAdjustments?sectionId=".concat(group)).then(function (response) {
        var data = null;

        if (response.data != null) {
          data = [];
          response.data.forEach(function (adjustment) {
            adjustment.groupId = group;
            data.push(adjustment);
          });
        }

        resolve(data);
      });
    })
  };
};