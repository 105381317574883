import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { FETCH_PRESCHOOL_GOALS, SUBMIT_PRESCHOOL_GOAL, DELETE_PRESCHOOL_GOAL, SEARCH_PRESCHOOL_GOALS, GET_PRESCHOOL_GOALS_STATISTICS, GET_PRESCHOOL_GOAL_PLANS_AND_DOCUMENTATIONS, RESET_PRESCHOOL_GOAL_PLANS_AND_DOCUMENTATIONS, GET_PRESCHOOL_GOALS_USER_STATISTICS } from 'actions/preschoolform';
var initialState = {
  preschoolGoals: [],
  searchResults: [],
  statistics: null,
  userStatistics: null,
  linkedData: []
};
export default function Preschoolgoal(state, action) {
  var type = action.type,
      value = action.value;

  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (type) {
    case SUBMIT_PRESCHOOL_GOAL:
      // Cast the values of this goal to strings
      value.title += '';
      value.description += '';

      if (typeof value.id !== 'undefined' && value.id !== '') {
        var goal = null;
        var index = state.preschoolGoals.findIndex(function (pG) {
          return value.id === pG.id;
        });

        if (index > -1) {
          goal = value;
          state.preschoolGoals.splice(index, 1);
          state.preschoolGoals.splice(index, 0, goal);
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          preschoolGoals: state.preschoolGoals
        });
      } else {
        // Remove this when we have actual data from the API
        value.id = '1234-test-id-' + (state.preschoolGoals !== null ? state.preschoolGoals.length + 1 : 1);
      }

      state.preschoolGoals.push(value);
      return _objectSpread(_objectSpread({}, state), {}, {
        preschoolGoals: state.preschoolGoals
      });
      break;

    case DELETE_PRESCHOOL_GOAL:
      if (typeof value.id !== 'undefined' && value.id !== '') {
        var _index = state.preschoolGoals.findIndex(function (pG) {
          return value.id === pG.id;
        });

        if (_index > -1) {
          state.splice(_index, 1);
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          preschoolGoals: state
        });
      }

      break;

    case SEARCH_PRESCHOOL_GOALS:
      var results = [];

      if (value.length < 3) {
        return _objectSpread(_objectSpread({}, state), {}, {
          searchResults: results
        });
      }

      results = state.preschoolGoals.filter(function (t) {
        return t.title.toLowerCase() === value.search.toLowerCase();
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        searchResults: results
      });
      break;

    case FETCH_PRESCHOOL_GOALS:
      return _objectSpread(_objectSpread({}, state), {}, {
        preschoolGoals: action.payload
      });
      break;

    case GET_PRESCHOOL_GOALS_STATISTICS:
      return _objectSpread(_objectSpread({}, state), {}, {
        statistics: action.payload
      });

    case GET_PRESCHOOL_GOALS_USER_STATISTICS:
      return _objectSpread(_objectSpread({}, state), {}, {
        userStatistics: action.payload
      });

    case GET_PRESCHOOL_GOAL_PLANS_AND_DOCUMENTATIONS:
      if (action.payload != null) {
        return _objectSpread(_objectSpread({}, state), {}, {
          linkedData: [].concat(_toConsumableArray(state.linkedData), [action.payload])
        });
      }

      return state;

    case RESET_PRESCHOOL_GOAL_PLANS_AND_DOCUMENTATIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        linkedData: []
      });

    default:
      return state;
      break;
  }
}