import Moment from 'moment';
import schoolHelper from 'helpers/schoolHandling';
var getSchoolForUser = schoolHelper.getSchoolForUser;
import { ROLE_TEACHER, ROLE_MENTOR, ROLE_ADMINISTRATOR, ROLE_COURSE_ADMIN, ROLE_STUDENT, ROLE_STANDARD_CURRICULUM_ADMIN, ROLE_SYSADMIN, ROLE_SPECIAL_PEDAGOGUE } from 'constants/roles';
export var isUserPedagogue = function isUserPedagogue(user) {
  if (user != null) {
    var school = getSchoolForUser(user);

    if (user.roles != null && user.roles.length > 0) {
      var pedagogue = user.roles.find(function (role) {
        return role.roleId.toUpperCase() == ROLE_SPECIAL_PEDAGOGUE && role.referenceType.toUpperCase() == 'SCHOOL' && role.referenceId == school;
      });

      if (pedagogue != null) {
        return true;
      }

      return false;
    }

    return false;
  }

  return false;
};
export var isUserTeacher = function isUserTeacher(user) {
  if (user != null) {
    var school = getSchoolForUser(user);

    if (user.roles != null && user.roles.length > 0) {
      var teacher = user.roles.find(function (role) {
        return role.roleId.toUpperCase() == ROLE_TEACHER && role.referenceType.toUpperCase() == 'SCHOOL' && role.referenceId == school;
      });

      if (teacher != null) {
        return true;
      }

      return false;
    }

    return false;
  }

  return false;
};
export var isUserAdministrator = function isUserAdministrator(user) {
  if (user != null) {
    var school = getSchoolForUser(user);

    if (user.roles != null && user.roles.length > 0) {
      var admin = user.roles.find(function (role) {
        return (role.roleId.toUpperCase() == ROLE_ADMINISTRATOR || role.roleId.toUpperCase() == ROLE_SYSADMIN) && role.referenceType.toUpperCase() == 'SCHOOL' && role.referenceId == school;
      });

      if (admin != null) {
        return true;
      }

      return false;
    }

    return false;
  }

  return false;
};
export var isUserCourseAdministrator = function isUserCourseAdministrator(user) {
  if (user != null) {
    var school = getSchoolForUser(user);

    if (user.roles != null && user.roles.length > 0) {
      var admin = user.roles.find(function (role) {
        return role.roleId.toUpperCase() == ROLE_COURSE_ADMIN && role.referenceType.toUpperCase() == 'SCHOOL' && role.referenceId == school;
      });

      if (admin != null) {
        return true;
      }

      return false;
    }

    return false;
  }

  return false;
};
export var isUserCurriculumAdmin = function isUserCurriculumAdmin(user) {
  if (user != null) {
    var school = getSchoolForUser(user);

    if (user.roles != null && user.roles.length > 0) {
      var admin = user.roles.find(function (role) {
        return role.roleId.toUpperCase() == ROLE_STANDARD_CURRICULUM_ADMIN && role.referenceType.toUpperCase() == 'SCHOOL' && role.referenceId == school;
      });

      if (admin != null) {
        return true;
      }

      return false;
    }

    return false;
  }

  return false;
};
export var isUserMentor = function isUserMentor(user) {
  if (user != null) {
    var school = getSchoolForUser(user);

    if (user.roles != null && user.roles.length > 0) {
      var mentor = user.roles.find(function (role) {
        return role.roleId.toUpperCase() == ROLE_MENTOR && role.referenceType.toUpperCase() == 'SCHOOL' && role.referenceId == school;
      });

      if (mentor != null) {
        return true;
      }

      return false;
    }

    return false;
  }

  return false;
};
export var isUserStudent = function isUserStudent(user) {
  if (user != null) {
    var school = getSchoolForUser(user);

    if (user.roles != null && user.roles.length > 0) {
      var student = user.roles.find(function (role) {
        return role.roleId.toUpperCase() == ROLE_STUDENT && role.referenceType.toUpperCase() == 'SCHOOL' && role.referenceId == school;
      });

      if (student != null) {
        return true;
      }

      return false;
    }

    return false;
  }

  return false;
};
export var getUserAssessmentDates = function getUserAssessmentDates() {
  var startDateLocalStorage = localStorage.getItem('assessment-start-date');
  var endDateLocalStorage = localStorage.getItem('assessment-end-date');
  var dates = {
    start: Moment().month(7).date(1).format('YYYY-MM-DD'),
    end: Moment().format('YYYY-MM-DD')
  };

  if (Moment().month() < 7) {
    dates.start = Moment().subtract(1, 'years').month(7).date(1).format('YYYY-MM-DD');
  }

  if (startDateLocalStorage != null) {
    dates.start = Moment(startDateLocalStorage).format('YYYY-MM-DD');
  }

  if (endDateLocalStorage != null) {
    dates.end = Moment(endDateLocalStorage).format('YYYY-MM-DD');
  }

  if (dates.end < dates.start) {
    dates.start = dates.end;
  }

  return dates;
};
export var isUserAuthoritative = function isUserAuthoritative(user) {
  return user.authoritative;
};