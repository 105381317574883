import api from 'lib/api';
export var ADD_MATRIX = 'ADD_MATRIX';
export var GET_MATRIX = 'GET_MATRIX';
export function addMatrix(matrix) {
  return {
    type: ADD_MATRIX,
    payload: matrix
  };
}
export var getMatrix = function getMatrix(id) {
  return {
    type: GET_MATRIX,
    payload: new Promise(function (resolve) {
      api.get("matrix/".concat(id)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};