import api from 'lib/api';
export var GET_FORM_TEMPLATES = 'TEMPLATE_GET_FORM_TEMPLATES';
export var GET_FORM_TEMPLATE = 'TEMPLATE_GET_FORM_SINGLE_TEMPLATE';
export var CREATE_FORM_TEMPLATE = 'TEMPLATE_CREATE_FORM_TEMPLATE';
export var UPDATE_FORM_TEMPLATE = 'TEMPLATE_CREATE_FORM_TEMPLATE';
export var CLEAR_FORM_TEMPLATE = 'TEMPLATE_CLEAR_SINGLE_FORM_TEMPLATE';
export var CREATE_FORM = 'TEMPLATE_ASSIGN_FORM_TEMPLATE';
export var UPDATE_FORM = 'TEMPLATE_UPDATE_FORM';
export var UPDATE_FORM_INSTANCE = 'TEMPLATE_UPDATE_FORM_INSTANCE';
export var CREATE_FORM_INSTANCE = 'TEMPLATE_CREATE_FORM_INSTANCE';
export var GET_FORM = 'TEMPLATE_GET_FORM';
export var CLEAR_FORM = 'TEMPLATE_CLEAR_FORM_DATA';
export var GET_FORMS = 'TEMPLATE_GET_FORMS';
export var GET_MY_FORMS = 'TEMPLATE_GET_MY_FORMS';
export var GET_FORM_INSTANCE = 'TEMPLATE_GET_FORM_INSTANCE';
export var createFormTemplate = function createFormTemplate(template) {
  return {
    type: CREATE_FORM_TEMPLATE,
    payload: new Promise(function (resolve) {
      api.post("formtemplates", template).then(function (data) {
        resolve(data);
      });
    })
  };
};
export var updateFormTemplate = function updateFormTemplate(template) {
  return {
    type: UPDATE_FORM_TEMPLATE,
    payload: new Promise(function (resolve) {
      api.put("formtemplates", template).then(function (response) {
        resolve(template);
      });
    })
  };
};
export var getFormTemplates = function getFormTemplates() {
  return {
    type: GET_FORM_TEMPLATES,
    payload: new Promise(function (resolve) {
      api.get("formtemplates").then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getFormTemplate = function getFormTemplate(id) {
  return {
    type: GET_FORM_TEMPLATE,
    payload: new Promise(function (resolve) {
      api.get("formtemplates/".concat(id)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var clearFormTemplate = function clearFormTemplate() {
  return {
    type: CLEAR_FORM_TEMPLATE,
    payload: true
  };
};
export var createForm = function createForm(form) {
  return {
    type: CREATE_FORM_INSTANCE,
    payload: new Promise(function (resolve) {
      api.post("forms", form).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var updateForm = function updateForm(form) {
  return {
    type: UPDATE_FORM,
    payload: new Promise(function (resolve) {
      api.put("forms", form).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getForm = function getForm(id) {
  return {
    type: GET_FORM,
    payload: new Promise(function (resolve) {
      api.get("forms/".concat(id)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var clearForm = function clearForm() {
  return {
    type: CLEAR_FORM,
    payload: true
  };
};
export var getForms = function getForms() {
  var creator = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return {
    type: GET_FORMS,
    payload: new Promise(function (resolve) {
      api.get("forms?creator=".concat(creator)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getMyForms = function getMyForms() {
  return {
    type: GET_MY_FORMS,
    payload: new Promise(function (resolve) {
      api.get("forms?creator=true").then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var updateFormInstance = function updateFormInstance(instance) {
  return {
    type: UPDATE_FORM_INSTANCE,
    payload: new Promise(function (resolve, reject) {
      api.put("forms/instance", instance).then(function (response) {
        resolve(response.data);
      })["catch"](function (error) {
        reject(error);
      });
    })
  };
};
export var getFormInstance = function getFormInstance(id) {
  return {
    type: GET_FORM_INSTANCE,
    payload: new Promise(function (resolve) {
      api.get("forms/instance/".concat(id)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};