import api from 'lib/api';
export var GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export var GET_NOTIFICATION_SETTINGS = 'GET_NOTIFICATION_SETTINGS';
export var GET_USER_NOTIFICATION_SETTINGS = 'GET_USER_NOTIFICATION_SETTINGS';
export var GET_USER_UNREAD_NOTIFICATIONS = 'NOTIFICATIONS_GET_USER_UNREAD_NOTIFICATIONS';
export var CLEAR_NOTIFICATIONS_FROM_STORE = 'CLEAR_NOTIFICATIONS_FROM_STORE';
export var MARK_NOTIFICATION_AS_READ = 'NOTIFICATIONS_MARK_NOTIFICATION_AS_READ';
export var getNotifications = function getNotifications(lcid) {
  return {
    type: GET_NOTIFICATIONS,
    payload: new Promise(function (resolve) {
      api.get("notifications/lcid/".concat(lcid)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getNotificationSettings = function getNotificationSettings() {
  return {
    type: GET_NOTIFICATION_SETTINGS,
    payload: new Promise(function (resolve) {
      api.get("notifications/notificationSettings").then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getUserNotificationSettings = function getUserNotificationSettings() {
  return {
    type: GET_USER_NOTIFICATION_SETTINGS,
    payload: new Promise(function (resolve) {
      api.get("notifications/userNotificationSettings").then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var clearNotificationsFromStore = function clearNotificationsFromStore() {
  return {
    type: CLEAR_NOTIFICATIONS_FROM_STORE,
    payload: []
  };
};
export var markNotificationAsRead = function markNotificationAsRead(notificationId) {
  return {
    type: MARK_NOTIFICATION_AS_READ,
    payload: new Promise(function (resolve) {
      api.post("notifications/".concat(notificationId, "/read")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getUserUnreadNotifications = function getUserUnreadNotifications() {
  return {
    type: GET_USER_UNREAD_NOTIFICATIONS,
    payload: new Promise(function (resolve) {
      api.get("users/me/notifications/unread").then(function (response) {
        resolve(response.data);
      });
    })
  };
};