import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { GET_SINGLE_SCHOOL_STUDENTS, GET_SINGLE_SCHOOL_DETAILS, GET_SCHOOL_ABSENCE, GET_SCHOOL_GUARDIANS, UPDATE_SCHOOL_TYPE, GET_SCHOOL_SUBJECTTEACHERS, GET_SCHOOL_SERVICES, GET_SCHOOL_DATA_FOR_SECTIONS, GET_SCHOOLS_DATA_FOR_SECTIONS, GET_SINGLE_SCHOOL_BY_EXTERNALID } from 'actions/schools';
import { DELETE_ABSENCE } from 'actions/absence';
var INITIAL_STATE = {
  school: null,
  active: null,
  students: null,
  absence: null,
  guardians: [],
  subjectTeacher: [],
  schoolServices: null,
  schoolDataForSection: null,
  schoolsDataForSection: null
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case GET_SINGLE_SCHOOL_BY_EXTERNALID:
      return _objectSpread(_objectSpread({}, state), {}, {
        school: action.payload
      });

    case GET_SINGLE_SCHOOL_STUDENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        students: action.payload
      });

    case GET_SINGLE_SCHOOL_DETAILS:
      return _objectSpread(_objectSpread({}, state), {}, {
        active: action.payload
      });

    case GET_SCHOOLS_DATA_FOR_SECTIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        schoolsDataForSection: action.payload
      });

    case GET_SCHOOL_DATA_FOR_SECTIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        schoolDataForSection: action.payload
      });

    case GET_SCHOOL_ABSENCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        absence: action.payload
      });

    case GET_SCHOOL_GUARDIANS:
      return _objectSpread(_objectSpread({}, state), {}, {
        guardians: action.payload
      });

    case UPDATE_SCHOOL_TYPE:
      var active = state.active;

      if (active != null) {
        if (active.id == action.payload.id) {
          return _objectSpread(_objectSpread({}, state), {}, {
            active: action.payload
          });
        }
      }

      return state;

    case GET_SCHOOL_SUBJECTTEACHERS:
      return _objectSpread(_objectSpread({}, state), {}, {
        subjectTeacher: action.payload
      });

    case GET_SCHOOL_SERVICES:
      return _objectSpread(_objectSpread({}, state), {}, {
        schoolServices: action.payload
      });

    default:
      return state;
  }
}