import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getUserUnreadNotifications } from 'actions/notifications';
import moment from 'moment';

var NotificationProvider = function NotificationProvider() {
  var dispatch = useDispatch();
  var isFocused = false;

  var onFocusFunction = function onFocusFunction() {
    // do whatever when focus is gained
    isFocused = true;
  };

  var onBlurFunction = function onBlurFunction() {
    // do whatever when focus is lost
    isFocused = false;
  };

  useEffect(function () {
    function fetchCount() {
      if (isFocused) {
        console.log('Fetch Count', moment().format('hh:mm:ss'));
        dispatch(getUserUnreadNotifications());
      }
    }

    ;
    onFocusFunction();
    var interval = setInterval(function () {
      return fetchCount();
    }, 45 * 1000);
    document.addEventListener('route_change', fetchCount);
    fetchCount();
    window.addEventListener("focus", onFocusFunction);
    window.addEventListener("blur", onBlurFunction);
    return function () {
      // Clean up events
      clearInterval(interval);
      document.removeEventListener('route_change', fetchCount);
      window.removeEventListener("focus", onFocusFunction);
      window.removeEventListener("blur", onBlurFunction);
    };
  }, []);
  return null;
};

export default NotificationProvider;