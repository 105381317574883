import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { GET_CURRENT_MEETINGS, GET_PAST_MEETINGS, GET_MEETING, GET_MEETING_INSTANCE, CREATE_MEETING, MEETING_INSTANCE_UPDATE_DATE, GET_USER_MEETINGS, MEETING_INSTANCE_UPDATE, CLEAR_MEETING, SAVE_WIZARD_MEETING, CLEAR_WIZARD_MEETING, DELETE_MEETING, DELETE_MEETING_INSTANCE } from 'actions/meetings';
var INITIAL_STATE = {
  current: [],
  past: [],
  user: [],
  meetingsByCreator: [],
  meeting: null,
  meetingInstance: null,
  createdId: 0,
  wizardMeeting: null,
  deletedMeeting: null
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  var meeting = _objectSpread({}, state.meeting);

  switch (action.type) {
    case GET_CURRENT_MEETINGS:
      if (action.payload != null) {
        return _objectSpread(_objectSpread({}, state), {}, {
          current: action.payload.filter(function (m) {
            return m.id != state.deletedMeeting;
          })
        });
      }

      return state;

    case GET_PAST_MEETINGS:
      if (action.payload != null) {
        return _objectSpread(_objectSpread({}, state), {}, {
          past: action.payload.filter(function (m) {
            return m.id != state.deletedMeeting;
          })
        });
      }

      return state;

    case GET_USER_MEETINGS:
      return _objectSpread(_objectSpread({}, state), {}, {
        user: action.payload
      });

    case GET_MEETING:
      return _objectSpread(_objectSpread({}, state), {}, {
        meeting: action.payload
      });

    case GET_MEETING_INSTANCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        meetingInstance: action.payload
      });

    case CREATE_MEETING:
      return _objectSpread(_objectSpread({}, state), {}, {
        meeting: action.payload
      });

    case CLEAR_MEETING:
      return _objectSpread(_objectSpread({}, state), {}, {
        meeting: null
      });

    case SAVE_WIZARD_MEETING:
      return _objectSpread(_objectSpread({}, state), {}, {
        wizardMeeting: action.payload
      });

    case CLEAR_WIZARD_MEETING:
      return _objectSpread(_objectSpread({}, state), {}, {
        wizardMeeting: INITIAL_STATE.wizardMeeting
      });

    case DELETE_MEETING:
      if (!action.payload) {
        return state;
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        current: _toConsumableArray(state.current).filter(function (m) {
          return m.id != action.payload;
        }),
        deletedMeeting: action.payload
      });

    case DELETE_MEETING_INSTANCE:
      if (!action.payload) {
        return state;
      }

      meeting.instances = meeting.instances.filter(function (i) {
        return i.id != action.payload;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        meeting: meeting
      });

    case MEETING_INSTANCE_UPDATE:
      return state;

    case MEETING_INSTANCE_UPDATE_DATE:
      meeting.instances = [].concat(_toConsumableArray(meeting.instances.filter(function (t) {
        return t.id != action.payload.id;
      })), [action.payload]);
      return _objectSpread(_objectSpread({}, state), {}, {
        meeting: meeting,
        meetingInstance: action.payload
      });

    default:
      return state;
  }
}
;