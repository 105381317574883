import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { ASSIGNMENT_CREATE, ASSIGNMENT_UPDATE, ACTIVE_TASK, GET_FOLDER_FILES, SET_ACTIVE_ASSIGNMENT, DELETE_ASSIGNMENT_FROM_STORE, CLEAR_ASSIGNMENTS_FROM_STORE, GET_TEAMS_ASSIGNMENTS, GET_TEAMS_ASSIGNMENT_DETAILS, GET_LATEST_MICROSOFT_ASSIGNMENTS, GET_LATEST_HALDOR_ASSIGNMENTS, GET_TEAMS_ASSIGNMENT_SUBMISSION, CLEAR_LATEST_ASSIGNMENTS, GET_HALDOR_ASSIGNMENTS, GET_MICROSOFT_EDUCATION_ASSIGNMENTS, SET_ASSIGNMENT_SERVICES, SEARCH_ASSIGNMENT, GET_ASSIGNMENT_STATUS, UPLOAD_USER_ONE_DRIVE_DOCUMENT, SET_MY_ASSIGNMENTS_ACTIVE_GROUP, GET_ASSIGNMENT_SECTION, CLEAR_ASSIGNMENT_SECTION, CREATE_ASSIGNMENT_TASK_WORKSPACE } from 'actions/assignments';
import Moment from 'moment';
import { ACTIVE_ASSIGNMENT_KNOWLEDGECOLLECTIONS } from 'actions/knowledge';
import { updateObjectInArray } from 'helpers/array';
import { CREATE_BULK_TASKS } from '../actions/assignments';

function addToAssignmentList(assignment, state) {
  if (state.assignments != null) {
    if (state.assignments.assignments != null) {
      if (state.assignments.assignments.length > 0) {
        var assignmentObj = state.assignments.assignments.find(function (itrAss) {
          return assignment.id == itrAss.id;
        });

        if (assignmentObj == null) {
          state.assignments.assignments.push(assignment);
        } else {
          state.assignments.assignments = state.assignments.assignments.filter(function (itrAss) {
            return assignment.id != itrAss.id;
          });
          state.assignments.assignments.push(assignment);
        }
      }
    }
  }
}

var INITIAL_STATE = {
  assignments: [],
  haldorAssignmentsFetchedUTC: null,
  teamsAssignmentsFetchedUTC: null,
  drafts: [],
  upcoming: [],
  overdue: [],
  current: [],
  submitted: [],
  closed: [],
  locked: [],
  teamsAssignments: [],
  active_assignment: null,
  active_knowledgeCollection: [],
  newAssignment: null,
  newAssignments: null,
  search_results: null,
  folderFiles: [],
  teamsAssignmentDetails: null,
  latestMicrosoftAssignments: [],
  latestHaldorAssignments: [],
  activeServices: {
    haldor: false,
    microsoft: false
  },
  uploadError: {},
  activeGroup: null,
  section: null
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case ACTIVE_TASK:
      return _objectSpread(_objectSpread({}, state), {}, {
        active_assignment: action.payload
      });

    case SET_ASSIGNMENT_SERVICES:
      return _objectSpread(_objectSpread({}, state), {}, {
        activeServices: action.payload
      });

    case CLEAR_LATEST_ASSIGNMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        latestMicrosoftAssignments: [],
        latestHaldorAssignments: []
      });

    case ACTIVE_ASSIGNMENT_KNOWLEDGECOLLECTIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        active_knowledgeCollection: action.payload
      });

    case GET_MICROSOFT_EDUCATION_ASSIGNMENTS:
      if (action.payload != null && action.payload.length > 0) {
        var educationDrafts = getDrafts(action.payload, state);
        var educationUpcoming = getUpcoming(action.payload, state);
        var educationOverdue = getOverdue(action.payload, state);
        var educationCurrent = getCurrent(action.payload, state);
        var educationSubmitted = getSubmitted(action.payload, state);
        var educationClosed = getClosed(action.payload, state);
        var educationLocked = getLocked(action.payload, state);
        var teamsAssignmentsFetchedUTC = Moment.utc();
        return _objectSpread(_objectSpread({}, state), {}, {
          closed: educationClosed,
          locked: educationLocked,
          submitted: educationSubmitted,
          current: educationCurrent,
          overdue: educationOverdue,
          upcoming: educationUpcoming,
          drafts: educationDrafts,
          teamsAssignments: action.payload,
          teamsAssignmentsFetchedUTC: teamsAssignmentsFetchedUTC
        });
      }

      return state;

    case GET_HALDOR_ASSIGNMENTS:
      var drafts = getDrafts(action.payload, state);
      var upcoming = getUpcoming(action.payload, state);
      var overdue = getOverdue(action.payload, state);
      var current = getCurrent(action.payload, state);
      var submitted = getSubmitted(action.payload, state);
      var closed = getClosed(action.payload, state);
      var locked = getLocked(action.payload, state);
      var haldorAssignmentsFetchedUTC = Moment.utc();
      return _objectSpread(_objectSpread({}, state), {}, {
        closed: closed,
        locked: locked,
        submitted: submitted,
        current: current,
        overdue: overdue,
        upcoming: upcoming,
        drafts: drafts,
        assignments: action.payload,
        haldorAssignmentsFetchedUTC: haldorAssignmentsFetchedUTC
      });

    case CREATE_ASSIGNMENT_TASK_WORKSPACE:
      var active_assignment = state.active_assignment;
      active_assignment.tasks[0].workspace = action.payload;
      return _objectSpread(_objectSpread({}, state), {}, {
        active_assignment: active_assignment
      });

    case SEARCH_ASSIGNMENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        search_results: action.payload
      });

    case GET_FOLDER_FILES:
      return _objectSpread(_objectSpread({}, state), {}, {
        folderFiles: action.payload
      });

    case ASSIGNMENT_CREATE:
      var createList = state.assignments;
      createList = updateObjectInArray(createList, action.payload);
      var updatedDrafts = getDrafts(action.payload, state);
      var updatedUpoming = getUpcoming(action.payload, state);
      var updatedOverdue = getOverdue(action.payload, state);
      var updatedCurrent = getCurrent(action.payload, state);
      var updatedSubmitted = getSubmitted(action.payload, state);
      var updatedClosed = getClosed(action.payload, state);
      var updatedLocked = getLocked(action.payload, state);
      return _objectSpread(_objectSpread({}, state), {}, {
        newAssignment: action.payload,
        assignments: createList,
        closed: updatedClosed,
        submitted: updatedSubmitted,
        current: updatedCurrent,
        overdue: updatedOverdue,
        upcoming: updatedUpoming,
        drafts: updatedDrafts,
        locked: updatedLocked
      });

    case CREATE_BULK_TASKS:
      var createBulkList = state.assignments;
      createBulkList = updateObjectInArray(createBulkList, action.payload);
      var updatedBulkDrafts = getDrafts(action.payload, state);
      var updatedBulkUpoming = getUpcoming(action.payload, state);
      var updatedBulkOverdue = getOverdue(action.payload, state);
      var updatedBulkCurrent = getCurrent(action.payload, state);
      var updatedBulkSubmitted = getSubmitted(action.payload, state);
      var updatedBulkClosed = getClosed(action.payload, state);
      var updatedBulkLocked = getLocked(action.payload, state);
      return _objectSpread(_objectSpread({}, state), {}, {
        assignments: createBulkList,
        closed: updatedBulkClosed,
        submitted: updatedBulkSubmitted,
        current: updatedBulkCurrent,
        overdue: updatedBulkOverdue,
        upcoming: updatedBulkUpoming,
        drafts: updatedBulkDrafts,
        newAssignments: action.payload,
        locked: updatedBulkLocked
      });

    case ASSIGNMENT_UPDATE:
      if (action.payload.tasks != null && action.payload.tasks.length > 0) {
        action.payload.tasks = action.payload.tasks.filter(function (task) {
          return task.markedForDeletion != true;
        });
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        active_assignment: action.payload,
        assignments: updateObjectInArray(state.assignments, action.payload, true),
        closed: updateObjectInArray(state.closed, action.payload, true),
        submitted: updateObjectInArray(state.submitted, action.payload, true),
        current: updateObjectInArray(state.current, action.payload, true),
        overdue: updateObjectInArray(state.overdue, action.payload, true),
        upcoming: updateObjectInArray(state.upcoming, action.payload, true),
        drafts: updateObjectInArray(state.drafts, action.payload, true)
      });

    case SET_ACTIVE_ASSIGNMENT:
      if (typeof action.id !== 'undefined' && action.id !== null) {
        var selected = null;

        if (action.id == parseInt(action.id, 10)) {
          selected = state.assignments.haldorAssignments.find(function (assignment) {
            return assignment.id == action.id;
          });
        } else {
          selected = state.assignments.microsoftAssignments.find(function (assignment) {
            return assignment.id == action.id;
          });
        }

        if (selected != null) {
          return _objectSpread(_objectSpread({}, state), {}, {
            active_assignment: selected
          });
        } else {
          return _objectSpread(_objectSpread({}, state), {}, {
            active_assignment: null
          });
        }
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        active_assignment: null
      });

    case DELETE_ASSIGNMENT_FROM_STORE:
      return _objectSpread(_objectSpread({}, state), {}, {
        assignments: _toConsumableArray(state.assignments).filter(function (t) {
          return t.id != action.payload;
        }),
        closed: _toConsumableArray(state.closed).filter(function (t) {
          return t.id != action.payload;
        }),
        submitted: _toConsumableArray(state.submitted).filter(function (t) {
          return t.id != action.payload;
        }),
        current: _toConsumableArray(state.current).filter(function (t) {
          return t.id != action.payload;
        }),
        overdue: _toConsumableArray(state.overdue).filter(function (t) {
          return t.id != action.payload;
        }),
        upcoming: _toConsumableArray(state.upcoming).filter(function (t) {
          return t.id != action.payload;
        }),
        drafts: _toConsumableArray(state.drafts).filter(function (t) {
          return t.id != action.payload;
        })
      });

    case CLEAR_ASSIGNMENTS_FROM_STORE:
      return _objectSpread(_objectSpread({}, state), {}, {
        closed: [],
        submitted: [],
        current: [],
        overdue: [],
        upcoming: [],
        drafts: [],
        assignments: []
      });

    case GET_TEAMS_ASSIGNMENTS:
      var teamsDrafts = getDrafts(action.payload, state);
      var teamsUpcoming = getUpcoming(action.payload, state);
      var teamsOverdue = getOverdue(action.payload, state);
      var teamsCurrent = getCurrent(action.payload, state);
      var teamsSubmitted = getSubmitted(action.payload, state);
      var teamsClosed = getClosed(action.payload, state);
      var teamsLocked = getLocked(action.payload, state);
      return _objectSpread(_objectSpread({}, state), {}, {
        teamsAssignments: action.paylod,
        // assignments: action.payload,
        closed: teamsClosed,
        submitted: teamsSubmitted,
        current: teamsCurrent,
        overdue: teamsOverdue,
        upcoming: teamsUpcoming,
        drafts: teamsDrafts,
        locked: teamsLocked
      });

    case GET_TEAMS_ASSIGNMENT_DETAILS:
      return _objectSpread(_objectSpread({}, state), {}, {
        teamsAssignmentDetails: action.payload
      });

    case GET_LATEST_HALDOR_ASSIGNMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        latestHaldorAssignments: action.payload
      });

    case GET_LATEST_MICROSOFT_ASSIGNMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        latestMicrosoftAssignments: action.payload
      });

    case GET_TEAMS_ASSIGNMENT_SUBMISSION:
      return _objectSpread(_objectSpread({}, state), {}, {
        submission: action.payload
      });

    case GET_ASSIGNMENT_STATUS:
      if (action.payload.created == true) {
        var newState = JSON.parse(JSON.stringify(state));
        var currentAssignmentIndex = newState.current.find(function (ss) {
          return ss.id == action.payload.assigmentId;
        });

        if (currentAssignmentIndex != null) {
          currentAssignmentIndex.created = Moment().format();

          if (newState.current != null) {
            newState.current = updateObjectInArray(newState.current, currentAssignmentIndex);
          }
        }

        var overdueAssignmentIndex = newState.overdue.find(function (ss) {
          return ss.id == action.payload.assigmentId;
        });

        if (overdueAssignmentIndex != null) {
          overdueAssignmentIndex.created = Moment().format();

          if (newState.overdue != null) {
            newState.overdue = updateObjectInArray(newState.overdue, overdueAssignmentIndex);
          }
        }

        if (newState.active_assignment != null) {
          newState.active_assignment = _objectSpread(_objectSpread({}, newState.active_assignment), {}, {
            created: action.payload.created == true ? Moment().format() : '0001-01-01T00:00:00',
            locked: !action.payload.created
          });
        }

        return newState;
      }

      return state;

    case UPLOAD_USER_ONE_DRIVE_DOCUMENT:
      var requestError = {};

      if (action.error) {
        requestError.error = true;
        requestError.message = action.payload.response.message;
        requestError.data = action.payload.response.data;
      } else {
        requestError.error = false;
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        uploadError: requestError
      });

    case SET_MY_ASSIGNMENTS_ACTIVE_GROUP:
      return _objectSpread(_objectSpread({}, state), {}, {
        activeGroup: action.payload
      });

    case GET_ASSIGNMENT_SECTION:
      return _objectSpread(_objectSpread({}, state), {}, {
        section: action.payload
      });

    case CLEAR_ASSIGNMENT_SECTION:
      return _objectSpread(_objectSpread({}, state), {}, {
        section: null
      });

    default:
      return state;
  }
}

function getDrafts(payload, state) {
  var list = state.drafts;

  if (Array.isArray(payload)) {
    var _iterator = _createForOfIteratorHelper(payload),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var assignment = _step.value;
        var foundIndex = list.findIndex(function (arrayObject) {
          return arrayObject.id == assignment.id;
        }); // Check if index was found, we need to replace 'object' with existing array entry

        if (foundIndex > -1) {
          list.splice(foundIndex, 1, assignment);
          break;
        }

        if (assignment.id == parseInt(assignment.id, 10)) {
          if (assignment.published == '0001-01-01T00:00:00' && list.findIndex(function (t) {
            return t.id == assignment.id;
          }) == -1) {
            list.push(assignment);
          }
        } else {
          var _state$activeServices;

          if (assignment.status == 'draft' && assignment.assignedDateTime == null && (assignment.reference == null || !((_state$activeServices = state.activeServices) !== null && _state$activeServices !== void 0 && _state$activeServices.haldor))) {
            list.push(assignment);
          }
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  } else {
    if (payload == null) {
      return list;
    }

    if (payload.id == parseInt(payload.id, 10)) {
      var _state$activeServices2;

      if (payload.status == 'ASSIGNMENT_DRAFT' && (payload.reference == null || !((_state$activeServices2 = state.activeServices) !== null && _state$activeServices2 !== void 0 && _state$activeServices2.haldor))) {
        list.push(payload);
      }
    } else {
      var _state$activeServices3;

      if (payload.status == 'draft' && payload.assignedDateTime == null && (payload.reference == null || !((_state$activeServices3 = state.activeServices) !== null && _state$activeServices3 !== void 0 && _state$activeServices3.haldor))) {
        list.push(payload);
      }
    }
  }

  list = sortList(list);
  return list;
}

function getOverdue(payload, state) {
  var timeNow = Moment();
  var list = state.overdue;

  if (Array.isArray(payload)) {
    var _iterator2 = _createForOfIteratorHelper(payload),
        _step2;

    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var assignment = _step2.value;
        var foundIndex = list.findIndex(function (arrayObject) {
          return arrayObject.id == assignment.id;
        }); // Check if index was found, we need to replace 'object' with existing array entry

        if (foundIndex > -1) {
          list.splice(foundIndex, 1, assignment);
          break;
        }

        if (assignment.id == parseInt(assignment.id, 10)) {
          // Haldor assignment
          var isBefore = timeNow.isBefore(Moment.utc(assignment.startDate).local());
          var isAfter = timeNow.isAfter(Moment.utc(assignment.dueDate).local());

          if ((assignment.status == 'ASSIGNMENT_OPEN' || assignment.status == 'ASSIGNMENT_LOCKED') && assignment.published != '0001-01-01T00:00:00' && !isBefore && isAfter) {
            if (assignment.tasks.length > 0) {
              var task = assignment.tasks[0];

              if (task.status == 'ASSIGNMENT_TASK_NOT_STARTED' || task.status == 'ASSIGNMENT_TASK_STARTED' || task.status == 'ASSIGNMENT_TASK_CAN_DEVELOP' || task.status == 'ASSIGNMENT_TASK_NOT_COMPLETE') {
                if (list.findIndex(function (t) {
                  return t.id == assignment.id;
                }) == -1) {
                  list.push(assignment);
                }
              }
            } else {
              if (list.findIndex(function (t) {
                return t.id == assignment.id;
              }) == -1) {
                list.push(assignment);
              }
            }
          }
        } else {
          var _state$activeServices4;

          // Microsoft assignment
          if (assignment.status == 'assigned' && timeNow.isAfter(assignment.dueDateTime) && (assignment.reference == null || !((_state$activeServices4 = state.activeServices) !== null && _state$activeServices4 !== void 0 && _state$activeServices4.haldor))) {
            list.push(assignment);
          }
        }
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
  } else {
    if (payload == null) {
      return list;
    }

    if (payload.id == parseInt(payload.id, 10)) {
      // Haldor assignment
      var _isBefore = timeNow.isBefore(Moment.utc(payload.startDate).local());

      var _isAfter = timeNow.isAfter(Moment.utc(payload.dueDate).local());

      if ((payload.status == 'ASSIGNMENT_OPEN' || assignment.status == 'ASSIGNMENT_LOCKED') && !_isBefore && _isAfter) {
        if (payload.tasks.length > 0) {
          var _task = payload.tasks[0];

          if (_task.status == 'ASSIGNMENT_TASK_NOT_STARTED' || _task.status == 'ASSIGNMENT_TASK_STARTED' || _task.status == 'ASSIGNMENT_TASK_CAN_DEVELOP' || _task.status == 'ASSIGNMENT_TASK_NOT_COMPLETE') {
            var _state$activeServices5;

            if (payload.reference == null || !((_state$activeServices5 = state.activeServices) !== null && _state$activeServices5 !== void 0 && _state$activeServices5.haldor)) {
              list.push(payload);
            }
          }
        } else {
          var _state$activeServices6;

          if (payload.reference == null || !((_state$activeServices6 = state.activeServices) !== null && _state$activeServices6 !== void 0 && _state$activeServices6.haldor)) {
            list.push(payload);
          }
        }
      }
    } else {
      var _state$activeServices7;

      // Microsoft assignment
      if (payload.status == 'assigned' && timeNow.isAfter(payload.dueDateTime) && (payload.reference == null || !((_state$activeServices7 = state.activeServices) !== null && _state$activeServices7 !== void 0 && _state$activeServices7.haldor))) {
        list.push(payload);
      }
    }
  }

  list = sortList(list);
  return list;
}

function getCurrent(payload, state) {
  var timeNow = Moment();
  var list = state.current;

  if (Array.isArray(payload)) {
    var _iterator3 = _createForOfIteratorHelper(payload),
        _step3;

    try {
      for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
        var assignment = _step3.value;
        var foundIndex = list.findIndex(function (arrayObject) {
          return arrayObject.id == assignment.id;
        }); // Check if index was found, we need to replace 'object' with existing array entry

        if (foundIndex > -1) {
          list.splice(foundIndex, 1, assignment);
          break;
        }

        if (assignment.id == parseInt(assignment.id, 10)) {
          var isBefore = timeNow.isBefore(Moment.utc(assignment.startDate).local());
          var isAfter = timeNow.isAfter(Moment.utc(assignment.dueDate).local());

          if ((assignment.status == 'ASSIGNMENT_OPEN' || assignment.status == 'ASSIGNMENT_LOCKED') && !isBefore && !isAfter && assignment.published !== '0001-01-01T00:00:00') {
            if (assignment.tasks.length == 0) {
              if (list.findIndex(function (t) {
                return t.id == assignment.id;
              }) == -1) {
                list.push(assignment);
              }
            } else if (assignment.tasks[0] != null && assignment.tasks[0].status != 'ASSIGNMENT_TASK_SUBMITTED') {
              if (list.findIndex(function (t) {
                return t.id == assignment.id;
              }) == -1) {
                list.push(assignment);
              }
            }
          }
        } else {
          if (assignment.status == 'assigned' && assignment.assignedDateTime != null && timeNow.isBefore(Moment(assignment.dueDateTime))) {
            var _state$activeServices8;

            if (assignment.reference == null || !((_state$activeServices8 = state.activeServices) !== null && _state$activeServices8 !== void 0 && _state$activeServices8.haldor)) {
              list.push(assignment);
            }
          }
        }
      }
    } catch (err) {
      _iterator3.e(err);
    } finally {
      _iterator3.f();
    }
  } else {
    if (payload == null) {
      return list;
    }

    if (payload.id == parseInt(payload.id, 10)) {
      var _isBefore2 = timeNow.isBefore(Moment.utc(payload.startDate).local());

      var _isAfter2 = timeNow.isAfter(Moment.utc(payload.dueDate).local());

      if ((payload.status == 'ASSIGNMENT_OPEN' || assignment.status == 'ASSIGNMENT_LOCKED') && !_isBefore2 && !_isAfter2 && (payload === null || payload === void 0 ? void 0 : payload.published) !== '0001-01-01T00:00:00') {
        if (payload.tasks.length == 0) {
          var _state$activeServices9;

          if (payload.reference == null || !((_state$activeServices9 = state.activeServices) !== null && _state$activeServices9 !== void 0 && _state$activeServices9.haldor)) {
            list.push(payload);
          }
        } else if (payload.tasks[0] != null && payload.tasks[0].status != 'ASSIGNMENT_TASK_SUBMITTED') {
          var _state$activeServices10;

          if (payload.reference == null || !((_state$activeServices10 = state.activeServices) !== null && _state$activeServices10 !== void 0 && _state$activeServices10.haldor)) {
            list.push(payload);
          }
        }
      }
    } else {
      if (payload.status == 'assigned' && payload.assignedDateTime != null && timeNow.isBefore(Moment(payload.dueDateTime))) {
        var _state$activeServices11;

        if (payload.reference == null || !((_state$activeServices11 = state.activeServices) !== null && _state$activeServices11 !== void 0 && _state$activeServices11.haldor)) {
          list.push(payload);
        }
      }
    }
  }

  list = sortListDesc(list);
  return list;
}

function getUpcoming(payload, state) {
  var timeNow = Moment();
  var list = state.upcoming;

  if (Array.isArray(payload)) {
    var _iterator4 = _createForOfIteratorHelper(payload),
        _step4;

    try {
      for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
        var assignment = _step4.value;
        var foundIndex = list.findIndex(function (arrayObject) {
          return arrayObject.id == assignment.id;
        }); // Check if index was found, we need to replace 'object' with existing array entry

        if (foundIndex > -1) {
          list.splice(foundIndex, 1, assignment);
          break;
        }

        if (assignment.id == parseInt(assignment.id, 10)) {
          var isBefore = timeNow.isBefore(Moment.utc(assignment.startDate).local());

          if ((assignment.status == 'ASSIGNMENT_OPEN' || assignment.status == 'ASSIGNMENT_LOCKED') && isBefore) {
            if (list.findIndex(function (t) {
              return t.id == assignment.id;
            }) == -1) {
              list.push(assignment);
            }
          }
        } else {
          if (assignment.status == 'assigned' && assignment.assignedDateTime != null && timeNow.isBefore(Moment(assignment.assignedDateTime))) {
            var _state$activeServices12;

            if (assignment.reference == null || !((_state$activeServices12 = state.activeServices) !== null && _state$activeServices12 !== void 0 && _state$activeServices12.haldor)) {
              list.push(assignment);
            }
          }
        }
      }
    } catch (err) {
      _iterator4.e(err);
    } finally {
      _iterator4.f();
    }
  } else {
    if (payload == null) {
      return list;
    }

    if (payload.id == parseInt(payload.id, 10)) {
      var _isBefore3 = timeNow.isBefore(Moment.utc(payload.startDate).local());

      if ((payload.status == 'ASSIGNMENT_OPEN' || assignment.status == 'ASSIGNMENT_LOCKED') && _isBefore3) {
        var _state$activeServices13;

        if (payload.reference == null || !((_state$activeServices13 = state.activeServices) !== null && _state$activeServices13 !== void 0 && _state$activeServices13.haldor)) {
          list.push(payload);
        }
      }
    } else {
      if (payload.status == 'assigned' && payload.assignedDateTime != null && timeNow.isBefore(Moment(payload.assignedDateTime))) {
        var _state$activeServices14;

        if (payload.reference == null || !((_state$activeServices14 = state.activeServices) !== null && _state$activeServices14 !== void 0 && _state$activeServices14.haldor)) {
          list.push(payload);
        }
      }
    }
  }

  list = sortList(list);
  return list;
}

function getSubmitted(payload, state) {
  var list = state.submitted;

  if (Array.isArray(payload)) {
    payload.forEach(function (assignment) {
      if (assignment.tasks != null && assignment.tasks.length > 0 && assignment.tasks[0] != null && assignment.tasks[0].status == 'ASSIGNMENT_TASK_SUBMITTED') {
        if (list.findIndex(function (t) {
          return t.id == assignment.id;
        }) == -1) {
          list.push(assignment);
        }
      }
    });
  } else {
    if (payload == null) {
      return list;
    }

    if (payload.tasks != null && payload.tasks.length > 0 && payload.tasks[0] != null && payload.tasks[0].status == 'ASSIGNMENT_TASK_SUBMITTED') {
      var _state$activeServices15;

      if (payload.reference == null || !((_state$activeServices15 = state.activeServices) !== null && _state$activeServices15 !== void 0 && _state$activeServices15.haldor)) {
        list.push(payload);
      }
    }
  }

  sortList(list);
  return list;
}

function getLocked(payload, state) {
  var list = state.locked;

  if (Array.isArray(payload)) {
    payload.forEach(function (assignment) {
      if (assignment.status == 'ASSIGNMENT_LOCKED' && assignment.published !== '0001-01-01T00:00:00') {
        if (list.findIndex(function (t) {
          return t.id == assignment.id;
        }) == -1) {
          list.push(assignment);
        }
      }
    });
  } else {
    if (payload == null) {
      return list;
    }

    if (payload.status == 'ASSIGNMENT_LOCKED' && (payload === null || payload === void 0 ? void 0 : payload.published) !== '0001-01-01T00:00:00') {
      var _state$activeServices16;

      if (payload.reference == null || !((_state$activeServices16 = state.activeServices) !== null && _state$activeServices16 !== void 0 && _state$activeServices16.haldor)) {
        list.push(payload);
      }
    }
  }

  list = sortList(list);
  return list;
}

function getClosed(payload, state) {
  var list = state.closed;

  if (Array.isArray(payload)) {
    payload.forEach(function (assignment) {
      if (assignment.status == 'ASSIGNMENT_CLOSED' && assignment.published !== '0001-01-01T00:00:00') {
        if (list.findIndex(function (t) {
          return t.id == assignment.id;
        }) == -1) {
          list.push(assignment);
        }
      }
    });
  } else {
    if (payload == null) {
      return list;
    }

    if (payload.status == 'ASSIGNMENT_CLOSED' && (payload === null || payload === void 0 ? void 0 : payload.published) !== '0001-01-01T00:00:00') {
      var _state$activeServices17;

      if (payload.reference == null || !((_state$activeServices17 = state.activeServices) !== null && _state$activeServices17 !== void 0 && _state$activeServices17.haldor)) {
        list.push(payload);
      }
    }
  }

  list = sortList(list);
  return list;
}

function sortList(list) {
  return list.sort(function (a, b) {
    var dateA = a.dueDate;
    var dateB = b.dueDate;

    if (a.displayName != null) {
      dateA = a.dueDateTime;
    }

    if (b.displayName != null) {
      dateB = b.dueDateTime;
    }

    return Moment(dateB).unix() - Moment(dateA).unix();
  });
}

function sortListDesc(list) {
  return list.sort(function (a, b) {
    var dateA = a.dueDate;
    var dateB = b.dueDate;

    if (a.displayName != null) {
      dateA = a.dueDateTime;
    }

    if (b.displayName != null) {
      dateB = b.dueDateTime;
    }

    return Moment(dateA).unix() - Moment(dateB).unix();
  });
}