import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { REGISTER_LANGUAGES, GET_TRANSLATIONS_FOR_LANGUAGE, LOAD_TRANSLATIONS_FILE } from 'actions/languages';
var INITIAL_STATE = {
  translations: null,
  languages: null
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case REGISTER_LANGUAGES:
      return _objectSpread(_objectSpread({}, state), {}, {
        languages: action.payload
      });

    case GET_TRANSLATIONS_FOR_LANGUAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        translations: action.payload
      });

    case LOAD_TRANSLATIONS_FILE:
      return _objectSpread(_objectSpread({}, state), {}, {
        translations: [].concat(_toConsumableArray(state.translations), _toConsumableArray(action.payload))
      });

    default:
      return state;
  }
}