import api from 'lib/api';
export var GET_SUBJECTS = 'SUBJECTS_GET_SUBJECTS';
export var GET_TENANT_SUBJECTS = 'SUBJECTS_GET_TENANT_SUBJECTS';
export var SEARCH_SUBJECTS = 'SUBJECTS_SEARCH_SUBJECTS';
export var CREATE_SUBJECT = 'SUBJECTS_CREATE_SUBJECT';
export var GET_SECTION_SUBJECTS = 'SUBJECTS_GET_SECTION_SUBJECTS';
export var getSubjects = function getSubjects() {
  return {
    type: GET_SUBJECTS,
    payload: new Promise(function (resolve) {
      api.get("subjects").then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getTenantSubjects = function getTenantSubjects(tenantId) {
  return {
    type: GET_TENANT_SUBJECTS,
    payload: new Promise(function (resolve) {
      api.get("subjects/tenants/".concat(tenantId)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var searchSubjects = function searchSubjects(query) {
  return {
    type: SEARCH_SUBJECTS,
    payload: new Promise(function (resolve) {
      api.get("subjects/search/".concat(query)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var createSubject = function createSubject(values) {
  return {
    type: CREATE_SUBJECT,
    payload: new Promise(function (resolve, reject) {
      api.post("subjects", values).then(function (response) {
        resolve(response.data);
      })["catch"](function (error) {
        reject(error);
      });
    })
  };
};
export var getSectionSubjects = function getSectionSubjects(sectionId, start, end) {
  return {
    type: GET_SECTION_SUBJECTS,
    payload: new Promise(function (resolve) {
      api.get("assessments/v2/sections/".concat(sectionId, "/subjects?startDate=").concat(start, "&endDate=").concat(end)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};