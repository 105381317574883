import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import api from 'lib/api';
export var GET_ADDITIONAL_ADJUSTMENTS = 'ADDITIONAL_ADJUSTMENTS_GET_ADJUSTMENTS';
export var GET_STUDENT_ADJUSTMENTS = 'ADDITIONAL_ADJUSTMENTS_GET_STUDENT_ADJUSTMENTS';
export var CREATE_STUDENT_ADJUSTMENT = 'ADDITIONAL_ADJUSTMENTS_CREATE_STUDENT_ADJUSTMENT';
export var UPDATE_STUDENT_ADJUSTMENT = 'ADDITIONAL_ADJUSTMENTS_UPDATE_STUDENT_ADJUSTMENT';
export var ADD_ADJUSTMENT_COMMENT = 'ADDITIONAL_ADJUSTMENTS_ADD_ADJUSTMENT_COMMENT';
export var GET_SCHOOL_ADJUSTMENTS = 'GET_SCHOOL_ADJUSTMENTS';
export var GET_GROUP_ADJUSTMENTS = 'ADDITIONAL_ADJUSTMENTS_GET_GROUP_ADJUSTMENTS';
export var GET_GROUP_ADJUSTMENTS_MULTIPLE = 'ADDITIONAL_ADJUSTMENTS_GET_GROUP_ADJUSTMENTS_MULTIPLE';
export var GET_STUDENT_ADJUSTMENTS_BY_COURSE = 'GET_STUDENT_ADJUSTMENTS_BY_COURSE';
export var GET_STUDENT_ADJUSTMENTS_BY_SUBJECT = 'GET_STUDENT_ADJUSTMENTS_BY_SUBJECT';
export var CREATE_ADDITIONAL_ADJUSTMENT = 'CREATE_ADDITIONAL_ADJUSTMENT';
export var SWITCH_ACTIVE_ADDITIONAL_ADJUSTMENT = 'SWITCH_ACTIVE_ADDITIONAL_ADJUSTMENT';
export var UPDATE_ADDITIONAL_ADJUSTMENT = 'UPDATE_ADDITIONAL_ADJUSTMENT';
export var UPDATE_STUDENT_ADDITIONAL_ADJUSTMENT_COMMENT = 'UPDATE_STUDENT_ADDITIONAL_ADJUSTMENT_COMMENT';
export var DELETE_ADDITIONAL_ADJUSTMENT = 'ADDITIONAL_ADJUSTMENTS_DELETE_ADDITIONAL_ADJUSTMENT';
export var DELETE_STUDENT_ADDITIONAL_ADJUSTMENT = 'ADDITIONAL_ADJUSTMENTS_DELETE_STUDENT_ADDITIONAL_ADJUSTMENT';
export var DELETE_STUDENT_ADDITIONAL_ADJUSTMENT_COMMENT = 'DELETE_STUDENT_ADDITIONAL_ADJUSTMENT_COMMENT';
export var getAdditionalAdjustments = function getAdditionalAdjustments() {
  return {
    type: GET_ADDITIONAL_ADJUSTMENTS,
    payload: new Promise(function (resolve) {
      api.get('AdditionalAdjustments').then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getStudentAdjustments = function getStudentAdjustments(student, start, end) {
  return {
    type: GET_STUDENT_ADJUSTMENTS,
    payload: new Promise(function (resolve) {
      api.get("StudentAdditionalAdjustments?studentId=".concat(student, "&start=").concat(start, "&end=").concat(end)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getStudentAdjustmentsByCourse = function getStudentAdjustmentsByCourse(student, courseId) {
  return {
    type: GET_STUDENT_ADJUSTMENTS_BY_COURSE,
    payload: new Promise(function (resolve) {
      api.get("StudentAdditionalAdjustments?studentId=".concat(student, "&courseId=").concat(courseId)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getStudentAdjustmentsBySubject = function getStudentAdjustmentsBySubject(groupId, subjectId, studentId) {
  return {
    type: GET_STUDENT_ADJUSTMENTS_BY_SUBJECT,
    payload: new Promise(function (resolve) {
      api.get("sections/".concat(groupId, "/subjects/").concat(subjectId, "/studentAdditionalAdjustments?userId=").concat(studentId)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var createStudentAdjustment = function createStudentAdjustment(values) {
  return {
    type: CREATE_STUDENT_ADJUSTMENT,
    payload: new Promise(function (resolve) {
      api.post('StudentAdditionalAdjustments', values).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var updateStudentAdjustment = function updateStudentAdjustment(values) {
  return {
    type: UPDATE_STUDENT_ADJUSTMENT,
    payload: new Promise(function (resolve) {
      api.put('StudentAdditionalAdjustments', values).then(function (response) {
        resolve(_objectSpread({
          oldId: values.id
        }, response.data));
      });
    })
  };
};
export var addComment = function addComment(values, studentAdjustment) {
  return {
    type: ADD_ADJUSTMENT_COMMENT,
    payload: new Promise(function (resolve) {
      api.post("StudentAdditionalAdjustments/".concat(studentAdjustment, "/comments"), values).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getSchoolAdjustments = function getSchoolAdjustments() {
  return {
    type: GET_SCHOOL_ADJUSTMENTS,
    payload: new Promise(function (resolve) {
      api.get('StudentAdditionalAdjustments').then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var createAdditionalAdjustment = function createAdditionalAdjustment(values) {
  return {
    type: CREATE_ADDITIONAL_ADJUSTMENT,
    payload: new Promise(function (resolve) {
      api.post('AdditionalAdjustments', values).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var switchActiveAdjustment = function switchActiveAdjustment(values) {
  return {
    type: SWITCH_ACTIVE_ADDITIONAL_ADJUSTMENT,
    payload: new Promise(function (resolve) {
      values.items = [];
      api.put('AdditionalAdjustments/Disable', values).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var updateAdditionalAdjustment = function updateAdditionalAdjustment(values) {
  return {
    type: UPDATE_ADDITIONAL_ADJUSTMENT,
    payload: new Promise(function (resolve) {
      values.items = [];
      api.put('AdditionalAdjustments', values).then(function (response) {
        resolve(_objectSpread({
          oldId: values.id
        }, response.data));
      });
    })
  };
};
export var deleteStudentAdjustment = function deleteStudentAdjustment(adjustment) {
  return {
    type: DELETE_STUDENT_ADDITIONAL_ADJUSTMENT,
    payload: new Promise(function (resolve) {
      api["delete"]("StudentAdditionalAdjustments/".concat(adjustment.id)).then(function (response) {
        resolve(adjustment.id);
      });
    })
  };
};
export var deleteStudentAdditionalAdjustmentComment = function deleteStudentAdditionalAdjustmentComment(comment) {
  return {
    type: DELETE_STUDENT_ADDITIONAL_ADJUSTMENT_COMMENT,
    payload: new Promise(function (resolve) {
      api["delete"]("StudentAdditionalAdjustments/".concat(comment.studentAdjustmentID, "/comments/").concat(comment.id)).then(function (response) {
        if (response.data == true) {
          resolve(comment);
        }
      });
    })
  };
};
export var updateStudentAdditionalAdjustmentComment = function updateStudentAdditionalAdjustmentComment(values) {
  return {
    type: UPDATE_STUDENT_ADDITIONAL_ADJUSTMENT_COMMENT,
    payload: new Promise(function (resolve) {
      api.put("StudentAdditionalAdjustments/".concat(values.studentAdjustmentID, "/comments"), values).then(function (response) {
        resolve(values);
      });
    })
  };
};
export var deleteAdditionalAdjustment = function deleteAdditionalAdjustment(adjustment) {
  return {
    type: DELETE_ADDITIONAL_ADJUSTMENT,
    payload: new Promise(function (resolve) {
      api["delete"]("AdditionalAdjustments/".concat(adjustment.id)).then(function (response) {
        resolve(adjustment.id);
      });
    })
  };
};