import api from 'lib/api';
export var GET_HALDOR_ASSIGNMENTS = 'GET_HALDOR_ASSIGNMENTS';
export var GET_MICROSOFT_EDUCATION_ASSIGNMENTS = 'GET_MICROSOFT_EDUCATION_ASSIGNMENTS';
export var ACTIVE_TASK = 'ACTIVE_TASK';
export var ACTIVE_ASSIGNMENT_KNOWLEDGECOLLECTIONS = 'ACTIVE_ASSIGNMENT_KNOWLEDGECOLLECTIONS';
export var GET_FOLDER_FILES = 'GET_FOLDER_FILES';
export var SET_ACTIVE_ASSIGNMENT = 'SET_ACTIVE_ASSIGNMENT';
export var DELETE_ASSIGNMENT_FROM_STORE = 'DELETE_ASSIGNMENT_FROM_STORE';
export var CLEAR_ASSIGNMENTS_FROM_STORE = 'CLEAR_ASSIGNMENTS_FROM_STORE';
export var DELETE_USER_ASSIGNMENT_DOCUMENT = 'DELETE_USER_ASSIGNMENT_DOCUMENT';
export var UPLOAD_USER_ONE_DRIVE_DOCUMENT = 'UPLOAD_USER_ONE_DRIVE_DOCUMENT';
export var ASSIGNMENT_CREATE = 'ASSIGNMENTS_ASSIGNMENT_CREATE';
export var ASSIGNMENT_UPDATE = 'ASSIGNMENTS_ASSIGNMENT_UPDATE';
export var CREATE_BULK_TASKS = 'ASSIGNMENTS_CREATE_BULK_TASKS';
export var GET_TEAMS_ASSIGNMENTS = 'ASSIGNMENTS_GET_TEAMS_ASSIGNMENT';
export var GET_TEAMS_ASSIGNMENT_DETAILS = 'ASSIGNMENT_SGET_TEAMS_ASSIGNMENT_DETAILS';
export var GET_LATEST_MICROSOFT_ASSIGNMENTS = 'GET_LATEST_MICROSOFT_ASSIGNMENTS';
export var GET_LATEST_HALDOR_ASSIGNMENTS = 'GET_LATEST_HALDOR_ASSIGNMENTS';
export var GET_TEAMS_ASSIGNMENT_SUBMISSION = 'ASSIGNMENTS_GET_TEAMS_ASSIGNMENT_SUBMISSION';
export var CLEAR_LATEST_ASSIGNMENTS = 'CLEAR_LATEST_ASSIGNMENTS';
export var SET_ASSIGNMENT_SERVICES = 'SET_ASSIGNMENT_SERVICES';
export var SEARCH_ASSIGNMENT = 'SEARCH_ASSIGNMENT';
export var GET_ASSIGNMENT_STATUS = 'ASSIGNMENTS_GET_ASSIGNMENT_STATUS';
export var GET_ASSIGNMENT_STUDENT_ADJUSTMENTS = 'ASSIGNMENTS_GET_ASSIGNMENT_STUDENT_ADJUSTMENTS';
export var SET_MY_ASSIGNMENTS_ACTIVE_GROUP = 'ASSIGNMENTS_SET_ACTIVE_GROUP_ON_MY_ASSIGNMENTS';
export var GET_ASSIGNMENT_SECTION = 'ASSIGNMENTS_GET_ASSIGNMENT_SECTION';
export var CLEAR_ASSIGNMENT_SECTION = 'ASSIGNMENTS_CLEAR_ASSIGNMENT_SECTION';
export var CREATE_ASSIGNMENT_TASK_WORKSPACE = 'CREATE_ASSIGNMENT_TASK_WORKSPACE';
export var getMicrosoftEducationAssignments = function getMicrosoftEducationAssignments(graphId) {
  return {
    type: GET_MICROSOFT_EDUCATION_ASSIGNMENTS,
    payload: new Promise(function (resolve) {
      var endpoint = 'assignments?origin=MS_ASSIGNMENTS';

      if (graphId != null) {
        endpoint += '&graphId=' + graphId;
      }

      api.get(endpoint).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getHaldorAssignments = function getHaldorAssignments(graphId) {
  return {
    type: GET_HALDOR_ASSIGNMENTS,
    payload: new Promise(function (resolve) {
      var endpoint = 'assignments?origin=HALDOR_ASSIGNMENTS';

      if (graphId != null) {
        endpoint += '&graphId=' + graphId;
      }

      api.get(endpoint).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var createAssignment = function createAssignment(values) {
  return {
    type: ASSIGNMENT_CREATE,
    payload: new Promise(function (resolve) {
      var json = JSON.stringify(values);
      api.post('assignments', json).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var updateAssignment = function updateAssignment(values) {
  return {
    type: ASSIGNMENT_UPDATE,
    payload: new Promise(function (resolve) {
      api.put('assignments', values).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var uploadUserOneDrive = function uploadUserOneDrive(assignmentId, files) {
  return {
    type: UPLOAD_USER_ONE_DRIVE_DOCUMENT,
    payload: new Promise(function (resolve, reject) {
      api.post("assignments/".concat(assignmentId, "/uploadOneDriveDocuments"), files).then(function (response) {
        resolve(response.data);
      })["catch"](function (error) {
        reject(error);
      });
    })
  };
};
export var createBulkAssignments = function createBulkAssignments(values) {
  return {
    type: CREATE_BULK_TASKS,
    payload: new Promise(function (resolve) {
      api.post('assignments/bulk', values).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var createAssignmentTaskWorkspace = function createAssignmentTaskWorkspace(assignmentId, assignmentTaskId) {
  return {
    type: CREATE_ASSIGNMENT_TASK_WORKSPACE,
    payload: new Promise(function (resolve) {
      api.put("assignments/".concat(assignmentId, "/tasks/").concat(assignmentTaskId)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var updateBulkAssignmentObject = function updateBulkAssignmentObject(id) {
  var origin = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return {
    type: CREATE_BULK_TASKS,
    payload: new Promise(function (resolve) {
      var endpoint = "assignments/".concat(id);

      if (origin) {
        endpoint = endpoint + "?origin=HALDOR_ASSIGNMENTS";
      }

      api.get(endpoint).then(function (response) {
        resolve([response.data]);
      });
    })
  };
};
export var getTaskDetails = function getTaskDetails(id, team) {
  return {
    type: ACTIVE_TASK,
    payload: new Promise(function (resolve, reject) {
      var url = "assignments/".concat(id);

      if (team != null) {
        url += "?groupId=".concat(team);
      }

      api.get(url).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getAssignmentKnowledgeCollections = function getAssignmentKnowledgeCollections(sectionId, assignmentId) {
  return {
    type: ACTIVE_ASSIGNMENT_KNOWLEDGECOLLECTIONS,
    payload: new Promise(function (resolve) {
      api.get("knowledge/section/".concat(sectionId, "/assignment/").concat(assignmentId, "/collections")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getFolderFiles = function getFolderFiles(assignmentId, taskId) {
  return {
    type: GET_FOLDER_FILES,
    payload: new Promise(function (resolve) {
      api.get("assignments/".concat(assignmentId, "/task/").concat(taskId, "/files")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var deleteAssignmentUserDocument = function deleteAssignmentUserDocument(assignmentId, fileName) {
  return {
    type: DELETE_USER_ASSIGNMENT_DOCUMENT,
    payload: new Promise(function (resolve) {
      var values = {
        assignmentId: assignmentId,
        fileName: fileName
      };
      api["delete"]("assignments/".concat(assignmentId, "/deleteDocument/").concat(fileName), values).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var setActiveAssignment = function setActiveAssignment(id) {
  return {
    type: SET_ACTIVE_ASSIGNMENT,
    payload: id
  };
};
export var deleteAssignmentFromStore = function deleteAssignmentFromStore(id) {
  return {
    type: DELETE_ASSIGNMENT_FROM_STORE,
    payload: new Promise(function (resolve) {
      resolve(id);
    })
  };
};
export var clearAssignmentsFromStore = function clearAssignmentsFromStore() {
  return {
    type: CLEAR_ASSIGNMENTS_FROM_STORE,
    payload: true
  };
};
export var getLatestMicrosoftEducationAssignments = function getLatestMicrosoftEducationAssignments(team) {
  return {
    type: GET_LATEST_MICROSOFT_ASSIGNMENTS,
    payload: new Promise(function (resolve) {
      api.get("assignments/?graphId=".concat(team, "&top=5&origin=MS_ASSIGNMENTS")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getLatestHaldorAssignments = function getLatestHaldorAssignments(team) {
  return {
    type: GET_LATEST_HALDOR_ASSIGNMENTS,
    payload: new Promise(function (resolve) {
      api.get("assignments/?graphId=".concat(team, "&top=5&origin=HALDOR_ASSIGNMENTS")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getMicrosoftAssignments = function getMicrosoftAssignments(team) {
  return {
    type: GET_TEAMS_ASSIGNMENTS,
    payload: new Promise(function (resolve) {
      api.get("assignments/?graphId=".concat(team, "&origin=MS_ASSIGNMENTS")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getMicrosoftAssignmentDetails = function getMicrosoftAssignmentDetails(id) {
  return {
    type: GET_TEAMS_ASSIGNMENT_DETAILS,
    payload: new Promise(function (resolve) {
      api.get("assignments/".concat(id, "/details")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getAssignmentSubmission = function getAssignmentSubmission(assignment, submission) {
  return {
    type: GET_TEAMS_ASSIGNMENT_SUBMISSION,
    payload: new Promise(function (resolve) {
      api.get("assignments/".concat(assignment, "/submissions/").concat(submission, "/files")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var clearLatestAssignments = function clearLatestAssignments() {
  return {
    type: CLEAR_LATEST_ASSIGNMENTS,
    payload: []
  };
};
export var setAssignmentServices = function setAssignmentServices(services) {
  return {
    type: SET_ASSIGNMENT_SERVICES,
    payload: services
  };
};
export var searchAssignment = function searchAssignment(value) {
  return {
    type: SEARCH_ASSIGNMENT,
    payload: new Promise(function (resolve) {
      api.get("assignments/search/".concat(value)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getAssignmentStatus = function getAssignmentStatus(assignmentId) {
  return {
    type: GET_ASSIGNMENT_STATUS,
    payload: new Promise(function (resolve) {
      api.get("assignments/".concat(assignmentId, "/status")).then(function (response) {
        var reponseObject = {
          assigmentId: assignmentId,
          created: response.data
        };
        resolve(reponseObject);
      });
    })
  };
};
export var getStudentAdditionalAdjustmentsForAssignment = function getStudentAdditionalAdjustmentsForAssignment(assignmentId) {
  return {
    type: GET_ASSIGNMENT_STUDENT_ADJUSTMENTS,
    payload: new Promise(function (resolve) {
      api.get("assignments/".concat(assignmentId, "/studentAdditionalAdjustments")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var setMyAssignmentsActiveGroup = function setMyAssignmentsActiveGroup(groupId) {
  return {
    type: SET_MY_ASSIGNMENTS_ACTIVE_GROUP,
    payload: groupId
  };
};
export var getAssignmentSection = function getAssignmentSection(id, callback) {
  return {
    type: GET_ASSIGNMENT_SECTION,
    payload: new Promise(function (resolve) {
      api.get("sections/".concat(id)).then(function (response) {
        var data = response.data;

        if (data && data.courses != null && data.courses.length > 0) {
          data.courses.forEach(function (element) {
            var sortedList = [];

            if (element.details != null && element.details.length > 0) {
              var activeDetailIndex = element.details.findIndex(function (element) {
                return element.endDate == null;
              });

              if (activeDetailIndex > -1) {
                var activeDetail = element.details[activeDetailIndex];
                element.details.splice(activeDetailIndex, 1);
                element.details.unshift(activeDetail);
              }
            }
          });
        }

        resolve(response.data);

        if (callback != null) {
          callback(response.data);
        }
      });
    })
  };
};
export var clearAssignmentSection = function clearAssignmentSection() {
  return {
    type: CLEAR_ASSIGNMENT_SECTION,
    payload: true
  };
};