import api from 'lib/api';
export var GET_FORECAST = 'ABSENCE_GET_FORECAST';
export var GET_CALENDAR_ATTENDENCE = 'ABSENCE_GET_CALENDAR_ATTENDENCE';
export var GET_SCHOOL_STUDENTS_PAGE = 'ABSENCE_GET_SCHOOL_STUDENTS_PAGE';
export var GET_USER_ATTENDANCE = 'ABSENCE_GET_USER_ATTENDANCE';
export var CLEAR_USER_ATTENDANCE = 'ABSENCE_CLEAR_USER_ATTENDANCE';
export var CLEAR_FORECAST = 'ABSENCE_CLEAR_FORECAST';
export var SUBMIT_ATTENDANCE = 'ABSENCE_SUBMIT_ATTENDANCE';
export var UPDATE_ATTENDANCE = 'ABSENCE_UPDATE_ATTENDANCE';
export var SUBMIT_ABSENCE = 'ABSENCE_SUBMIT_ABSENCE';
export var UPDATE_ABSENCE = 'ABSENCE_UPDATE_ABSENCE';
export var DELETE_ABSENCE = 'DELETE_ABSENCE';
export var DELETE_REPORTED_ATTENDANCE = 'ATTENDANCE_DELETE_REPORTED_ATTENDANCE';
export var GET_USER_ATTENDANCE_STATS = 'ABSENCE_GET_USER_ATTENDANCE_STATS'; // Get forecast before reporting attendence

export var getForecast = function getForecast(calendarId) {
  return {
    type: GET_FORECAST,
    payload: new Promise(function (resolve) {
      api.get("attendance/Forecast/?calendarEventId=".concat(calendarId)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var clearForecast = function clearForecast() {
  return {
    type: CLEAR_FORECAST,
    payload: true
  };
}; // Get reported attendence?

export var getAttendence = function getAttendence(calendarId) {
  return {
    type: GET_CALENDAR_ATTENDENCE,
    payload: new Promise(function (resolve) {
      api.get("attendance/ForCalendarEvent?calendarEventId=".concat(calendarId)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var submitAttendence = function submitAttendence(values, lessonId) {
  return {
    type: SUBMIT_ATTENDANCE,
    payload: new Promise(function (resolve, reject) {
      api.post("attendance/?calendarEventId=".concat(lessonId), values).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var updateAttendance = function updateAttendance(values, lessonId) {
  return {
    type: UPDATE_ATTENDANCE,
    payload: new Promise(function (resolve, reject) {
      api.put("attendance/?calendarEventId=".concat(lessonId), values).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var submitAbsence = function submitAbsence(values) {
  return {
    type: SUBMIT_ABSENCE,
    payload: new Promise(function (resolve, reject) {
      api.post('absence', values).then(function (response) {
        resolve(response.data);
      })["catch"](function (error) {
        reject(error);
      });
    })
  };
};
export var updateAbsence = function updateAbsence(values) {
  return {
    type: UPDATE_ABSENCE,
    payload: new Promise(function (resolve, reject) {
      api.put('absence', values).then(function (response) {
        resolve(response.data);
      })["catch"](function (error) {
        reject(error);
      });
    })
  };
};
export var getUserAttendanceStatistics = function getUserAttendanceStatistics(userId, start, end) {
  return {
    type: GET_USER_ATTENDANCE_STATS,
    payload: new Promise(function (resolve) {
      api.get("attendance/Statistics?studentId=".concat(userId, "&startDate=").concat(start, "&endDate=").concat(end)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getUserAttendance = function getUserAttendance(userId, start, end, sectionId) {
  return {
    type: GET_USER_ATTENDANCE,
    payload: new Promise(function (resolve) {
      if (sectionId != 0 && sectionId != null) {
        api.get("attendance/AttendanceDetails?studentId=".concat(userId, "&startTime=").concat(start, "&endTime=").concat(end, "&sectionId=").concat(sectionId)).then(function (response) {
          resolve(response.data);
        });
        return true;
      }

      api.get("attendance/AttendanceDetails?studentId=".concat(userId, "&startTime=").concat(start, "&endTime=").concat(end)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var clearUserAttendance = function clearUserAttendance() {
  return {
    type: CLEAR_USER_ATTENDANCE,
    payload: true
  };
};
export var deleteAbsence = function deleteAbsence(absenceId) {
  return {
    type: DELETE_ABSENCE,
    payload: new Promise(function (resolve) {
      api["delete"]("absence/".concat(absenceId)).then(function (response) {
        resolve(absenceId);
      });
    })
  };
};
export var deleteReportedAttendance = function deleteReportedAttendance(calendarEventId) {
  return {
    type: DELETE_REPORTED_ATTENDANCE,
    payload: new Promise(function (resolve) {
      api["delete"]("attendance/".concat(calendarEventId)).then(function (response) {
        resolve(calendarEventId);
      });
    })
  };
};