import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import api from 'lib/api';
var MESSAGES_PER_PAGE = 50;
export var GET_CONVERSATIONS = 'CONVERSATIONS_GET_CONVERSATIONS';
export var GET_CONVERSATIONS_REFERENCE = 'CONVERSATIONS_GET_CONVERSATIONS_BY_REFERENCE';
export var GET_CONVERSATION = 'CONVERSATIONS_GET_CONVERSATION';
export var GET_ARCHIVED_CONVERSATIONS = 'CONVERSATIONS_GET_ARCHIVED_CONVERSATIONS';
export var RESET_CONVERSATION = 'CONVERSATIONS_RESET_CONVERSATION';
export var GET_CONVERSATION_MESSAGES = 'CONVERSATION_GET_CONVERSATION_MESSAGES';
export var GET_USER_CONTACTS = 'CONVERSATION_GET_USER_CONTACTS';
export var SEARCH_CONVERSATIONS = 'CONVERSATION_SEARCH_CONVERSATIONS';
export var CLEAR_SEARCH_CONVERSATIONS = 'CONVERSATION_CLEAR_SEARCH_CONVERSATIONS';
export var UPDATE_CONVERSATION = 'CONVERSATION_UPDATE_CONVERSATION';
export var ARCHIVE_CONVERSATION = 'CONVERSATION_ARCHIVE_CONVERSATION';
export var GET_USER_GUARDIAN = 'CONVERSATION_GET_USER_GUARDIAN';
export var FLAG_MESSAGE = 'CONVERSATION_FLAG_MESSAGE';
export var STAGE_CONVERSATION = 'CONVERSATION_STAGE_NEW_CONVERSATION';
export var SUBMIT_CONVERSATION = 'CONVERSATION_SUBMIT_NEW_CONVERSATION';
export var SUBMIT_CONVERSATION_MESSAGE = 'CONVERSATION_SUBMIT_CONVERSATION_MESSAGE';
export var getConversations = function getConversations() {
  return {
    type: GET_CONVERSATIONS,
    payload: new Promise(function (resolve) {
      api.get("conversations").then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getConversationsByReference = function getConversationsByReference(referenceId, referenceType) {
  return {
    type: GET_CONVERSATIONS_REFERENCE,
    payload: new Promise(function (resolve) {
      api.get("conversations?referenceId=".concat(referenceId, "&referenceType=").concat(referenceType)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getArchivedConversations = function getArchivedConversations() {
  return {
    type: GET_ARCHIVED_CONVERSATIONS,
    payload: new Promise(function (resolve) {
      api.get("conversations?archived=true").then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getConversation = function getConversation(id) {
  return {
    type: GET_CONVERSATION,
    payload: new Promise(function (resolve) {
      api.get("conversations/".concat(id)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getMessages = function getMessages(conversation, page) {
  return {
    type: GET_CONVERSATION_MESSAGES,
    payload: new Promise(function (resolve) {
      var skip = page * MESSAGES_PER_PAGE;
      var top = skip + MESSAGES_PER_PAGE;
      api.get("conversations/".concat(conversation, "/messages?skip=").concat(skip, "&top=").concat(top)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var submitMessage = function submitMessage(message) {
  return {
    type: SUBMIT_CONVERSATION_MESSAGE,
    payload: new Promise(function (resolve) {
      api.post('conversations/message', message).then(function (response) {
        var data = _objectSpread(_objectSpread({}, message), {}, {
          id: response.data
        });

        resolve(data);
      });
    })
  };
};
export var createConversation = function createConversation(conversation) {
  return {
    type: SUBMIT_CONVERSATION,
    payload: new Promise(function (resolve) {
      if (conversation.id != null) {
        delete conversation.id;
      }

      api.post("conversations", conversation).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var stageConversation = function stageConversation(conversation) {
  return {
    type: STAGE_CONVERSATION,
    payload: new Promise(function (resolve) {
      resolve(conversation);
    })
  };
};
export var getContacts = function getContacts() {
  return {
    type: GET_USER_CONTACTS,
    payload: new Promise(function (resolve) {
      api.get("conversations/contacts").then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var searchConversation = function searchConversation(query) {
  return {
    type: SEARCH_CONVERSATIONS,
    payload: new Promise(function (resolve) {
      api.get("conversations/?search=".concat(query)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var clearSearch = function clearSearch() {
  return {
    type: CLEAR_SEARCH_CONVERSATIONS,
    payload: true
  };
};
export var resetConversation = function resetConversation() {
  return {
    type: RESET_CONVERSATION,
    payload: true
  };
};
export var archiveConversation = function archiveConversation(conversation) {
  return {
    type: ARCHIVE_CONVERSATION,
    payload: new Promise(function (resolve) {
      conversation.status = 'ARCHIVED';
      api.put("conversations/", conversation).then(function (response) {
        resolve(conversation);
      });
    })
  };
};
export var flagMessage = function flagMessage(id, reason) {
  return {
    type: FLAG_MESSAGE,
    payload: new Promise(function (resolve) {
      var values = {
        'messageId': id,
        'reason': reason
      };
      api.post("conversations/flagged", values).then(function (response) {
        resolve(_objectSpread({
          flagID: response.data
        }, values));
      });
    })
  };
};
export function getUserGuardian(id) {
  return {
    type: GET_USER_GUARDIAN,
    payload: new Promise(function (resolve, reject) {
      api.get("conversations/contacts/".concat(id, "/guardians")).then(function (response) {
        resolve({
          userId: id,
          guardians: response.data
        });
      });
    })
  };
}