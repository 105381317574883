import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { GET_COURSES, SET_ACTIVE_COURSE, GET_COURSE_DETAILS, GET_COURSE_BY_COURSECODE, CLEAR_COURSES_FROM_STORE, GET_STANDARD_COURSES } from "actions/courses";
import { SEARCH_COURSE, CLEAR_COURSE_SEARCH } from "actions/search";
import { updateObjectInArray } from "helpers/array";
var INITIAL_STATE = {
  courses: [],
  standardCourses: [],
  course: null,
  courseByCourseCode: null,
  courseSearchResults: []
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case GET_COURSES:
      return _objectSpread(_objectSpread({}, state), {}, {
        courses: action.payload
      });

    case GET_STANDARD_COURSES:
      return _objectSpread(_objectSpread({}, state), {}, {
        standardCourses: action.payload
      });

    case SEARCH_COURSE:
      var searchResult = action.payload;
      searchResult = searchResult.sort(function (a, b) {
        var course_one_title = a.title.toLowerCase();
        var course_two_title = b.title.toLowerCase();
        var course_one_typeOfSchooling = a.typeOfSchooling.toLowerCase();
        var course_two_typeOfSchooling = b.typeOfSchooling.toLowerCase();

        if (course_one_title > course_two_title) {
          return 1;
        } else if (course_one_title === course_two_title && course_one_typeOfSchooling > course_two_typeOfSchooling) {
          return 1;
        } else if (course_one_title === course_two_title && course_one_typeOfSchooling === course_two_typeOfSchooling) {
          return 0;
        } else if (course_one_title === course_two_title && course_one_typeOfSchooling < course_two_typeOfSchooling) {
          return -1;
        } else if (course_one_title < course_two_title) {
          return -1;
        }
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        courseSearchResults: searchResult
      });

    case CLEAR_COURSE_SEARCH:
      return _objectSpread(_objectSpread({}, state), {}, {
        courseSearchResults: []
      });

    case GET_COURSE_BY_COURSECODE:
      if (action.error) {
        return _objectSpread(_objectSpread({}, state), {}, {
          courseByCourseCode: null
        });
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        courseByCourseCode: action.payload
      });

    case SET_ACTIVE_COURSE:
      if (typeof action.id !== "undefined" && action.id !== null) {
        var selected = state.courses.find(function (course) {
          return parseInt(course.id) == parseInt(course.id);
        });

        if (selected !== undefined) {
          return _objectSpread(_objectSpread({}, state), {}, {
            course: selected
          });
        } else {
          return _objectSpread(_objectSpread({}, state), {}, {
            course: INITIAL_STATE.course
          });
        }
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        course: INITIAL_STATE.course
      });

    case GET_COURSE_DETAILS:
      return _objectSpread(_objectSpread({}, state), {}, {
        course: action.payload,
        courses: updateObjectInArray(state.courses, action.payload)
      });

    case CLEAR_COURSES_FROM_STORE:
      return _objectSpread(_objectSpread({}, state), {}, {
        courses: []
      });

    default:
      return state;
  }
}