export function objectsAreSame(x, y) {
  var objectsAreSame = true;

  for (var i = 0; x.length > i; i++) {
    if (typeof x[i] != 'undefined' && typeof y[i] != 'undefined') {
      for (var propertyName in x[i]) {
        if (x[i][propertyName] !== y[i][propertyName]) {
          objectsAreSame = false;
          break;
        }
      }
    } else {
      objectsAreSame = false;
      break;
    }
  }

  return objectsAreSame;
}
;
export function allEntriesEqual(array) {
  return new Set(array).size == 1;
}
;
export function updateObjectInArray(array, object) {
  var onlyReplace = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

  // Make some elementary checks before running any functions
  if (array != null && object != null) {
    // Make sure the object provided has an id property before doing anything
    if (object.id != null) {
      // Make a find on the array provided to determine if the object already exists
      var foundIndex = array.findIndex(function (arrayObject) {
        return arrayObject.id == object.id;
      }); // Check if index was found, we need to replace 'object' with existing array entry

      if (foundIndex > -1) {
        array.splice(foundIndex, 1, object);
      } else if (!onlyReplace) {
        // The item wasnt found, add it
        // Since most of our order is by when it was created we insert the object at first position
        array.unshift(object);
      }
    }
  } // Return array back (Should always be the case)


  return array;
}

Array.prototype.unique = function () {
  var arr = [];

  for (var i = 0; i < this.length; i++) {
    if (!arr.includes(this[i])) {
      arr.push(this[i]);
    }
  }

  return arr;
};

Array.prototype.clear = function () {
  for (var i = this.length; i > 0; i--) {
    this.pop();
  }
};