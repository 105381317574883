import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { GET_ASSESSMENT, GET_ASSESSMENTS, GET_ASSESSMENTS_COURSES, GET_STUDENT_ASSESSMENT_OVERVIEW, GET_MATRIX_ASSESSMENT_STATISTIC, RESET_MATRIX_ASSESSMENT_STATISTICS, GET_STUDENT_ASSESSMENTS, CLEAR_MATRIX_ASSESSMENT_OVERVIEW, RESET_USER_ASESSMENT_STATISTIC, GET_SECTIONS_FROM_ASSESSMENTS, GET_USER_ASSESSMENTS_STATISTIC_PROGRESS, GET_ASSESSMENTS_BY_STUDENT_AND_SECTION, GET_ASSESSMENTS_BY_STUDENT, CLEAR_ASSESSMENTS, GET_STUDENT_COURSES_GOALS, GET_STUDENT_ASSESSMENT_STATISTICS } from 'actions/assessments';
import { GET_STUDENT_ASSESSOR_ASSESSMENT, GET_STUDENT_ASSESSOR_ASSESSMENTS } from 'actions/studentassessments';
var INITIAL_STATE = {
  courses: [],
  assessments: [],
  assessment: null,
  studentAssessment: null,
  studentAssessments: [],
  studentStatistics: null,
  studentOverview: null,
  matrixAssessments: [],
  userAssessments: [],
  progressSections: [],
  progressAssessmentStats: []
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case GET_ASSESSMENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        assessment: action.payload
      });

    case GET_ASSESSMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        assessments: action.payload
      });

    case GET_ASSESSMENTS_COURSES:
      return _objectSpread(_objectSpread({}, state), {}, {
        courses: action.payload
      });

    case GET_STUDENT_ASSESSOR_ASSESSMENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        studentAssessment: action.payload
      });

    case GET_STUDENT_COURSES_GOALS:
      return _objectSpread(_objectSpread({}, state), {}, {
        studentOverview: action.payload
      });

    case GET_STUDENT_ASSESSMENT_STATISTICS:
      return _objectSpread(_objectSpread({}, state), {}, {
        studentStatistics: action.payload
      });

    case GET_STUDENT_ASSESSOR_ASSESSMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        studentAssessments: action.payload
      });

    case GET_SECTIONS_FROM_ASSESSMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        progressSections: action.payload
      });

    case GET_USER_ASSESSMENTS_STATISTIC_PROGRESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        progressAssessmentStats: action.payload
      });

    case CLEAR_MATRIX_ASSESSMENT_OVERVIEW:
      return _objectSpread(_objectSpread({}, state), {}, {
        matrixAssessments: INITIAL_STATE.matrixAssessments,
        assessments: []
      });

    case GET_MATRIX_ASSESSMENT_STATISTIC:
      return _objectSpread(_objectSpread({}, state), {}, {
        matrixAssessments: [].concat(_toConsumableArray(state.matrixAssessments), [action.payload])
      });

    case RESET_MATRIX_ASSESSMENT_STATISTICS:
      return _objectSpread(_objectSpread({}, state), {}, {
        matrixAssessments: []
      });

    case GET_STUDENT_ASSESSMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        studentAssessments: action.payload
      });

    case RESET_USER_ASESSMENT_STATISTIC:
      return _objectSpread(_objectSpread({}, state), {}, {
        userAssessments: INITIAL_STATE.userAssessments
      });

    case GET_ASSESSMENTS_BY_STUDENT_AND_SECTION:
      return _objectSpread(_objectSpread({}, state), {}, {
        assessments: action.payload
      });

    case GET_ASSESSMENTS_BY_STUDENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        assessments: action.payload
      });

    case CLEAR_ASSESSMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        assessments: []
      });

    default:
      return state;
  }
}