import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { GET_PRESCHOOL_DOCUMENTS, SET_ACTIVE_PRESCHOOL_DOCUMENT, UPDATE_PRESCHOOL_DOCUMENT, SUBMIT_PRESCHOOL_DOCUMENT, SET_ACTIVE_PRESCHOOL_DETAILS, GET_DOCUMENTS_PAGE, GET_SECTION_DOCUMENTS, CLEAR_DOCUMENT_READ_LIST, GET_PLAN_DOCUMENTS, GET_DOCUMENT_READ_LIST, DELETE_DOCUMENT_FROM_STORE } from 'actions/documents';
import { updateObjectInArray } from 'helpers/array';
var initialState = {
  planDocuments: [],
  documents: [],
  readList: null,
  activeDocument: {},
  createdDocument: {}
};
export default function Documents(state, action) {
  var type = action.type,
      value = action.value;

  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (type) {
    case SUBMIT_PRESCHOOL_DOCUMENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        createdDocument: value
      });

    case GET_PRESCHOOL_DOCUMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        documents: action.payload
      });

    case SET_ACTIVE_PRESCHOOL_DOCUMENT:
      if (typeof action.id !== 'undefined' && action.id !== null) {
        var selected = state.documents.find(function (doc) {
          return parseInt(doc.id) == parseInt(action.id);
        });

        if (selected !== undefined) {
          return _objectSpread(_objectSpread({}, state), {}, {
            activeDocument: selected
          });
        } else {
          return _objectSpread(_objectSpread({}, state), {}, {
            activeDocument: initialState.activeDocument
          });
        }
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        activeDocument: initialState.activeDocument
      });

    case 'get_preschool_document_image':
      if (state.activeDocument != initialState.activeDocument) {
        // If there is something in the activeDocument we will push the images to the state
        var current = state.activeDocument;
        var files = [];

        if (current.files != null) {
          files = current.files;
        }

        files.push(action.payload);
        current.files = files;
        return _objectSpread(_objectSpread({}, state), {}, {
          activeDocument: current
        });
      } else {
        // If not we just return the state once more
        return state;
      }

    case DELETE_DOCUMENT_FROM_STORE:
      var combinedItems = state.documents;
      combinedItems = combinedItems.filter(function (t) {
        return t.id != action.payload;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        documents: combinedItems
      });

    case GET_DOCUMENT_READ_LIST:
      return _objectSpread(_objectSpread({}, state), {}, {
        readList: action.payload
      });

    case CLEAR_DOCUMENT_READ_LIST:
      return _objectSpread(_objectSpread({}, state), {}, {
        readList: initialState.readList
      });

    case SET_ACTIVE_PRESCHOOL_DETAILS:
      if (action.payload != null) {
        var listdocuments = null;

        if (state.posts != null) {
          listdocuments = updateObjectInArray(state.documents, action.payload);
        } else {
          listdocuments = updateObjectInArray([], action.payload);
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          activeDocument: action.payload,
          documents: listdocuments
        });
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        activePost: initialState.activePost
      });

    case UPDATE_PRESCHOOL_DOCUMENT:
      action.payload.ReferenceType = 'document';
      var updateDocuments = updateObjectInArray(state.combined, action.payload);
      return _objectSpread(_objectSpread({}, state), {}, {
        documents: updateDocuments,
        activeDocument: action.payload
      });

    case GET_PLAN_DOCUMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        planDocuments: action.payload
      });

    case GET_SECTION_DOCUMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        sectionDocuments: action.payload
      });

    default:
      return state;
      break;
  }
}
;

function updateDocumentationInList(documentations, document) {
  var index = documentations.findIndex(function (t) {
    return t.id == document.id;
  });
  documentations.splice(index, 1);
  documentations.splice(index, 0, document);
  return documentations;
}