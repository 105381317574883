import api from 'lib/api';
export var SEARCH_PLANS = 'SEARCH_PLANS';
export var GET_PLANS = 'GET_PLANS';
export var ACTIVE_PLAN = 'ACTIVE_PLAN';
export var ACTIVE_PLAN_KNOWLEDGECOLLECTIONS = 'ACTIVE_PLAN_KNOWLEDGECOLLECTIONS';
export var GET_PLANS_BY_SECTIONID = 'GET_PLANS_BY_SECTIONID';
export var SET_ACTIVE_PLAN = 'SET_ACTIVE_PLAN';
export var PLAN_UPDATE = 'PLANNING_UPDATE';
export var CLEAR_PLANS_FROM_STORE = 'CLEAR_PLANS_FROM_STORE';
export var CLEAR_SECTION_PLANS = 'CLEAR_SECTION_PLANS';
export var PLAN_DELETE_PLAN = 'PLAN_DELETE_PLAN';
export var PLAN_DELETE_ASSIGNMENT_FROM_PLAN = 'PLAN_DELETE_ASSIGNMENT_FROM_PLAN';
export var CREATE_BULK_PLANS = 'CREATE_BULK_PLANS';
export var CREATE_PLAN = 'CREATE_PLAN';
export var GET_PLAN_STUDENT_ADDITIONAL_ADJUSTMENTS = 'PLANS_GET_STUDENT_ADDITIONAL_ADJUSTMENTS';
export var GET_PLAN_SECTION = 'PLANS_GET_PLAN_SECTION';
export var CLEAR_PLAN_SECTION = 'PLANS_CLEAR_PLAN_SECTION';
export var GET_BOOKMARKED_PLANS = 'GET_BOOKMARKED_PLANS';
export var getBookmarkedPlans = function getBookmarkedPlans() {
  return {
    type: GET_BOOKMARKED_PLANS,
    payload: new Promise(function (resolve, reject) {
      api.get('planning/bookmarked').then(function (response) {
        resolve(response.data);
      })["catch"](function (error) {
        reject(error);
      });
    })
  };
};
export var getPlans = function getPlans(start, end) {
  return {
    type: GET_PLANS,
    payload: new Promise(function (resolve) {
      api.get('planning').then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getPlanDetails = function getPlanDetails(id, callback) {
  return {
    type: ACTIVE_PLAN,
    payload: new Promise(function (resolve, reject) {
      api.get("planning/".concat(id, "?ver=2")).then(function (response) {
        resolve(response.data);

        if (callback != null) {
          callback(response.data);
        }
      })["catch"](function (error) {
        reject(error);
      });
    })
  };
};
export var createBulkPlans = function createBulkPlans(bulkPlans) {
  return {
    type: CREATE_BULK_PLANS,
    payload: new Promise(function (resolve) {
      api.post("planning/bulk?ver=2", bulkPlans).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var createPlan = function createPlan(plan) {
  return {
    type: CREATE_PLAN,
    payload: new Promise(function (resolve) {
      api.post("planning/?ver=2", plan).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getPlanKnowledgeCollections = function getPlanKnowledgeCollections(sectionId, planId, callback) {
  return {
    type: ACTIVE_PLAN_KNOWLEDGECOLLECTIONS,
    payload: new Promise(function (resolve) {
      api.get("knowledge/section/".concat(sectionId, "/plan/").concat(planId, "/collections")).then(function (response) {
        resolve(response.data);

        if (callback != null) {
          callback(response.data);
        }
      });
    })
  };
};
export var updatePlan = function updatePlan(values) {
  return {
    type: PLAN_UPDATE,
    payload: new Promise(function (resolve) {
      api.put("planning?ver=2", values).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getPlansBySectionId = function getPlansBySectionId(id, callback) {
  return {
    type: GET_PLANS_BY_SECTIONID,
    payload: new Promise(function (resolve) {
      api.get("planning/section/".concat(id)).then(function (response) {
        resolve(response.data);

        if (callback != null) {
          callback(response.data);
        }
      });
    })
  };
};
export var deletePlan = function deletePlan(id) {
  return {
    type: PLAN_DELETE_PLAN,
    payload: new Promise(function (resolve) {
      api["delete"]("planning/".concat(id)).then(function (response) {
        resolve(id);
      });
    })
  };
};
export var setActivePlan = function setActivePlan(id) {
  return {
    type: SET_ACTIVE_PLAN,
    payload: id
  };
};
export var clearPlansFromStore = function clearPlansFromStore(id) {
  return {
    type: CLEAR_PLANS_FROM_STORE,
    payload: []
  };
};
export var clearSectionPlansFromStore = function clearSectionPlansFromStore() {
  return {
    type: CLEAR_SECTION_PLANS,
    payload: []
  };
};
export var deleteAssignmentFromPlan = function deleteAssignmentFromPlan(planId, assignmentId) {
  return {
    type: PLAN_DELETE_ASSIGNMENT_FROM_PLAN,
    payload: new Promise(function (resolve) {
      api["delete"]("planning/".concat(planId, "/assignment/").concat(assignmentId)).then(function (response) {
        resolve({
          'planId': planId,
          'assignmentId': assignmentId
        });
      });
    })
  };
};
export var searchPlans = function searchPlans(value, group) {
  return {
    type: SEARCH_PLANS,
    payload: new Promise(function (resolve) {
      var url = "planning/search/".concat(value);

      if (group != null) {
        url += "?graphId=".concat(group);
      }

      api.get(url).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getStudentAdditionalAdjustmentsForPlan = function getStudentAdditionalAdjustmentsForPlan(planId) {
  return {
    type: GET_PLAN_STUDENT_ADDITIONAL_ADJUSTMENTS,
    payload: new Promise(function (resolve) {
      api.get("planning/".concat(planId, "/studentAdditionalAdjustments")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getPlanSection = function getPlanSection(id) {
  return {
    type: GET_PLAN_SECTION,
    payload: new Promise(function (resolve) {
      api.get("sections/".concat(id)).then(function (response) {
        var data = response.data;

        if (data && data.courses != null && data.courses.length > 0) {
          data.courses.forEach(function (element) {
            var sortedList = [];

            if (element.details != null && element.details.length > 0) {
              var activeDetailIndex = element.details.findIndex(function (element) {
                return element.endDate == null;
              });

              if (activeDetailIndex > -1) {
                var activeDetail = element.details[activeDetailIndex];
                element.details.splice(activeDetailIndex, 1);
                element.details.unshift(activeDetail);
              }
            }
          });
        }

        resolve(response.data);
      });
    })
  };
};
export var clearPlanSection = function clearPlanSection() {
  return {
    type: CLEAR_PLAN_SECTION,
    payload: null
  };
};