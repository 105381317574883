var store = require('../');

import { appInsights } from 'lib/appInsights';

var fetchSchool = function fetchSchool(activeSchool, currentUser) {
  var localStorageSchool = localStorage.getItem("hal.school" + currentUser.userId);
  var foundSchool = null;

  if (currentUser.schools != null && localStorageSchool != null) {
    foundSchool = currentUser.schools.find(function (school) {
      return school.id == localStorageSchool;
    });

    if (foundSchool == null) {
      var properties = {
        'localStorageSchool': localStorageSchool,
        'url': window.location.pathname
      };
      appInsights.trackTrace({
        message: 'Wrong school in localstorage',
        severityLevel: 2
      }, properties);
    }
  }

  if (localStorageSchool == null) {
    localStorage.setItem("hal.school" + currentUser.userId, currentUser.schools[0].id);
    var newAction = {
      type: "SET_SCHOOL",
      payload: currentUser.schools[0].id
    };
    store["default"].dispatch(newAction);
  } else {
    if (foundSchool != null) {
      var _newAction = {
        type: "SET_SCHOOL",
        payload: localStorageSchool
      };
      store["default"].dispatch(_newAction);
    } else {
      if (window.location.pathname == '/') {
        localStorage.setItem("hal.school" + currentUser.userId, currentUser.schools[0].id);
        var _newAction2 = {
          type: "SET_SCHOOL",
          payload: currentUser.schools[0].id
        };
        store["default"].dispatch(_newAction2);
      }
    }
  }
};

var setLocalStorage = function setLocalStorage(currentUser) {
  var localStorageSchool = localStorage.getItem("hal.school" + currentUser.userId);

  if (localStorageSchool == null) {
    localStorage.setItem("hal.school" + currentUser.userId, currentUser.schools[0].id);
  }
};

var setSchoolOnUser = function setSchoolOnUser(currentUser, schoolId) {
  localStorage.setItem("hal.school" + currentUser.userId, schoolId);
};

var getSchoolForUser = function getSchoolForUser(currentUser) {
  return localStorage.getItem("hal.school" + currentUser.userId);
};

export default {
  fetchSchool: fetchSchool,
  setLocalStorage: setLocalStorage,
  setSchoolOnUser: setSchoolOnUser,
  getSchoolForUser: getSchoolForUser
};