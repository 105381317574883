import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { isSupported, setMenuItems, getMenuItems } from 'helpers/localstorage';
import { GET_MENU_ITEMS } from 'actions/menu';
var rehydrate_items = null;

if (isSupported(function () {
  return localStorage;
})) {
  rehydrate_items = getMenuItems();
}

var INITIAL_STATE = {
  items: rehydrate_items
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case GET_MENU_ITEMS:
      if (action.payload != null) {
        setMenuItems(action.payload);
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        items: action.payload
      });

    default:
      return state;
  }
}