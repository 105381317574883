import api from 'lib/api';
export var GET_SCHOOL_TYPES = 'GET_SCHOOL_TYPES';
export var getSchoolTypes = function getSchoolTypes() {
  return {
    type: GET_SCHOOL_TYPES,
    payload: new Promise(function (resolve) {
      api.get("tenants/schooltypes").then(function (response) {
        resolve(response.data);
      });
    })
  };
};