import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { GET_USER_VERDICTS, GET_PLAN_ASSESSMENTS, GET_ASSIGNMENT_ASSESSMENTS, GET_SECTION_VERDICTS, GET_STUDY_PLAN, GET_STUDENT_LAST_VERDICT, GET_VERDICT_SCALE, CLEAR_VERDICTS, CLEAR_VERDCIT_SCALE, GET_VERDICTS_FOR_STUDENTS_IN_SECTION, RESET_ASSIGNMENT_ASSESSMENTS, RESET_PLAN_ASSESSMENTS } from 'actions/verdicts';
var INITIAL_STATE = {
  scale: null,
  user: null,
  assignments: null,
  plans: null,
  section: null,
  userStudyPlans: null,
  lastVerdicts: [],
  sectionStudents: null
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case GET_VERDICT_SCALE:
      return _objectSpread(_objectSpread({}, state), {}, {
        scale: action.payload
      });

    case GET_USER_VERDICTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        user: action.payload
      });

    case RESET_ASSIGNMENT_ASSESSMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        plans: null
      });

    case RESET_PLAN_ASSESSMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        assignments: null
      });

    case GET_PLAN_ASSESSMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        plans: action.payload
      });

    case GET_ASSIGNMENT_ASSESSMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        assignments: action.payload
      });

    case GET_SECTION_VERDICTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        section: action.payload
      });

    case GET_STUDY_PLAN:
      return _objectSpread(_objectSpread({}, state), {}, {
        userStudyPlans: action.payload
      });

    case GET_STUDENT_LAST_VERDICT:
      if (action.payload != null && action.payload.length > 0) {
        var lastVerdicts = _toConsumableArray(state.lastVerdicts);

        lastVerdicts.push(action.payload[0]);
        return _objectSpread(_objectSpread({}, state), {}, {
          lastVerdicts: lastVerdicts
        });
      }

      return state;

    case CLEAR_VERDICTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        lastVerdicts: [],
        assignments: null,
        plans: null,
        user: null,
        userStudyPlans: null
      });

    case CLEAR_VERDCIT_SCALE:
      return _objectSpread(_objectSpread({}, state), {}, {
        scale: null
      });

    case GET_VERDICTS_FOR_STUDENTS_IN_SECTION:
      return _objectSpread(_objectSpread({}, state), {}, {
        sectionStudents: action.payload
      });

    default:
      return state;
  }
}