import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { GET_SERVICES, SET_SELECTED_SERVICES, GET_CONSENT_INFO, GET_CONSENT_PRESENT, POST_INVITE_ADMIN, GET_ADMIN_INVITATIONS, SET_EMAIL_SENT_LIST, GET_TENANT_PRODUCTS } from 'actions/services';
import { GET_SCHOOL_SERVICES } from 'actions/schools';
import { GET_TENANT } from 'actions/user';
import ServiceManager from '_class/ServiceManager';
var INITIAL_STATE = {
  availableProducts: null,
  selectedServices: [],
  tenantProducts: [],
  consentInfo: null,
  inviteAdminStatus: null,
  adminInvitations: [],
  emailSentList: [],
  isAppConsented: false,
  tenantServices: null,
  schoolServices: null,
  availableServices: null
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case GET_SERVICES:
      return _objectSpread(_objectSpread({}, state), {}, {
        availableProducts: action.payload
      });

    case SET_SELECTED_SERVICES:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedServices: action.payload
      });

    case GET_CONSENT_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        consentInfo: action.payload
      });

    case POST_INVITE_ADMIN:
      return _objectSpread(_objectSpread({}, state), {}, {
        inviteAdminStatus: action.payload
      });

    case GET_ADMIN_INVITATIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        adminInvitations: action.payload
      });

    case GET_CONSENT_PRESENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        isAppConsented: action.payload
      });

    case SET_EMAIL_SENT_LIST:
      return _objectSpread(_objectSpread({}, state), {}, {
        emailSentList: action.payload
      });

    case GET_TENANT_PRODUCTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        tenantProducts: action.payload
      });

    case GET_TENANT:
      return _objectSpread(_objectSpread({}, state), {}, {
        tenantServices: action.payload !== null ? action.payload.tenantServices : null,
        availableServices: new ServiceManager(action.payload !== null ? action.payload.tenantServices : null, state.schoolServices).getServices()
      });

    case GET_SCHOOL_SERVICES:
      return _objectSpread(_objectSpread({}, state), {}, {
        schoolServices: action.payload,
        availableServices: new ServiceManager(state.tenantServices, action.payload).getServices()
      });

    default:
      return state;
  }
}
;