import api from 'lib/api';
export var TOGGLE_ADVANCED_CALENDAR = 'TOGGLE_ADVANCED_CALENDAR'; //activity filtering

export var CLEAR_FILTER = 'CLEAR_FILTER';
export var UPDATE_FILTER = 'UPDATE_FILTER';
export var TOGGLE_MY_SCHEDULE = 'TOGGLE_MY_SCHEDULE';
export var SET_TOTAL_AMOUNT = 'SET_TOTAL_AMOUNT'; //Group filtering

export var SET_ALL_GROUPS = 'SET_ALL_GROUPS';
export var SET_ALL_SEARCHED = 'SET_ALL_SEARCHED';
export var TOGGLE_ALL_USERS = 'TOGGLE_ALL_USERS';
export var SET_CUSTOM_GROUP = 'SET_CUSTOM_GROUP';
export var SET_SEARCH_GROUP = 'SET_SEARCH_GROUP';
export var REMOVE_SEARCHED_GROUP = 'REMOVE_SEARCHED_GROUP';
export var ADD_SECTION = 'ADD_SECTION';
export var ADD_SECTION_SEARCH = 'ADD_SECTION_SEARCH';
export var CHANGE_SELECTED_PEOPLE = 'CHANGE_SELECTED_PEOPLE';
export var CHANGE_SEARCHED_PEOPLE = 'CHANGE_SEARCHED_PEOPLE';
export var CHANGE_SEARCH_OWNER = 'CHANGE_SEARCH_OWNER';
export var CHANGE_SELECTED_OWNER = 'CHANGE_SELECTED_OWNER';
export var SET_TOTAL_GROUPS = 'SET_TOTAL_GROUPS';
export var REMOVE_SELECTED_PEOPLE = 'REMOVE_SELECTED_PEOPLE';
export var REMOVE_SELECTED_OWNER = 'REMOVE_SELECTED_OWNER';
export var REMOVE_SEARCHED_PEOPLE = 'REMOVE_SEARCHED_PEOPLE';
export var REMOVE_SEARCHED_OWNER = 'REMOVE_SEARCHED_OWNER';
export var CLEAR_ALL = 'CLEAR_ALL';
export var SELECT_ALL_USER = 'SELECT_ALL_USER';
export var REMOVE_ALL_USER = 'REMOVE_ALL_USER';
export var REMOVE_ALL = 'REMOVE_ALL';
export var clearScheduleFilter = function clearScheduleFilter(data) {
  return {
    type: CLEAR_FILTER,
    payload: {
      filterData: data
    }
  };
};
export var toggleAdvanceCalendar = function toggleAdvanceCalendar() {
  return {
    type: TOGGLE_ADVANCED_CALENDAR
  };
};
export var UpdateFilter = function UpdateFilter(data, obj) {
  return {
    type: UPDATE_FILTER,
    payload: {
      allData: data,
      obj: obj
    }
  };
};
export var ClearAllFilter = function ClearAllFilter() {
  return {
    type: CLEAR_ALL
  };
};
export var toggleMySchedule = function toggleMySchedule() {
  return {
    type: TOGGLE_MY_SCHEDULE
  };
};
export var updateTotalUsers = function updateTotalUsers(total) {
  return {
    type: SET_TOTAL_AMOUNT,
    payload: {
      countTotal: total
    }
  };
};
export var setAllGroups = function setAllGroups(sections, count, groups, owners, checkedObj) {
  return {
    type: SET_ALL_GROUPS,
    payload: {
      data: sections,
      total: count,
      totalGroups: groups,
      totalOwners: owners,
      groupsChecked: checkedObj
    }
  };
};
export var setAllSearched = function setAllSearched(searchedArr, checkedObj) {
  return {
    type: SET_ALL_SEARCHED,
    payload: {
      data: searchedArr,
      groupsChecked: checkedObj
    }
  };
};
export var toggleAllUsers = function toggleAllUsers() {
  return {
    type: TOGGLE_ALL_USERS
  };
};
export var selectCustomGroup = function selectCustomGroup(data) {
  return {
    type: SET_CUSTOM_GROUP,
    payload: {
      group: data
    }
  };
};
export var selectSearchGroup = function selectSearchGroup(data) {
  return {
    type: SET_SEARCH_GROUP,
    payload: {
      group: data
    }
  };
};
export var removeCustomGroup = function removeCustomGroup(group, fullObj) {
  return {
    type: REMOVE_SEARCHED_GROUP,
    payload: {
      group: group,
      obj: fullObj
    }
  };
};
export var getSections = function getSections(id, isCheckbox, userId, isSearch, callback) {
  return {
    type: ADD_SECTION,
    payload: new Promise(function (resolve, reject) {
      api.get("sections/".concat(id, "?expand=owners;members")).then(function (response) {
        if (response.data.owners === null) {
          response.data.owners = [];
        }

        resolve({
          data: response.data,
          checkBox: isCheckbox,
          id: id,
          currentUser: userId,
          search: isSearch
        });

        if (callback != null) {
          callback(response.data);
        }
      })["catch"](function (e) {
        reject(e);
      });
    })
  };
};
export var addAll = function addAll(id) {
  return new Promise(function (resolve, reject) {
    api.get("sections/".concat(id, "?expand=owners;members")).then(function (response) {
      resolve({
        data: response.data
      });
    })["catch"](function (e) {
      reject(e);
    });
  });
};
export var getSectionSearch = function getSectionSearch(id, isCheckbox, callback) {
  return {
    type: ADD_SECTION_SEARCH,
    payload: new Promise(function (resolve, reject) {
      api.get("sections/".concat(id, "?expand=owners;members")).then(function (response) {
        if (response.data.owners === null) {
          response.data.owners = [];
        }

        resolve({
          data: response.data,
          checkBox: isCheckbox,
          id: id
        });

        if (callback != null) {
          callback(response.data);
        }
      })["catch"](function (e) {
        reject(e);
      });
    })
  };
};
export var changeSelected = function changeSelected(data) {
  return {
    type: CHANGE_SELECTED_PEOPLE,
    payload: {
      selectedUser: data
    }
  };
};
export var changeSelectedOwner = function changeSelectedOwner(data) {
  return {
    type: CHANGE_SELECTED_OWNER,
    payload: {
      selectedUser: data
    }
  };
};
export var removeSelectedPeople = function removeSelectedPeople(data) {
  return {
    type: REMOVE_SELECTED_PEOPLE,
    payload: {
      selectedUser: data
    }
  };
};
export var removeSelectedOwner = function removeSelectedOwner(data) {
  return {
    type: REMOVE_SELECTED_OWNER,
    payload: {
      selectedUser: data
    }
  };
};
export var setAllActive = function setAllActive(id, ownerArr, findArr, searched, count) {
  return {
    type: SELECT_ALL_USER,
    payload: {
      id: id,
      owners: ownerArr,
      newArr: findArr,
      isSearched: searched,
      countObject: count
    }
  };
};
export var removeAllUser = function removeAllUser(id, obj, ownerArr, afterRemoved) {
  var searchedData = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  return {
    type: REMOVE_ALL_USER,
    payload: {
      id: id,
      data: obj,
      owners: ownerArr,
      removed: afterRemoved,
      isSearchedData: searchedData
    }
  };
};
export var removeAll = function removeAll() {
  return {
    type: REMOVE_ALL
  };
};