import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { GET_PLANS, ACTIVE_PLAN, GET_PLANS_BY_SECTIONID, SET_ACTIVE_PLAN, CLEAR_PLANS_FROM_STORE, PLAN_UPDATE, PLAN_DELETE_PLAN, PLAN_DELETE_ASSIGNMENT_FROM_PLAN, SEARCH_PLANS, CLEAR_SECTION_PLANS, CREATE_PLAN, CREATE_BULK_PLANS, GET_PLAN_SECTION, CLEAR_PLAN_SECTION, GET_BOOKMARKED_PLANS } from 'actions/plans';
import { ACTIVE_PLAN_KNOWLEDGECOLLECTIONS } from 'actions/knowledge';
import { updateObjectInArray } from 'helpers/array';
var INITIAL_STATE = {
  plans: null,
  active_plan: {},
  active_knowledgeCollection: [],
  sectionPlans: [],
  search_results: null,
  createdPlanId: null,
  createdPlans: null,
  section: null,
  version: '1',
  bookmarkedPlans: {
    data: [],
    error: null,
    requestResponseCode: null
  }
};
export default function () {
  var _action$payload, _action$payload2, _action$payload2$resp;

  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case ACTIVE_PLAN:
      var updateList = updateObjectInArray(state.plans, action.payload);
      return _objectSpread(_objectSpread({}, state), {}, {
        active_plan: action.payload,
        plans: updateList
      });

    case ACTIVE_PLAN_KNOWLEDGECOLLECTIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        active_knowledgeCollection: action.payload
      });

    case CREATE_PLAN:
      return _objectSpread(_objectSpread({}, state), {}, {
        createdPlanId: action.payload
      });

    case GET_PLANS:
      return _objectSpread(_objectSpread({}, state), {}, {
        plans: action.payload
      });

    case SEARCH_PLANS:
      return _objectSpread(_objectSpread({}, state), {}, {
        search_results: action.payload
      });

    case GET_BOOKMARKED_PLANS:
      var existingObject = state.bookmarkedPlans;
      existingObject.data = action.error ? [] : action.payload;
      existingObject.error = action.error ? (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.response : null;
      existingObject.requestResponseCode = action.error ? (_action$payload2 = action.payload) === null || _action$payload2 === void 0 ? void 0 : (_action$payload2$resp = _action$payload2.response) === null || _action$payload2$resp === void 0 ? void 0 : _action$payload2$resp.status : 200;
      return _objectSpread(_objectSpread({}, state), {}, {
        bookmarkedPlans: existingObject
      });

    case GET_PLANS_BY_SECTIONID:
      return _objectSpread(_objectSpread({}, state), {}, {
        sectionPlans: action.payload
      });

    case SET_ACTIVE_PLAN:
      if (action.id != null) {
        var selected = state.plans.find(function (plan) {
          return plan.id == action.id;
        });

        if (typeof selected != 'undefined') {
          return _objectSpread(_objectSpread({}, state), {}, {
            active_plan: selected
          });
        } else {
          return _objectSpread(_objectSpread({}, state), {}, {
            active_plan: INITIAL_STATE.active_plan
          });
        }
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        active_plan: INITIAL_STATE.active_plan
      });

    case PLAN_UPDATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        active_plan: action.payload
      });

    case CLEAR_PLANS_FROM_STORE:
      return _objectSpread(_objectSpread({}, state), {}, {
        plans: INITIAL_STATE.plans,
        active_plan: INITIAL_STATE.active_plan
      });

    case CLEAR_SECTION_PLANS:
      return _objectSpread(_objectSpread({}, state), {}, {
        sectionPlans: INITIAL_STATE.sectionPlans
      });

    case PLAN_DELETE_PLAN:
      if (action.payload != null) {
        var _currentPlans = state.plans;

        if (_currentPlans != null && _currentPlans.length > 0) {
          // Try to find index of the now deleted plan
          var foundIndex = _currentPlans.findIndex(function (plan) {
            return plan.id == action.payload;
          });

          if (foundIndex > -1) {
            // Remove the index from list
            _currentPlans.splice(foundIndex, 1);

            return _objectSpread(_objectSpread({}, state), {}, {
              plans: _currentPlans
            });
          }
        } else {
          return state;
        }
      }

      return state;

    case PLAN_DELETE_ASSIGNMENT_FROM_PLAN:
      return state;

    case CREATE_BULK_PLANS:
      if (action.payload != null) {
        var currentPlans = state.plans;
        if (currentPlans == null) currentPlans = [];
        return _objectSpread(_objectSpread({}, state), {}, {
          plans: [].concat(_toConsumableArray(currentPlans), _toConsumableArray(action.payload)),
          createdPlans: action.payload
        });
      }

      return state;

    case GET_PLAN_SECTION:
      return _objectSpread(_objectSpread({}, state), {}, {
        section: action.payload
      });

    case CLEAR_PLAN_SECTION:
      return _objectSpread(_objectSpread({}, state), {}, {
        section: null
      });

    default:
      return state;
  }
}