import api from 'lib/api';
export var GET_MENU_ITEMS = 'MENU_GET_MENU_ITEMS';
export var getMenuItems = function getMenuItems() {
  return {
    type: GET_MENU_ITEMS,
    payload: new Promise(function (resolve) {
      api.get("navigation").then(function (response) {
        resolve(response.data);
      });
    })
  };
};