export var getAttendanceColor = function getAttendanceColor(attendance) {
  var color = '#fff';

  if (attendance != null) {
    if (attendance.type == 'PRESENT') {
      color = '#19db6c';
    }

    if (attendance.type.indexOf('VALID') > -1) {
      color = '#efc24c';
    }

    if (attendance.type.indexOf('INVALID') > -1) {
      color = '#f25252';
    }

    if (attendance.type == 'NOT_REPORTED') {
      color = '#dbdbdb';
    }
  }

  return color;
};
export var getAttendanceType = function getAttendanceType(attendance) {
  var content = '';

  if (attendance != null) {
    if (attendance.type == 'PRESENT') {
      content = 'närvarande';
    }

    if (attendance.type == 'INVALID_ABSENCE') {
      content = 'ogiltigt frånvarande ';
    }

    if (attendance.type == 'VALID_ABSENCE') {
      content = 'giltigt frånvarande';
    }

    if (attendance.type == 'INVALID_LATE') {
      content = 'ogiltig sen ankomst';
    }

    if (attendance.type == 'VALID_LATE') {
      content = 'giltig sen ankomst';
    }
  }

  return content;
};