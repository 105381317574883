import _typeof from "@babel/runtime/helpers/typeof";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import Moment from 'moment';
import { SUBMIT_POST, UPDATE_POST, GET_POSTS_NEXT_PAGE, SET_ACTIVE_POST_DETAILS, GET_SECTION_POSTS, DELETE_POST_FROM_STORE, CLEAR_POSTS_FROM_STORE, CLEAR_SECTION_POSTS_FROM_STORE, DELETE_POST } from 'actions/posts';
import { SUBMIT_PRESCHOOL_DOCUMENT, SET_ACTIVE_PRESCHOOL_DETAILS, UPDATE_PRESCHOOL_DOCUMENT, GET_PRESCHOOL_DOCUMENTS, GET_DOCUMENTS_PAGE, GET_SECTION_DOCUMENTS, CLEAR_DOCUMENTS_FROM_STORE, CLEAR_SECTION_DOCUMENTS_FROM_STORE, DELETE_DOCUMENT_FROM_STORE, DELETE_DOCUMENT } from 'actions/documents';
import { updateObjectInArray } from 'helpers/array';
var initialState = {
  combined: [],
  sectionCombined: [],
  posts: [],
  documentations: [],
  sectionDocumentations: [],
  sectionPosts: []
};
export default function CombinedEvents(state, action) {
  var type = action.type,
      value = action.value;

  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (type) {
    case GET_POSTS_NEXT_PAGE:
      var currentPostCombined = state.combined;
      action.payload.map(function (d) {
        var checkExisting = currentPostCombined.find(function (cb) {
          return cb.id == d.id && cb.ReferenceType == 'post';
        });

        if (typeof checkExisting == 'undefined') {
          currentPostCombined.push(_objectSpread({
            "ReferenceType": "post"
          }, d));
        }
      });

      if (currentPostCombined.length > 0) {
        currentPostCombined.sort(function (a, b) {
          return Moment(b.published).unix() - Moment(a.published).unix();
        });
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        combined: currentPostCombined,
        posts: action.payload
      });

    case GET_SECTION_POSTS:
      var currentSectionCombinedPosts = state.sectionCombined;

      if (action.payload != null && _typeof(action.payload) == 'object') {
        action.payload.map(function (d) {
          var checkExisting = currentSectionCombinedPosts.find(function (cb) {
            return cb.id == d.id && cb.ReferenceType == 'post';
          });

          if (checkExisting == null) {
            currentSectionCombinedPosts.push(_objectSpread({
              "ReferenceType": "post"
            }, d));
          }
        });

        if (currentSectionCombinedPosts.length > 0) {
          currentSectionCombinedPosts.sort(function (a, b) {
            return Moment(b.published).unix() - Moment(a.published).unix();
          });
        }
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        sectionCombined: currentSectionCombinedPosts,
        sectionPosts: action.payload
      });

    case SUBMIT_POST:
      action.payload.ReferenceType = 'post';
      var addPosts = null;

      if (state.combined != null) {
        addPosts = updateObjectInArray(state.combined, action.payload);
      } else {
        addPosts = updateObjectInArray([], action.payload);
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        combined: addPosts
      });

    case UPDATE_POST:
      action.payload.ReferenceType = 'post';
      var updatePosts = updateObjectInArray(state.combined, action.payload);
      return _objectSpread(_objectSpread({}, state), {}, {
        combined: updatePosts
      });

    case SET_ACTIVE_POST_DETAILS:
      if (action.payload != null && !action.error) {
        action.payload.ReferenceType = 'post';
        var listposts = null;

        if (state.combined != null) {
          listposts = updateObjectInArray(state.combined, action.payload);
        } else {
          listposts = updateObjectInArray([], action.payload);
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          combined: listposts
        });
      }

      return state;

    case SUBMIT_PRESCHOOL_DOCUMENT:
      action.payload.ReferenceType = 'document';
      var addDocuments = null;

      if (state.combined != null) {
        addDocuments = updateObjectInArray(state.combined, action.payload);
      } else {
        addDocuments = updateObjectInArray([], action.payload);
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        combined: addDocuments
      });

    case CLEAR_SECTION_POSTS_FROM_STORE:
      // Add ReferenceType==Post here
      return _objectSpread(_objectSpread({}, state), {}, {
        sectionCombined: []
      });

    case DELETE_POST_FROM_STORE:
      var currentPostsToDelete = state.combined; // Add ReferenceType==Post here

      currentPostsToDelete = currentPostsToDelete.filter(function (t) {
        return t.id != action.payload;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        combined: currentPostsToDelete
      });

    case CLEAR_POSTS_FROM_STORE:
      // Add ReferenceType==Post here
      return _objectSpread(_objectSpread({}, state), {}, {
        combined: [],
        sectionCombined: []
      });

    case GET_DOCUMENTS_PAGE:
      var currentDocumentsCombined = state.combined;
      action.payload.map(function (d) {
        // Add ReferenceType==Document here
        var checkExisting = currentDocumentsCombined.find(function (cb) {
          return cb.id == d.id && cb.ReferenceType == 'document';
        });

        if (typeof checkExisting == 'undefined') {
          currentDocumentsCombined.push(_objectSpread({
            "ReferenceType": "document"
          }, d));
        }
      });

      if (currentDocumentsCombined.length > 0) {
        currentDocumentsCombined.sort(function (a, b) {
          return Moment(b.published).unix() - Moment(a.published).unix();
        });
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        combined: currentDocumentsCombined,
        documentations: action.payload
      });

    case SET_ACTIVE_PRESCHOOL_DETAILS:
      if (action.payload != null) {
        action.payload.ReferenceType = 'document';
        var listdocuments = null;

        if (state.combined != null) {
          listdocuments = updateObjectInArray(state.combined, action.payload);
        } else {
          listdocuments = updateObjectInArray([], action.payload);
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          combined: listdocuments
        });
      }

      return state;

    case DELETE_DOCUMENT_FROM_STORE:
      // Add ReferenceType==Document here
      var currentDocumentsToDelete = state.combined.filter(function (t) {
        return t.id != action.payload;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        documents: currentDocumentsToDelete
      });

    case GET_SECTION_DOCUMENTS:
      var currentSectionCombinedDocuments = state.sectionCombined;
      action.payload.map(function (d) {
        var checkExisting = currentSectionCombinedDocuments.find(function (cb) {
          return cb.id == d.id && cb.ReferenceType == 'document';
        });

        if (typeof checkExisting == 'undefined') {
          currentSectionCombinedDocuments.push(_objectSpread({
            "ReferenceType": "document"
          }, d));
        }
      });

      if (currentSectionCombinedDocuments.length > 0) {
        currentSectionCombinedDocuments.sort(function (a, b) {
          return Moment(b.published).unix() - Moment(a.published).unix();
        });
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        sectionCombined: currentSectionCombinedDocuments,
        sectionDocumentations: action.payload
      });

    case CLEAR_DOCUMENTS_FROM_STORE:
      // Add ReferenceType==Document here
      return _objectSpread(_objectSpread({}, state), {}, {
        combined: [],
        sectionCombined: []
      });

    case CLEAR_SECTION_DOCUMENTS_FROM_STORE:
      // Add ReferenceType==Document here
      return _objectSpread(_objectSpread({}, state), {}, {
        sectionCombined: []
      });

    case DELETE_POST:
      var newCombinedItems = state.combined.filter(function (t) {
        if (action.payload == t.id && t.ReferenceType == 'post') {
          return false;
        }

        return true;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        combined: newCombinedItems
      });

    case DELETE_DOCUMENT:
      var newCombinedDocuments = state.combined.filter(function (t) {
        if (action.payload == t.id && t.ReferenceType == 'document') {
          return false;
        }

        return true;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        combined: newCombinedDocuments
      });

    default:
      return state;
      break;
  }
}