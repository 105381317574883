import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { GET_POSTS, SUBMIT_POST, UPDATE_POST, SET_ACTIVE_POST, SET_ACTIVE_POST_DETAILS, GET_POSTS_NEXT_PAGE, GET_SECTION_POSTS, DELETE_POST_FROM_STORE, CLEAR_POSTS_FROM_STORE, CLEAR_SECTION_POSTS_FROM_STORE, GET_POST_READ_LIST, CLEAR_POST_READ_LIST, SET_PAGINATION_INFO } from 'actions/posts';
var initialState = {
  posts: [],
  activePost: null,
  createdPost: {},
  sectionPosts: [],
  readList: null,
  collectionInformation: []
};
import { updateObjectInArray } from 'helpers/array';
export default function Posts(state, action) {
  var type = action.type;

  if (typeof state === 'undefined') {
    return initialState;
  }

  var combinedItems = state.posts;

  switch (type) {
    case GET_POSTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        posts: action.payload
      });

    case SUBMIT_POST:
      var addPosts = null;

      if (state.posts != null) {
        addPosts = updateObjectInArray(state.posts, action.payload);
      } else {
        addPosts = updateObjectInArray([], action.payload);
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        createdPost: action.payload,
        posts: addPosts
      });

    case SET_ACTIVE_POST:
      if (action.id != null) {
        var selected = state.posts.find(function (post) {
          return parseInt(post.id) == parseInt(action.id);
        });

        if (typeof selected != 'undefined') {
          return _objectSpread(_objectSpread({}, state), {}, {
            activePost: selected
          });
        } else {
          return _objectSpread(_objectSpread({}, state), {}, {
            activePost: null
          });
        }
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        activePost: null
      });

    case SET_ACTIVE_POST_DETAILS:
      if (action.payload != null && !action.error) {
        var listposts = null;

        if (state.posts != null) {
          listposts = updateObjectInArray(state.posts, action.payload);
        } else {
          listposts = updateObjectInArray([], action.payload);
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          activePost: action.payload,
          posts: listposts
        });
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        activePost: initialState.activePost
      });

    case UPDATE_POST:
      var updatePosts = updateObjectInArray(state.posts, action.payload);
      return _objectSpread(_objectSpread({}, state), {}, {
        activePost: action.payload,
        posts: updatePosts
      });

    case GET_POSTS_NEXT_PAGE:
      var combined = state.posts;
      action.payload.map(function (d) {
        var checkExisting = combined.find(function (cb) {
          return cb.id == d.id;
        });

        if (typeof checkExisting == 'undefined') {
          combined.push(d);
        }
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        posts: combined
      });

    case GET_SECTION_POSTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        sectionPosts: action.payload
      });

    case SET_PAGINATION_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        collectionInformation: action.payload
      });

    case CLEAR_SECTION_POSTS_FROM_STORE:
      return _objectSpread(_objectSpread({}, state), {}, {
        sectionPosts: []
      });

    case DELETE_POST_FROM_STORE:
      combinedItems = combinedItems.filter(function (t) {
        return t.id != action.payload;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        posts: combinedItems
      });

    case GET_POST_READ_LIST:
      return _objectSpread(_objectSpread({}, state), {}, {
        readList: action.payload
      });

    case CLEAR_POSTS_FROM_STORE:
      return _objectSpread(_objectSpread({}, state), {}, {
        posts: []
      });

    case CLEAR_POST_READ_LIST:
      return _objectSpread(_objectSpread({}, state), {}, {
        readList: null
      });

    default:
      return state;
      break;
  }
}

function updatePostInList(posts, post) {
  var index = posts.findIndex(function (t) {
    return t.id == post.id;
  });
  posts.splice(index, 1);
  posts.splice(index, 0, post);
  return posts;
}