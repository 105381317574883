import { getRootUrl } from 'helpers/url';
export var isIframe = function isIframe() {
  try {
    return window.self !== window.top || getRootUrl().indexOf('-tab') > -1;
  } catch (e) {
    return true;
  }
};
export var backButtonEnable = function backButtonEnable() {
  var isIframe = window.self !== window.top;
  var current = window.location.pathname;

  if (current[current.length - 1] == '/') {
    // Remove trailing slash
    current = current.slice(0, -1);
  } // Remove trailing slash on getRootUrl since it always contains one


  if (current == getRootUrl().slice(0, -1)) {
    return false;
  } else {
    if (current == '/' && isIframe) {
      return false;
    }

    if (current == '/menu') {
      return false;
    }

    if (current == '/search') {
      return false;
    }

    if (current == '/assignments') {
      return false;
    }

    if (current == '/evaluations') {
      return false;
    }

    if (current == '/posts') {
      return false;
    }

    if (current == '/plans') {
      return false;
    }

    if (current == '/groups') {
      return false;
    }

    if (current == '/schedule') {
      return false;
    }

    if (current == '/notifications') {
      return false;
    }

    if (current == '/conversations') {
      return false;
    }

    if (current == '/report/absence') {
      return false;
    }

    if (current == '/report/attendance') {
      return false;
    }

    if (current == '/courses') {
      return false;
    }

    if (current == '/courses/standard') {
      return false;
    }

    if (current == '/conversations/admin/flagged') {
      return false;
    }

    if (current == '/forms/templates') {
      return false;
    }

    if (current == '/meetings') {
      return false;
    }

    if (current == '/progress') {
      return false;
    }

    if (current == '/additionaladjustments') {
      return false;
    }

    if (current == '/overview/mentor') {
      return false;
    }

    if (current == '/bookmarks') {
      return false;
    }

    if (current == '/administration') {
      return false;
    }

    if (current == '/sms') {
      return false;
    }

    return true;
  }
};
export var generateMicrosoftAssignmentDeepLink = function generateMicrosoftAssignmentDeepLink(assignment, group, channel) {
  var base = 'https://teams.microsoft.com/l/entity';
  var entityId = '66aeee93-507d-479a-a3ef-8f494af43945/classroom';
  var context = "{\"subEntityId\":\"{\\\"version\\\":\\\"1.0\\\",\\\"config\\\":{\\\"classes\\\":[{\\\"id\\\":\\\"".concat(group, "\\\",\\\"assignmentIds\\\":[\\\"").concat(assignment, "\\\"]}]},\\\"action\\\":\\\"navigate\\\",\\\"view\\\":\\\"assignment-viewer\\\"}\",\"channelId\":\"").concat(channel, "\"}");
  context = encodeURIComponent(context);
  return "".concat(base, "/").concat(entityId, "?context=").concat(context);
};