import api from 'lib/api';
export var GET_MEETING_TIME_BLOCKS = 'GET_MEETING_TIME_BLOCKS';
export var CREATE_MEETING_TIME_BLOCKS = 'CREATE_MEETING_TIME_BLOCKS';
export var UPDATE_MEETING_TIME_BLOCKS = 'UPDATE_MEETING_TIME_BLOCKS';
export var UPDATE_MEETING_TIME_BLOCK_SLOT = 'UPDATE_MEETING_TIME_BLOCK_SLOT';
export var DELETE_MEETING_TIME_BLOCKS = 'DELETE_MEETING_TIME_BLOCKS';
export var GET_MEETING_TIME_BLOCK_SLOTS = 'GET_MEETING_TIME_BLOCK_SLOTS';
export var getMeetingTimeBlocks = function getMeetingTimeBlocks(meetingId) {
  return {
    type: GET_MEETING_TIME_BLOCKS,
    payload: new Promise(function (resolve) {
      api.get("timeblocks/meeting/".concat(meetingId)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var createMeetingTimeBlocks = function createMeetingTimeBlocks(meetingId, timeBlocks) {
  return {
    type: CREATE_MEETING_TIME_BLOCKS,
    payload: new Promise(function (resolve) {
      api.post("timeblocks/meeting/".concat(meetingId), timeBlocks).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var updateMeetingTimeBlock = function updateMeetingTimeBlock(meetingId, timeBlock) {
  return {
    type: UPDATE_MEETING_TIME_BLOCKS,
    payload: new Promise(function (resolve) {
      api.put("timeblocks/meeting/".concat(meetingId), timeBlock).then(function (response) {
        resolve(timeBlock);
      });
    })
  };
};
export var updateMeetingTimeBlockSlot = function updateMeetingTimeBlockSlot(meetingId, timeBlockSlot) {
  return {
    type: UPDATE_MEETING_TIME_BLOCK_SLOT,
    payload: new Promise(function (resolve) {
      api.put("timeblocks/meeting/".concat(meetingId, "/slots"), timeBlockSlot).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var deleteMeetingTimeBlock = function deleteMeetingTimeBlock(meetingId, blockId, callback) {
  return {
    type: DELETE_MEETING_TIME_BLOCKS,
    payload: new Promise(function (resolve) {
      api["delete"]("timeblocks/meeting/".concat(meetingId, "/block/").concat(blockId)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};