Array.prototype.getActiveSchool = function (key) {
  var response = this.find(function (s) {
    return s.id == localStorage.getItem("hal.school" + key);
  });
  if (response != null) return response;else if (this.length > 0) return this[0];
  return {
    type: ''
  };
};

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};