/**
 * @typedef {import('types').Bookmark} Bookmark
 */
import api from 'lib/api';
export var GET_MY_BOOKMARKS = 'BOOKMARKS_GET_MY_BOOKMARKS';
export var GET_BOOKMARK_BY_REFERENCE = 'BOOKMARKS_GET_BOOKMARK_BY_REFERENCE';
export var CREATE_BOOKMARK = 'BOOKMARKS_CREATE_BOOKMARK';
export var DELETE_BOOKMARK = 'BOOKMARKS_DELETE_BOOKMARK';
export var getMyBookmarks = function getMyBookmarks() {
  return {
    type: GET_MY_BOOKMARKS,
    payload: new Promise(function (resolve) {
      api.get('users/me/bookmarks').then(function (response) {
        resolve(response.data);
      });
    })
  };
};
/**
 * @param {number} referenceId
 * @param {string} referenceType ASSIGNMENT, PLAN
 */

export var getBookmarkByReference = function getBookmarkByReference(referenceId, referenceType) {
  return {
    type: GET_BOOKMARK_BY_REFERENCE,
    payload: new Promise(function (resolve) {
      api.get("bookmarks?referenceId=".concat(referenceId, "&referenceType=").concat(referenceType)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
/**
 * @param {Bookmark} values
 */

export var createBookmark = function createBookmark(values) {
  return {
    type: CREATE_BOOKMARK,
    payload: new Promise(function (resolve) {
      api.post("users/me/bookmarks", values).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
/**
 * @param {number} id
 */

export var deleteBookmark = function deleteBookmark(id) {
  return {
    type: DELETE_BOOKMARK,
    payload: new Promise(function (resolve) {
      api["delete"]("users/me/bookmarks/".concat(id)).then(function () {
        resolve(id);
      });
    })
  };
};