import { appInsights } from 'lib/appInsights';
export var trackNoGroups = function trackNoGroups(userId) {
  var localStorageSchool = localStorage.getItem("hal.school" + userId);
  var message = 'Groups is empty';
  var properties = {
    'user': userId,
    'currentSchool': localStorageSchool
  };
  appInsights.trackTrace({
    message: message,
    severityLevel: 2
  }, properties);
};
export var trackImmersiveReaderError = function trackImmersiveReaderError(error, userId) {
  var localStorageSchool = localStorage.getItem("hal.school" + userId);
  var message = 'ImmersiveReaderError';
  var properties = {
    'user': userId,
    'currentSchool': localStorageSchool,
    'error': error
  };
  appInsights.trackTrace({
    message: message,
    severityLevel: 2
  }, properties);
};