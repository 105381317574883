import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import api from 'lib/api';
export var CREATE_NOTE = 'NOTES_CREATE_NEW_NOTE';
export var UPDATE_NOTE = 'NOTES_UPDATE_NOTE';
export var SET_ACTIVE_NOTE = 'NOTES_SET_ACTIVE_NOTE';
export var RESET_ACTIVE_NOTE = 'NOTES_RESET_ACTIVE_NOTE';
export var GET_NOTES_BY_REFERENCE = 'NOTES_GET_NOTES_BY_REFERENCE';
export var GET_MENTOR_NOTES_BY_REFERENCE = 'GET_MENTOR_NOTES_BY_REFERENCE';
export var REMOVE_NOTE_BY_ID = 'NOTES_REMOVE_NOTE_BY_ID';
export var createNote = function createNote(note) {
  return {
    type: CREATE_NOTE,
    payload: new Promise(function (resolve) {
      api.post("notes", note).then(function (response) {
        var newNote = _objectSpread({
          id: response.data
        }, note);

        resolve(newNote);
      });
    })
  };
};
export var updateNote = function updateNote(note) {
  return {
    type: UPDATE_NOTE,
    payload: new Promise(function (resolve) {
      api.put("notes", note).then(function (data) {
        resolve(data);
      });
    })
  };
};
export var setActiveNote = function setActiveNote(note) {
  return {
    type: SET_ACTIVE_NOTE,
    payload: note
  };
};
export var resetActiveNote = function resetActiveNote() {
  return {
    type: RESET_ACTIVE_NOTE,
    payload: true
  };
};
export var getNotesByReference = function getNotesByReference(id, type) {
  return {
    type: GET_NOTES_BY_REFERENCE,
    payload: new Promise(function (resolve) {
      api.get("notes/reference/".concat(id, "/type/").concat(type)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getMentorNotesByReference = function getMentorNotesByReference(id, type) {
  return {
    type: GET_MENTOR_NOTES_BY_REFERENCE,
    payload: new Promise(function (resolve) {
      api.get("notes/reference/".concat(id, "/type/").concat(type)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var removeNote = function removeNote(id) {
  return {
    type: REMOVE_NOTE_BY_ID,
    payload: new Promise(function (resolve) {
      api["delete"]("notes/".concat(id)).then(function (response) {
        resolve(id);
      });
    })
  };
};