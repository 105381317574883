import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { LOG_ACTIVITY, GET_LOGS_PAGE_BY_SCHOOL } from 'actions/logs';
var initialState = {
  logs: [],
  startingTicks: ''
};
export default function Logs(state, action) {
  var type = action.type,
      value = action.value;

  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (type) {
    case GET_LOGS_PAGE_BY_SCHOOL:
      return _objectSpread(_objectSpread({}, state), {}, {
        logs: action.payload
      });

    case LOG_ACTIVITY:
      return state;

    default:
      return state;
      break;
  }
}