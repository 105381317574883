import packageJson from './../../package.json';
import { isSupported } from 'helpers/localstorage';
import getEnviroment from 'lib/env';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

var shorthash = require('../../app-config/shorthash.js');

var s_shortterm_id = shorthash.unique(new Date().toUTCString());

if (isSupported()) {
  if (window.location.pathname === '/Auth' && localStorage.getItem('hsid') != null) {
    s_shortterm_id = localStorage.getItem('hsid');
  } else {
    localStorage.setItem('hsid', s_shortterm_id);
  }
}

var appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: getEnviroment().applicationInsightKey,
    disableExceptionTracking: false
  }
});
appInsights.loadAppInsights();
appInsights.addTelemetryInitializer(function (envelope) {
  envelope.data.hsid = s_shortterm_id;
});
appInsights.context.application.ver = packageJson.version;

var getEnhancedLogLevel = function getEnhancedLogLevel() {
  if (localStorage.getItem('enhanced-log-level') != null) {
    return localStorage.getItem('enhanced-log-level');
  }

  return 0;
};

var setEnhancedLogLevel = function setEnhancedLogLevel(level) {
  localStorage.setItem('enhanced-log-level', level);
  return level;
};

export { appInsights, getEnhancedLogLevel, setEnhancedLogLevel };