import api from 'lib/api';
export var GET_STUDENT_ASSESSOR_ASSESSMENT = 'GET_STUDENT_ASSESSOR_ASSESSMENT';
export var GET_STUDENT_ASSESSOR_ASSESSMENTS = 'GET_STUDENT_ASSESSOR_ASSESSMENTS';
export var CREATE_STUDENT_ASSESSMENTS = 'CREATE_STUDENT_ASSESSMENTS';
export var UPDATE_STUDENT_ASSESSMENT = 'UPDATE_STUDENT_ASSESSMENT';
export var DELETE_STUDENT_ASSESSMENT = 'DELETE_STUDENT_ASSESSMENT';
export var getStudentAssessment = function getStudentAssessment(id, userId, callback) {
  return {
    type: GET_STUDENT_ASSESSOR_ASSESSMENT,
    payload: new Promise(function (resolve) {
      api.get("assignments/".concat(id, "/studentassessments/").concat(userId)).then(function (response) {
        resolve(response.data);

        if (callback != null) {
          callback(response.data);
        }
      });
    })
  };
};
export var getStudentAssessments = function getStudentAssessments(id, callback) {
  return {
    type: GET_STUDENT_ASSESSOR_ASSESSMENTS,
    payload: new Promise(function (resolve) {
      api.get("assignments/".concat(id, "/studentassessments")).then(function (response) {
        if (response.status == 204) {
          resolve(null);
        } else {
          resolve(response.data);
        }

        if (callback != null) {
          callback(response.data);
        }
      });
    })
  };
};
export var createStudentAssessments = function createStudentAssessments(id, assessment, callback) {
  return {
    type: CREATE_STUDENT_ASSESSMENTS,
    payload: new Promise(function (resolve) {
      var endpoint = "assignments/".concat(id, "/studentassessment");
      api.post(endpoint, assessment).then(function (response) {
        resolve(response.data);

        if (callback != null) {
          callback(response.data);
        }
      });
    })
  };
};
export var updateStudentAssessment = function updateStudentAssessment(id, assessment, callback) {
  return {
    type: UPDATE_STUDENT_ASSESSMENT,
    payload: new Promise(function (resolve) {
      var endpoint = "assignments/".concat(id, "/studentassessment");
      api.put(endpoint, assessment).then(function (response) {
        resolve(response.data);

        if (callback != null) {
          callback(response.data);
        }
      });
    })
  };
};
export var deletStudentAssessment = function deletStudentAssessment(data, id, callback) {
  return {
    type: DELETE_STUDENT_ASSESSMENT,
    payload: new Promise(function (resolve) {
      api["delete"]("assignments/".concat(id, "/studentassessment"), data).then(function (response) {
        resolve(response.data);

        if (callback != null) {
          callback(response.data);
        }
      });
    })
  };
};