import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { GET_MEETING_TIME_BLOCKS, CREATE_MEETING_TIME_BLOCKS, UPDATE_MEETING_TIME_BLOCKS, UPDATE_MEETING_TIME_BLOCK_SLOT, DELETE_MEETING_TIME_BLOCKS } from '../actions/timeblocks';
import Moment from 'moment';
var INITIAL_STATE = {
  meetingTimeBlocks: [],
  meetingTimeBlockSlots: [],
  meetingTimeBlockSlot: {}
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case GET_MEETING_TIME_BLOCKS:
      return _objectSpread(_objectSpread({}, state), {}, {
        meetingTimeBlocks: action.payload.sort(function (a, b) {
          return new Moment(a.startTime).unix() - new Moment(b.startTime).unix();
        })
      });

    case CREATE_MEETING_TIME_BLOCKS:
      return _objectSpread(_objectSpread({}, state), {}, {
        meetingTimeBlocks: [].concat(_toConsumableArray(state.meetingTimeBlocks), _toConsumableArray(action.payload)).sort(function (a, b) {
          return new Moment(a.startTime).unix() - new Moment(b.startTime).unix();
        })
      });

    case UPDATE_MEETING_TIME_BLOCKS:
      return _objectSpread(_objectSpread({}, state), {}, {
        meetingTimeBlocks: _toConsumableArray(state.meetingTimeBlocks.filter(function (t) {
          return t.id != action.payload.id;
        })).sort(function (a, b) {
          return new Moment(a.startTime).unix() - new Moment(b.startTime).unix();
        })
      });

    case UPDATE_MEETING_TIME_BLOCK_SLOT:
      return _objectSpread(_objectSpread({}, state), {}, {
        meetingTimeBlockSlot: action.payload
      });

    case DELETE_MEETING_TIME_BLOCKS:
      return _objectSpread(_objectSpread({}, state), {}, {
        meetingTimeBlocks: _toConsumableArray(state.meetingTimeBlocks.filter(function (t) {
          return t.id != action.payload;
        })).sort(function (a, b) {
          return new Moment(a.startTime).unix() - new Moment(b.startTime).unix();
        })
      });

    default:
      return state;
  }
}