import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { GET_DIRECTORY_CONTENTS, SET_ACTIVE_DIRECTORY, SET_SAVED_DIR_AS_ACTIVE, GET_SECTION_DRIVES, GET_SECTION_CONTENT } from '../actions/onedrive';
var initialState = {
  activeFolders: [],
  activeSections: [],
  activeContent: null,
  fetchedFolders: [],
  sectionDrives: null,
  sectionContent: null
};
export default function (state, action) {
  var type = action.type;

  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (type) {
    case GET_DIRECTORY_CONTENTS:
      var savedFolders = state.fetchedFolders;
      savedFolders.push(action.payload);
      return _objectSpread(_objectSpread({}, state), {}, {
        activeContent: action.payload,
        fetchedFolders: savedFolders
      });

    case SET_ACTIVE_DIRECTORY:
      if (typeof action.folder !== 'undefined' && action.folder !== null) {
        if (action.folder === 'root' || action.folder === 'sharepointroot') {
          return _objectSpread(_objectSpread({}, state), {}, {
            activeFolders: []
          });
        }

        var newState = state.activeFolders;

        if (action.root) {
          newState = [];
        }

        var found = newState.find(function (adir) {
          return adir.id === action.folder;
        });

        if (typeof found === 'undefined') {
          // Not in list, add it
          // First search for this id in the fetchedFolders state
          var savedFolder = null;
          state.fetchedFolders.map(function (fdir) {
            if (fdir != null) {
              var tryer = fdir.files.find(function (file) {
                return file.id === action.folder;
              });

              if (typeof tryer !== 'undefined') {
                savedFolder = tryer;
              }
            }
          });

          if (savedFolder != null) {
            // Then use that data for creating an object for displaying the breadcrumb item
            var folder = {
              id: savedFolder.id,
              name: savedFolder.name,
              folder: true
            };
            newState.push(folder);
          }
        } else {
          // Already in list
          var dirIndex = newState.findIndex(function (adir) {
            return adir.id === action.folder;
          });

          if (dirIndex < newState.length) {
            // The current dir isnt the last in the list
            // Remove all entries behind it
            newState.splice(dirIndex + 1, newState.length);
          } else {
            // The current dir is the last in the list
            newState.splice(dirIndex, 1);
          }
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          activeFolders: newState
        });
      } else {
        return _objectSpread(_objectSpread({}, state), {}, {
          activeFolders: []
        });
      }

    case SET_SAVED_DIR_AS_ACTIVE:
      if (typeof action.folder !== 'undefined' && action.folder !== null) {
        var foundObject = state.fetchedFolders.find(function (dir) {
          if (dir && dir.id) {
            return dir.id === action.folder;
          }
        });

        if (typeof foundObject !== 'undefined') {
          if (foundObject.type == 'sectionContent') {
            return _objectSpread(_objectSpread({}, state), {}, {
              sectionContent: foundObject
            });
          } else {
            return _objectSpread(_objectSpread({}, state), {}, {
              activeContent: foundObject
            });
          }
        }
      } else {
        // Reset the state of the activeContent property
        return _objectSpread(_objectSpread({}, state), {}, {
          activeContent: initialState.activeContent,
          sectionContent: initialState.sectionContent
        });
      }

    case GET_SECTION_DRIVES:
      return _objectSpread(_objectSpread({}, state), {}, {
        sectionDrives: action.payload
      });

    case GET_SECTION_CONTENT:
      if (action.payload != null) {
        action.payload.type = 'sectionContent';
        var newFolders = state.fetchedFolders;
        newFolders.push(action.payload);
        return _objectSpread(_objectSpread({}, state), {}, {
          sectionContent: action.payload,
          fetchedFolders: newFolders
        });
      }

    default:
      return state;
  }
}