import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { GET_SECTIONS, GET_SECTION, GET_FILTERED_SECTION, GET_SECTION_DETAILS, GET_EDUCATION_GROUPS, GET_ARCHIVED_EDUCATION_GROUPS, GET_MENTOR_GROUPS, GET_ARCHIEVED_MENTOR_GROUPS, SET_ACTIVE_SECTION, CLEAR_SECTIONS_FROM_STORE, GET_SCHOOL_BY_GROUP_GRAPH_ID, GET_SECTION_BY_GRAPH_ID, GET_SECTION_ATTENDANCE_STATS, CLEAR_SECTION_ATTENDANCE_STATS, GET_GROUP_ABSENCE, GET_SECTION_GUARDIANS, GET_STUDENT_ATTENDANCE_STATS, CLEAR_STUDENT_ATTENDANCE_STATS, GET_SECTION_TEAMS_CHANNEL, GET_SECTION_ASESSMENT_STATISTICS, TOGGLE_SELECTED_SECTION, CLEAR_SELECTED_SECTIONS, GET_EDUCATION_GROUPS_LEGACY } from 'actions/sections';

function sortMatrices(section, state) {
  if (section != null && section.courses != null) {
    if (section.courses.length > 0) {
      section.courses.forEach(function (course) {
        if (course.details != null) {
          if (course.details.length > 0) {
            course.details.forEach(function (detail) {
              if (detail.matrix != null) {
                if (detail.matrix._Rows != null) {
                  var rows = detail.matrix._Rows.sort(function (a, b) {
                    return a.paragraph - b.paragraph || a.sentenceNr - b.sentenceNr;
                  });

                  detail.matrix._Rows = rows;
                }
              }
            });
          }
        }
      });
    }
  }
}

var INITIAL_STATE = {
  sections: null,
  mentorGroups: [],
  mentorArchievedGroups: [],
  educationGroups: [],
  archivedEducationGroups: [],
  legacyEducationGroups: [],
  activeSection: null,
  activeFilteredSection: null,
  graphGroupSchool: null,
  graphSection: null,
  sectionAttendance: null,
  guardians: [],
  studentAttendanceStats: [],
  teamChannels: [],
  assessmentStatistics: null,
  sectionAssessmentStatistics: {
    data: null,
    error: null,
    requestResponseCode: null
  },
  selected: []
};
export default function () {
  var _action$payload, _action$payload2, _action$payload2$resp;

  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case GET_SCHOOL_BY_GROUP_GRAPH_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        graphGroupSchool: action.payload
      });

    case GET_SECTIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        sections: action.payload
      });

    case GET_SECTION_BY_GRAPH_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        graphSection: action.payload
      });

    case GET_EDUCATION_GROUPS_LEGACY:
      var groups = action.payload;

      if (Array.isArray(groups) && groups.length > 0) {
        groups.forEach(function (group) {
          group.type = group.type + "_LEGACY";
        });
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        legacyEducationGroups: groups
      });

    case GET_EDUCATION_GROUPS:
      if (action.payload != null) {
        return _objectSpread(_objectSpread({}, state), {}, {
          educationGroups: action.payload,
          loading: false
        });
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        educationGroups: action.payload
      });

    case GET_SECTION_TEAMS_CHANNEL:
      return _objectSpread(_objectSpread({}, state), {}, {
        teamChannels: action.payload
      });

    case GET_ARCHIVED_EDUCATION_GROUPS:
      if (action.payload != null) {
        return _objectSpread(_objectSpread({}, state), {}, {
          archivedEducationGroups: action.payload,
          loading: false
        });
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        archivedEducationGroups: action.payload
      });

    case GET_MENTOR_GROUPS:
      return _objectSpread(_objectSpread({}, state), {}, {
        mentorGroups: action.payload
      });

    case GET_ARCHIEVED_MENTOR_GROUPS:
      return _objectSpread(_objectSpread({}, state), {}, {
        mentorArchievedGroups: action.payload
      });

    case GET_SECTION:
      sortMatrices(action.payload, state);
      return _objectSpread(_objectSpread({}, state), {}, {
        activeSection: action.payload
      });

    case GET_FILTERED_SECTION:
      sortMatrices(action.payload, state);
      return _objectSpread(_objectSpread({}, state), {}, {
        activeFilteredSection: action.payload
      });

    case GET_SECTION_DETAILS:
      if (action.payload != null && state.educationGroups.length > 0) {
        var currentIndex = state.educationGroups.findIndex(function (se) {
          return se.id == action.payload.id;
        });

        if (currentIndex > -1) {
          var newSections = state.educationGroups;
          newSections.splice(currentIndex, 1, action.payload);
          return _objectSpread(_objectSpread({}, state), {}, {
            educationGroups: newSections
          });
        }

        return state;
      }

      return state;

    case SET_ACTIVE_SECTION:
      return _objectSpread(_objectSpread({}, state), {}, {
        activeSection: null
      });

    case CLEAR_SECTIONS_FROM_STORE:
      return _objectSpread(_objectSpread({}, state), {}, {
        educationGroups: [],
        sections: INITIAL_STATE.sections,
        mentorGroups: [],
        archivedEducationGroups: INITIAL_STATE.archivedEducationGroups
      });

    case GET_SECTION_ATTENDANCE_STATS:
      return _objectSpread(_objectSpread({}, state), {}, {
        sectionAttendance: action.payload
      });

    case CLEAR_SECTION_ATTENDANCE_STATS:
      return _objectSpread(_objectSpread({}, state), {}, {
        sectionAttendance: INITIAL_STATE.sectionAttendance
      });

    case GET_GROUP_ABSENCE:
      if (action.payload != null) {
        var activeGroup = state.activeSection;
        var mentorGroups = state.mentorGroups;

        if (state.mentorGroups != null) {
          mentorGroups = mentorGroups.map(function (group) {
            if (group.id == action.payload.groupId) {
              group.absences = action.payload.value;
            }

            return group;
          });
        }

        if (state.activeSection != null) {
          if (state.activeSection.id == action.payload.groupId) {
            activeGroup.absences = action.payload.value;
          }
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          activeSection: activeGroup,
          mentorGroups: mentorGroups
        });
      }

      return state;

    case GET_SECTION_GUARDIANS:
      return _objectSpread(_objectSpread({}, state), {}, {
        guardians: [].concat(_toConsumableArray(state.guardians.filter(function (t) {
          return t.id != action.payload.id;
        })), [action.payload])
      });

    case GET_STUDENT_ATTENDANCE_STATS:
      return _objectSpread(_objectSpread({}, state), {}, {
        studentAttendanceStats: [].concat(_toConsumableArray(state.studentAttendanceStats), [action.payload])
      });

    case CLEAR_STUDENT_ATTENDANCE_STATS:
      return _objectSpread(_objectSpread({}, state), {}, {
        studentAttendanceStats: INITIAL_STATE.studentAttendanceStats
      });

    case GET_SECTION_ASESSMENT_STATISTICS:
      var existingObject = state.sectionAssessmentStatistics;
      existingObject.data = action.error ? null : action.payload;
      existingObject.error = action.error ? (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.response : null;
      existingObject.requestResponseCode = action.error ? (_action$payload2 = action.payload) === null || _action$payload2 === void 0 ? void 0 : (_action$payload2$resp = _action$payload2.response) === null || _action$payload2$resp === void 0 ? void 0 : _action$payload2$resp.status : 200;
      return _objectSpread(_objectSpread({}, state), {}, {
        sectionAssessmentStatistics: existingObject
      });

    case TOGGLE_SELECTED_SECTION:
      if (action.payload.section != null) {
        var selected = _toConsumableArray(state.selected);

        var foundInSelected = selected.findIndex(function (group) {
          return group.id == action.payload.section.id;
        });

        if (action.payload.groupAssignment) {
          selected = [action.payload.section];
        } else if (foundInSelected > -1) {
          selected.splice(foundInSelected, 1);
        } else {
          selected.push(action.payload.section);
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          selected: selected
        });
      }

      return state;

    case CLEAR_SELECTED_SECTIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        selected: INITIAL_STATE.selected
      });

    default:
      return state;
  }
}