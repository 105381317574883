import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { GET_BASE_GROUP, GET_BASE_GROUPS, CREATE_BASE_GROUP, UPDATE_BASE_GROUP, DELETE_BASE_GROUP, CLEAR_ACTIVE_BASE_GROUP } from 'actions/basegroups';
var INITIAL_STATE = {
  baseGroups: [],
  baseGroup: null
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case GET_BASE_GROUP:
      return _objectSpread(_objectSpread({}, state), {}, {
        baseGroup: action.payload
      });

    case GET_BASE_GROUPS:
      return _objectSpread(_objectSpread({}, state), {}, {
        baseGroups: action.payload
      });

    case CREATE_BASE_GROUP:
      return _objectSpread(_objectSpread({}, state), {}, {
        baseGroup: action.payload,
        baseGroups: [].concat(_toConsumableArray(state.baseGroups), [action.payload])
      });

    case UPDATE_BASE_GROUP:
      return _objectSpread(_objectSpread({}, state), {}, {
        baseGroup: action.payload
      });

    case DELETE_BASE_GROUP:
      return _objectSpread(_objectSpread({}, state), {}, {
        baseGroups: _toConsumableArray(state.baseGroups.filter(function (t) {
          return t.id != action.payload;
        })),
        baseGroup: INITIAL_STATE.baseGroup
      });

    case CLEAR_ACTIVE_BASE_GROUP:
      return _objectSpread(_objectSpread({}, state), {}, {
        baseGroup: INITIAL_STATE.baseGroup
      });

    default:
      return state;
  }
}