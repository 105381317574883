import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { GET_MY_BOOKMARKS, GET_BOOKMARK_BY_REFERENCE, CREATE_BOOKMARK, DELETE_BOOKMARK } from 'actions/bookmarks';
var INITIAL_STATE = {
  user: [],
  reference: []
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case GET_MY_BOOKMARKS:
      return _objectSpread(_objectSpread({}, state), {}, {
        user: action.payload
      });

    case GET_BOOKMARK_BY_REFERENCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        reference: action.payload
      });

    case CREATE_BOOKMARK:
      return _objectSpread(_objectSpread({}, state), {}, {
        user: [].concat(_toConsumableArray(state.user), _toConsumableArray(action.payload)),
        reference: [].concat(_toConsumableArray(state.reference), _toConsumableArray(action.payload))
      });

    case DELETE_BOOKMARK:
      return _objectSpread(_objectSpread({}, state), {}, {
        user: _toConsumableArray(state.user.filter(function (bookmark) {
          return bookmark.id != action.payload;
        })),
        reference: state.reference != null && state.reference.id == action.payload ? INITIAL_STATE.reference : state.reference
      });

    default:
      return state;
  }
}