import api from 'lib/api';
export var KNOWLEDGE = 'KNOWLEDGE';
export var CLEAR_KNOWLEDGE = 'CLEAR_KNOWLEDGE';
export var ACTIVE_PLAN_KNOWLEDGECOLLECTIONS = 'ACTIVE_PLAN_KNOWLEDGECOLLECTIONS';
export var ACTIVE_ASSIGNMENT_KNOWLEDGECOLLECTIONS = 'ACTIVE_ASSIGNMENT_KNOWLEDGECOLLECTIONS';
export var UPDATE_CLARIFICATION = 'UPDATE_CLARIFICATION';
export var DELETE_CLARIFICATION = 'DELETE_CLARIFICATION';
export var getKnowledgeBySection = function getKnowledgeBySection(sectionId, grouped, callback) {
  return {
    type: KNOWLEDGE,
    payload: new Promise(function (resolve) {
      var endpoint = "knowledge/section/".concat(sectionId);

      if (grouped) {
        endpoint = "".concat(endpoint, "/grouped/paragraph");
      } else {
        endpoint = "".concat(endpoint, "/collections");
      }

      api.get(endpoint).then(function (response) {
        resolve(response.data);
        callback(response.data);
      });
    })
  };
};
export var getGroupedKnowledgeBySection = function getGroupedKnowledgeBySection(sectionId, callback) {
  return {
    type: KNOWLEDGE,
    payload: new Promise(function (resolve) {
      var endpoint = "knowledge/section/".concat(sectionId, "/collections");
      api.get(endpoint).then(function (response) {
        resolve(response.data);
        callback(response.data);
      });
    })
  };
};
export var getPlanKnowledgeCollections = function getPlanKnowledgeCollections(sectionId, planId, callback) {
  return {
    type: ACTIVE_PLAN_KNOWLEDGECOLLECTIONS,
    payload: new Promise(function (resolve) {
      api.get("knowledge/section/".concat(sectionId, "/plan/").concat(planId, "/collections")).then(function (response) {
        resolve(response.data);
        callback(response.data);
      });
    })
  };
};
export var getAssignmentKnowledgeCollections = function getAssignmentKnowledgeCollections(sectionId, assignmentId, callback) {
  return {
    type: ACTIVE_ASSIGNMENT_KNOWLEDGECOLLECTIONS,
    payload: new Promise(function (resolve) {
      api.get("knowledge/section/".concat(sectionId, "/assignment/").concat(assignmentId, "/collections")).then(function (response) {
        resolve(response.data);
        callback(response.data);
      });
    })
  };
};
export var updateClarification = function updateClarification(clarification, callback) {
  return {
    type: UPDATE_CLARIFICATION,
    payload: new Promise(function (resolve) {
      var endpoint = 'knowledge/clarification';
      api.put(endpoint, clarification).then(function (response) {
        resolve(response.data);
        callback(response.data);
      });
    })
  };
};
export var deleteClarification = function deleteClarification(clarification, callback) {
  return {
    type: DELETE_CLARIFICATION,
    payload: new Promise(function (resolve) {
      api["delete"]("knowledge/clarification/".concat(clarification.id)).then(function (response) {
        resolve(response.data);
        callback(response.data);
      });
    })
  };
};
export var clearKnowledge = function clearKnowledge() {
  return {
    type: CLEAR_KNOWLEDGE,
    payload: []
  };
};