import api from 'lib/api';
export var GET_FLAGGED_MESSAGES = 'CONVERSATIONS_ADMIN_GET_FLAGGED_MESSAGES';
export var UPDATE_FLAGGED_MESSAGE = 'CONVERSATIONS_ADMIN_UPDATE_FLAGGED_MESSAGE';
export var DELETE_FLAGGED_MESSAGE = 'CONVERSATIONS_ADMIN_DELETE_FLAGGED_MESSAGE';
export var getFlaggedMessages = function getFlaggedMessages() {
  return {
    type: GET_FLAGGED_MESSAGES,
    payload: new Promise(function (resolve) {
      api.get("conversations/flagged").then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var updateFlaggedMessage = function updateFlaggedMessage(flag) {
  return {
    type: UPDATE_FLAGGED_MESSAGE,
    payload: new Promise(function (resolve) {
      api.put('conversations/flagged', flag).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var deleteFlaggedMessage = function deleteFlaggedMessage(flagId) {
  return {
    type: DELETE_FLAGGED_MESSAGE,
    payload: new Promise(function (resolve) {
      api["delete"]("conversations/flagged/".concat(flagId)).then(function (response) {
        resolve(flagId);
      });
    })
  };
};