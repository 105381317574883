/**
 * @typedef {import('types').HaldorEnvironment} HaldorEnvironment
 */
var environments = require('../../app-config/_environments.json');
/**
 * @returns HaldorEnvironment
 */


var getEnvironment = function getEnvironment() {
  /** @type {HaldorEnvironment} environment */
  var environment = environments.find(function (environment) {
    return environment.domains.indexOf(window.location.host) > -1;
  });
  return environment;
};

export default getEnvironment;