import api from 'lib/api';
export var GET_TYPES = 'GET_TYPES';
export var getTypes = function getTypes(lcid) {
  return {
    type: GET_TYPES,
    payload: new Promise(function (resolve) {
      api.get("status/".concat(lcid)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};