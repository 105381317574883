import api from 'lib/api';
export var GET_SINGLE_SCHOOL_DETAILS = 'SCHOOLS_GET_SINGLE_SCHOOL_DETAILS';
export var GET_SCHOOL_ABSENCE = 'SCHOOLS_GET_SINGLE_SCHOOL_ABSENCE';
export var SET_ACTIVE_SCHOOL = 'SCHOOLS_SET_ACTIVE_SCHOOL';
export var GET_SINGLE_SCHOOL_STUDENTS = 'SCHOOLS_GET_SINGLE_SCHOOL_STUDENTS';
export var GET_SCHOOL_GUARDIANS = 'GET_SCHOOL_GUARDIANS';
export var UPDATE_SCHOOL_TYPE = 'UPDATE_SCHOOL_TYPE';
export var GET_SCHOOL_SUBJECTTEACHERS = 'GET_SCHOOL_SUBJECTTEACHERS';
export var GET_SCHOOL_SERVICES = 'SCHOOLS_GET_SCHOOL_SERVICES';
export var GET_SCHOOLS_DATA_FOR_SECTIONS = 'GET_SCHOOLS_DATA_FOR_SECTIONS';
export var GET_SCHOOL_DATA_FOR_SECTIONS = 'GET_SCHOOL_DATA_FOR_SECTIONS';
export var GET_SINGLE_SCHOOL_BY_EXTERNALID = 'GET_SINGLE_SCHOOL_BY_EXTERNALID';
export var getSchoolByExternalId = function getSchoolByExternalId(id) {
  return {
    type: GET_SINGLE_SCHOOL_BY_EXTERNALID,
    payload: new Promise(function (resolve) {
      api.get("schools/externalId/".concat(id)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getSchoolDetails = function getSchoolDetails(id) {
  return {
    type: GET_SINGLE_SCHOOL_DETAILS,
    payload: new Promise(function (resolve) {
      api.get("schools/".concat(id)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getSchoolDataForSections = function getSchoolDataForSections(id) {
  return {
    type: GET_SCHOOL_DATA_FOR_SECTIONS,
    payload: new Promise(function (resolve) {
      api.get("schools/".concat(id, "/sectionsettings")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getSchoolsDataForSections = function getSchoolsDataForSections() {
  return {
    type: GET_SCHOOLS_DATA_FOR_SECTIONS,
    payload: new Promise(function (resolve) {
      api.get("schools/sectionsettings").then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getSchoolAbsence = function getSchoolAbsence(id, date) {
  return {
    type: GET_SCHOOL_ABSENCE,
    payload: new Promise(function (resolve) {
      api.get("schools/".concat(id, "/absence?date=").concat(date)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getSchoolStudents = function getSchoolStudents(id, skip) {
  return {
    type: GET_SINGLE_SCHOOL_STUDENTS,
    payload: new Promise(function (resolve) {
      if (skip != null) {
        api.get("schools/".concat(id, "/students/").concat(skip)).then(function (response) {
          resolve(response.data);
        });
        return true;
      }

      api.get("schools/".concat(id, "/students")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getSchoolGuardians = function getSchoolGuardians(id) {
  return {
    type: GET_SCHOOL_GUARDIANS,
    payload: new Promise(function (resolve) {
      api.get("schools/".concat(id, "/guardians")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getSchoolSubjectTeachers = function getSchoolSubjectTeachers(id) {
  return {
    type: GET_SCHOOL_SUBJECTTEACHERS,
    payload: new Promise(function (resolve) {
      api.get("schools/".concat(id, "/subjectteachers")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var updateSchoolType = function updateSchoolType(Id, Type) {
  return {
    type: UPDATE_SCHOOL_TYPE,
    payload: new Promise(function (resolve) {
      api.put("schools/".concat(Id, "/type/").concat(Type), null).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getSchoolServices = function getSchoolServices(id) {
  return {
    type: GET_SCHOOL_SERVICES,
    payload: new Promise(function (resolve) {
      api.get("schools/".concat(id, "/services")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};