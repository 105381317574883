import api from 'lib/api';
export var LOG_ACTIVITY = 'LOG_ACTIVITY';
export var GET_LOGS_PAGE_BY_SCHOOL = 'GET_LOGS_PAGE_BY_SCHOOL';
export function logActivity(values) {
  return {
    type: LOG_ACTIVITY,
    payload: new Promise(function (resolve) {
      api.post('log', values).then(function (response) {
        resolve(response.data);
      });
    })
  };
}
export function getLogsPage(startingTicks, pageSize) {
  return {
    type: GET_LOGS_PAGE_BY_SCHOOL,
    payload: new Promise(function (resolve) {
      api.get("log?startingTicks=".concat(startingTicks, "&pageSize=").concat(pageSize)).then(function (response) {
        resolve(response.data);
      });
    })
  };
}