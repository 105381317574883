import api from 'lib/api';
export var GET_SMS_LIST = 'SMS_GET_SMS_LIST';
export var GET_SMS = 'SMS_GET_SMS';
export var CREATE_SMS = 'SMS_CREATE_SMS';
export var UPDATE_SMS = 'SMS_UPDATE_SMS';
export var DELETE_SMS = 'SMS_DELETE_SMS';
export var GET_REMAINING_SMS = 'SMS_GET_SMS_REMAINING';
export var GET_SMS_STATISTICS = 'SMS_GET_SMS_STATISTICS';
export var GET_SMS_EDUCATION_SECTIONS = 'SMS_GET_SMS_EDUCATION_SECTIONS';
export var GET_SMS_MENTOR_SECTIONS = 'SMS_GET_SMS_MENTOR_SECTIONS';
export var getSMSList = function getSMSList(group, status, start, end) {
  return {
    type: GET_SMS_LIST,
    payload: new Promise(function (resolve) {
      var url = "sms?_=1";

      if (group != null) {
        url += "&sectionId=".concat(group);
      }

      if (status != null) {
        url += "&status=".concat(status);
      }

      if (start != null) {
        url += "&startDate=".concat(start);
      }

      if (end != null) {
        url += "&endDate=".concat(end);
      }

      api.get(url).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getSMS = function getSMS(id) {
  return {
    type: GET_SMS,
    payload: new Promise(function (resolve) {
      api.get("sms/".concat(id)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var createSMS = function createSMS(values) {
  return {
    type: CREATE_SMS,
    payload: new Promise(function (resolve, reject) {
      api.post('sms', values).then(function (response) {
        resolve(response.data);
      })["catch"](function (error) {
        reject(error);
      });
    })
  };
};
export var updateSMS = function updateSMS(values) {
  return {
    type: UPDATE_SMS,
    payload: new Promise(function (resolve, reject) {
      api.put("sms/".concat(values.id), values).then(function (response) {
        resolve(response.data);
      })["catch"](function (error) {
        reject(error);
      });
    })
  };
};
export var deleteSMS = function deleteSMS(id) {
  return {
    type: DELETE_SMS,
    payload: new Promise(function (resolve) {
      api["delete"]("sms/".concat(id)).then(function (response) {
        resolve(1);
      });
    })
  };
};
export var getRemainingSMS = function getRemainingSMS() {
  return {
    type: GET_REMAINING_SMS,
    payload: new Promise(function (resolve) {
      api.get('sms/settings').then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getSMSStatistics = function getSMSStatistics() {
  return {
    type: GET_SMS_STATISTICS,
    payload: new Promise(function (resolve) {
      api.get("sms/statistics").then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getSMSEducationSections = function getSMSEducationSections() {
  return {
    type: GET_SMS_EDUCATION_SECTIONS,
    payload: new Promise(function (resolve) {
      api.get("sections?serviceId=HALDOR_SMS&type=EDUCATION_GROUP").then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getSMSMentorSections = function getSMSMentorSections() {
  return {
    type: GET_SMS_MENTOR_SECTIONS,
    payload: new Promise(function (resolve) {
      api.get("sections?serviceId=HALDOR_SMS&type=MENTOR_GROUP").then(function (response) {
        resolve(response.data);
      });
    })
  };
};