import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { SET_USER, GET_TENANT, USER_ROLES, CURRENT_USER, CURRENT_USER_BASE, SET_ROLE, SET_SCHOOL, SET_SCHOOL_TYPE, SEARCH_USER, GET_USER_INFO, SET_ACTIVE_TEAMS_TAB, GET_TENANT_SCHOOLS, USER_GET_USER_ABSENCE, USER_GET_MENTOR_SECTION, CLEAR_SEARCH_USER, GET_USER_GUARDIAN, GET_GUARDIAN_BY_EMAIL, GET_GUARDIAN_BY_ID, SET_GUARDIAN_BY_ID, LINK_STUDENT_GUARDIAN, ADD_USER_GUARDIAN, SET_USER_AUTHORITATIVE, GET_READER_PARAMS, GET_USER_COURSES, GET_USER_SUBJECTS, UPDATE_USER, GET_USER_FILES, ADD_USER_ROLE, DELETE_USER_ROLE, GET_TEACHER_ROLES, DELETE_TEACHER_ROLE, ADD_TEACHER_ROLE, GET_USER_SECTIONS, GET_MY_MENTOR_SECTIONS, GET_USERS_WITHOUT_MENTOR_GROUPS, USER_ADMIN_CHECK, GET_MY_SECTIONS, GET_ALL_SECTIONS, GET_ALL_USER_SECTIONS, DELETE_USER_FILE, USER_SCOPES_VERIFICATION, GET_USER_ACCESSTOKEN } from 'actions/user';
import { SET_ARCHIVING_SECTION } from 'actions/sections';
var INITIAL_STATE = {
  user: null,
  currentUser: null,
  currentBaseUser: null,
  userCourses: [],
  userSubjects: [],
  tenant: null,
  activeRole: null,
  activeSchool: null,
  roles: [],
  searchResults: null,
  info: [],
  activeTab: null,
  absence: null,
  userMentorSections: null,
  guardians: [],
  guardian: null,
  searchGuardian: null,
  reader: null,
  files: [],
  teacherRoles: [],
  sections: null,
  usersWithoutMentorGroups: null,
  onboardingUser: null,
  mySections: null,
  myScopesVerification: null,
  accessToken: null
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case GET_USER_ACCESSTOKEN:
      return _objectSpread(_objectSpread({}, state), {}, {
        accessToken: action.payload
      });

    case USER_SCOPES_VERIFICATION:
      return _objectSpread(_objectSpread({}, state), {}, {
        myScopesVerification: action.payload
      });

    case USER_ADMIN_CHECK:
      return _objectSpread(_objectSpread({}, state), {}, {
        onboardingUser: action.payload
      });

    case ADD_USER_ROLE:
      return _objectSpread({}, state);

    case DELETE_USER_ROLE:
      return _objectSpread({}, state);

    case SET_ACTIVE_TEAMS_TAB:
      return _objectSpread(_objectSpread({}, state), {}, {
        activeTab: action.payload
      });

    case SET_USER:
      return _objectSpread(_objectSpread({}, state), {}, {
        user: action.payload
      });

    case GET_TENANT:
      return _objectSpread(_objectSpread({}, state), {}, {
        tenant: action.payload
      });

    case GET_TENANT_SCHOOLS:
      return _objectSpread(_objectSpread({}, state), {}, {
        tenant: action.payload
      });

    case USER_ROLES:
      return _objectSpread(_objectSpread({}, state), {}, {
        roles: action.payload
      });

    case CURRENT_USER:
      return _objectSpread(_objectSpread({}, state), {}, {
        currentUser: action.payload
      });

    case CURRENT_USER_BASE:
      return _objectSpread(_objectSpread({}, state), {}, {
        currentBaseUser: action.payload
      });

    case SET_ROLE:
      var role = action.payload.toUpperCase();
      return _objectSpread(_objectSpread({}, state), {}, {
        activeRole: role
      });

    case SET_SCHOOL:
      return _objectSpread(_objectSpread({}, state), {}, {
        activeSchool: action.payload
      });

    case SET_SCHOOL_TYPE:
      var user = state.currentUser;
      user.schools.map(function (school) {
        school.type = action.payload;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        currentUser: user
      });

    case SEARCH_USER:
      return _objectSpread(_objectSpread({}, state), {}, {
        searchResults: action.payload
      });

    case CLEAR_SEARCH_USER:
      return _objectSpread(_objectSpread({}, state), {}, {
        searchResults: null
      });

    case GET_USER_INFO:
      if (action.payload != null) {
        return _objectSpread(_objectSpread({}, state), {}, {
          info: [].concat(_toConsumableArray(state.info.filter(function (t) {
            return t.userId != action.payload.userId;
          })), [action.payload])
        });
      }

      return state;

    case SET_USER_AUTHORITATIVE:
      var info = _toConsumableArray(state.info);

      if (action.payload != null) {
        var index = info.findIndex(function (i) {
          return i.userId == action.payload.userId;
        });

        if (index != -1) {
          info.splice(index, 1, action.payload);
        } else {
          info.push(action.payload);
        }
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        info: info
      });

    case GET_USER_GUARDIAN:
      return _objectSpread(_objectSpread({}, state), {}, {
        guardians: action.payload
      });

    case GET_GUARDIAN_BY_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        guardian: action.payload
      });

    case SET_GUARDIAN_BY_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        guardian: action.payload
      });

    case GET_GUARDIAN_BY_EMAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        searchGuardian: action.payload
      });

    case ADD_USER_GUARDIAN:
      var guardians = _toConsumableArray(state.guardians);

      guardians.push(action.payload);
      return _objectSpread(_objectSpread({}, state), {}, {
        guardians: guardians
      });

    case LINK_STUDENT_GUARDIAN:
      guardians = _toConsumableArray(state.guardians);
      guardians.push(action.payload);
      return _objectSpread(_objectSpread({}, state), {}, {
        guardians: guardians
      });

    case USER_GET_USER_ABSENCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        absence: action.payload
      });

    case USER_GET_MENTOR_SECTION:
      return _objectSpread(_objectSpread({}, state), {}, {
        userMentorSections: action.payload
      });

    case GET_READER_PARAMS:
      return _objectSpread(_objectSpread({}, state), {}, {
        reader: action.payload
      });

    case GET_USER_COURSES:
      return _objectSpread(_objectSpread({}, state), {}, {
        userCourses: action.payload
      });

    case GET_USER_SUBJECTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        userSubjects: action.payload
      });

    case UPDATE_USER:
      info = _toConsumableArray(state.info);

      if (action.payload != null) {
        var _index = info.findIndex(function (i) {
          return i.userId == action.payload.userId;
        });

        if (_index == -1) {
          info.push(action.payload);
        }
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        info: info
      });

    case GET_USER_FILES:
      return _objectSpread(_objectSpread({}, state), {}, {
        files: action.payload
      });

    case DELETE_USER_FILE:
      return _objectSpread(_objectSpread({}, state), {}, {
        files: _toConsumableArray(state.files).filter(function (file) {
          return file.id != action.payload;
        })
      });

    case GET_TEACHER_ROLES:
      return _objectSpread(_objectSpread({}, state), {}, {
        teacherRoles: action.payload
      });

    case DELETE_TEACHER_ROLE:
      return _objectSpread(_objectSpread({}, state), {}, {
        teacherRoles: action.payload
      });

    case ADD_TEACHER_ROLE:
      var teacherRoles = [];

      if (state.teacherRoles != null) {
        teacherRoles = _toConsumableArray(state.teacherRoles);
      }

      if (action.payload != null) {
        var _index2 = teacherRoles.findIndex(function (i) {
          return i.subjectCode == action.payload.subjectCode;
        });

        if (_index2 != -1) {
          teacherRoles.splice(_index2, 1, action.payload);
        } else {
          teacherRoles.push(action.payload);
        }
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        teacherRoles: teacherRoles
      });

    case GET_USER_SECTIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        sections: action.payload
      });

    case GET_ALL_USER_SECTIONS:
      if (action.payload != null) {
        if (action.payload.pageIndex > 1) {
          return _objectSpread(_objectSpread({}, state), {}, {
            userSections: [].concat(_toConsumableArray(state.userSections), _toConsumableArray(action.payload.documents))
          });
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          userSections: _toConsumableArray(action.payload.documents)
        });
      }

      return state;

    case GET_MY_MENTOR_SECTIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        sections: action.payload
      });

    case GET_USERS_WITHOUT_MENTOR_GROUPS:
      return _objectSpread(_objectSpread({}, state), {}, {
        usersWithoutMentorGroups: action.payload
      });

    case GET_MY_SECTIONS:
      if (action.payload != null) {
        if (action.payload.pageIndex > 1) {
          return _objectSpread(_objectSpread({}, state), {}, {
            mySections: [].concat(_toConsumableArray(state.mySections), _toConsumableArray(action.payload.documents))
          });
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          mySections: _toConsumableArray(action.payload.documents)
        });
      }

      return state;

    case GET_ALL_SECTIONS:
      if (action.payload != null) {
        if (action.payload.pageIndex > 1) {
          return _objectSpread(_objectSpread({}, state), {}, {
            mySections: [].concat(_toConsumableArray(state.mySections), _toConsumableArray(action.payload.documents))
          });
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          mySections: _toConsumableArray(action.payload.documents)
        });
      }

      return state;

    case SET_ARCHIVING_SECTION:
      if (state.mySections != null) {
        var newMySections = JSON.parse(JSON.stringify(state.mySections));
        var foundGroupIndex = newMySections.findIndex(function (g) {
          return g.id == action.payload.id;
        });

        if (foundGroupIndex == -1) {
          return state;
        }

        newMySections.splice(foundGroupIndex, 1, action.payload);
        return _objectSpread(_objectSpread({}, state), {}, {
          mySections: newMySections
        });
      }

      return state;

    default:
      return state;
  }
}