import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import Assignments from './reducer_assignments';
import Status from './reducer_status';
import Planning from './reducer_plans';
import Sections from './reducer_sections';
import Schedule from './reducer_schedule';
import Notifications from './reducer_notifications';
import Knowledge from './reducer_knowledge';
import User from './reducer_user';
import Errors from './reducer_errors';
import DetailsTable from './reducer_detailstable';
import Assessments from './reducer_assessments';
import Progress from './reducer_progress';
import Preschoolgoal from './reducer_preschoolgoal';
import Posts from './reducer_posts';
import Documents from './reducer_documents';
import CombinedEvents from './reducer_combined_events';
import Matrix from './reducer_matrix';
import Courses from './reducer_courses';
import OneDrive from './reducer_onedrive';
import Logs from './reducer_logs';
import Absence from './reducer_absence';
import School from './reducer_schools';
import Search from './reducer_search';
import Header from './reducer_header';
import Verdicts from './reducer_verdicts';
import Conversation from './reducer_conversation';
import ConversationAdmin from './reducer_conversations_admin';
import Templates from './reducer_templates';
import Meetings from './reducer_meetings';
import Notes from './reducer_notes';
import Menu from './reducer_menu';
import AdditionalAdjustments from './reducer_adjustments';
import TimeBlocks from './reducer_timeblocks';
import BaseGroups from './reducer_basegroups';
import Bookmarks from './reducer_bookmarks';
import Plagiarism from './reducer_plagiarism';
import Languages from './reducer_languages';
import Services from './reducer_services';
import Blocks from './reducer_blocks';
import Subjects from './reducer_subjects';
import SMS from './reducer_sms';
import ScheduleFilter from './reducer_scheduleFilter';
import InformationTexts from './reducer_information_texts';
import Tenant from './reducer_tenants';
var rootReducer = combineReducers({
  form: reduxFormReducer,
  assignments: Assignments,
  status: Status,
  planning: Planning,
  sections: Sections,
  schedule: Schedule,
  Notifications: Notifications,
  knowledge: Knowledge,
  user: User,
  errors: Errors,
  detailsTable: DetailsTable,
  assessments: Assessments,
  progress: Progress,
  PreschoolGoals: Preschoolgoal,
  Posts: Posts,
  Documents: Documents,
  CombinedEvents: CombinedEvents,
  Matrix: Matrix,
  Courses: Courses,
  onedrive: OneDrive,
  Logs: Logs,
  Absence: Absence,
  School: School,
  Search: Search,
  Header: Header,
  Verdicts: Verdicts,
  Conversation: Conversation,
  ConversationAdmin: ConversationAdmin,
  Templates: Templates,
  Meetings: Meetings,
  Notes: Notes,
  Menu: Menu,
  AdditionalAdjustments: AdditionalAdjustments,
  TimeBlocks: TimeBlocks,
  BaseGroups: BaseGroups,
  Bookmarks: Bookmarks,
  Plagiarism: Plagiarism,
  Languages: Languages,
  Services: Services,
  Subjects: Subjects,
  Blocks: Blocks,
  SMS: SMS,
  ScheduleFilter: ScheduleFilter,
  InformationTexts: InformationTexts,
  Tenant: Tenant
});
export default rootReducer;