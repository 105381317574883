import api from 'lib/api';
export var GET_WELCOME_INFORMATION_TEXT = 'INFORMATION_TEXT_GET_WELCOME_TEXT';
export var UPDATE_INFORMATION_TEXT_READ = 'INFORMATION_TEXT_UPDATE_INFORMATION_TEXT_READ';
export var HIDE_WELCOME_MODAL = 'HIDE_WELCOME_MODAL';
export var getWelcomeInformation = function getWelcomeInformation() {
  return {
    type: GET_WELCOME_INFORMATION_TEXT,
    payload: new Promise(function (resolve) {
      api.get('informationTexts?messageType=welcome').then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var updateInformationTextRead = function updateInformationTextRead(values) {
  return {
    type: UPDATE_INFORMATION_TEXT_READ,
    payload: new Promise(function (resolve) {
      api.put("informationTexts/contentread", values).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var hideWelcomeWindow = function hideWelcomeWindow() {
  return {
    type: HIDE_WELCOME_MODAL
  };
};