import api from 'lib/api';
export var GET_BASE_GROUP = 'BASE_GROUPS_GET_BASE_GROUP';
export var GET_BASE_GROUPS = 'BASE_GROUPS_GET_BASE_GROUPS';
export var CREATE_BASE_GROUP = 'BASE_GROUPS_CREATE_BASE_GROUPS';
export var UPDATE_BASE_GROUP = 'BASE_GROUPS_UPDATE_BASE_GROUP';
export var DELETE_BASE_GROUP = 'BASE_GROUPS_DELETE_BASE_GROUP';
export var CLEAR_ACTIVE_BASE_GROUP = 'BASE_GROUPS_CLEAR_ACTIVE';
export var getBaseGroup = function getBaseGroup(baseGroupId) {
  return {
    type: GET_BASE_GROUP,
    payload: new Promise(function (resolve) {
      api.get("basegroups/".concat(baseGroupId)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var setActiveBaseGroup = function setActiveBaseGroup(basegroup) {
  return {
    type: GET_BASE_GROUP,
    payload: new Promise(function (resolve) {
      resolve(basegroup);
    })
  };
};
export var getBaseGroups = function getBaseGroups(groupId) {
  return {
    type: GET_BASE_GROUPS,
    payload: new Promise(function (resolve) {
      api.get("basegroups/section/".concat(groupId)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var createBaseGroup = function createBaseGroup(values) {
  return {
    type: CREATE_BASE_GROUP,
    payload: new Promise(function (resolve) {
      api.post('basegroups', values).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var updateBaseGroup = function updateBaseGroup(values) {
  return {
    type: UPDATE_BASE_GROUP,
    payload: new Promise(function (resolve) {
      api.put("basegroups", values).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var deleteBaseGroup = function deleteBaseGroup(baseGroupId) {
  return {
    type: DELETE_BASE_GROUP,
    payload: new Promise(function (resolve) {
      api["delete"]("basegroups/".concat(baseGroupId)).then(function () {
        resolve(baseGroupId);
      });
    })
  };
};
export var clearActiveBaseGroup = function clearActiveBaseGroup() {
  return {
    type: CLEAR_ACTIVE_BASE_GROUP,
    payload: true
  };
};