import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { CREATE_NOTE, GET_NOTES_BY_REFERENCE, GET_MENTOR_NOTES_BY_REFERENCE, RESET_ACTIVE_NOTE, SET_ACTIVE_NOTE, REMOVE_NOTE_BY_ID } from 'actions/notes';
var INITIAL_STATE = {
  notes: [],
  mentorNotes: [],
  active: null,
  "new": null
};

function removeNote() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var id = arguments.length > 1 ? arguments[1] : undefined;

  if (id == null) {
    return state;
  }

  var newState = _toConsumableArray(state);

  newState = newState.filter(function (note) {
    return note.id != id;
  });
  return newState;
}

export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case GET_NOTES_BY_REFERENCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        notes: action.payload
      });

    case GET_MENTOR_NOTES_BY_REFERENCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        mentorNotes: action.payload
      });

    case SET_ACTIVE_NOTE:
      return _objectSpread(_objectSpread({}, state), {}, {
        active: action.payload
      });

    case RESET_ACTIVE_NOTE:
      return _objectSpread(_objectSpread({}, state), {}, {
        active: INITIAL_STATE.active
      });

    case CREATE_NOTE:
      return _objectSpread(_objectSpread({}, state), {}, {
        "new": action.payload
      });

    case REMOVE_NOTE_BY_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        mentorNotes: removeNote(state.mentorNotes, action.payload),
        notes: removeNote(state.notes, action.payload)
      });

    default:
      return state;
  }
}
;