import { http } from 'lib/msal';
import getEnvironment from 'lib/env';
var api = {
  get: function get(route, useBigAzure) {
    if (useBigAzure) {
      var _getEnvironment;

      return http.get(route, {
        baseURL: (_getEnvironment = getEnvironment()) === null || _getEnvironment === void 0 ? void 0 : _getEnvironment.baseURL
      });
    }

    return http.get(route);
  },
  put: function put(route, data) {
    return http.put(route, data);
  },
  post: function post(route, data) {
    return http.post(route, data, {
      headers: {
        'content-type': 'application/json'
      }
    });
  },
  "delete": function _delete(route, data) {
    return http["delete"](route, {
      data: data != null ? data : null
    });
  },
  patch: function patch(route, data, operations) {
    return new Promise(function (resolve) {
      http.patch(route, operations).then(function (response) {
        resolve(response);
      });
    });
  },
  getFile: function getFile(id) {
    return http.get("fileItems/".concat(id), {
      responseType: 'blob'
    });
  },
  getOneDriveFile: function getOneDriveFile(route, token) {
    return http.get(route, {
      headers: {
        'Authorization': "Bearer ".concat(token)
      }
    });
  }
};
export default api;