import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { GET_FORM_TEMPLATES, GET_FORM_TEMPLATE, GET_MY_FORMS, UPDATE_FORM_TEMPLATE, CLEAR_FORM_TEMPLATE, GET_FORM, GET_FORMS, GET_FORM_INSTANCE, CLEAR_FORM, CREATE_FORM_INSTANCE } from 'actions/templates';
var INITIAL_STATE = {
  templates: null,
  formTemplate: null,
  form: null,
  forms: null,
  instance: null,
  createdForm: 0
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case GET_FORM_TEMPLATES:
      return _objectSpread(_objectSpread({}, state), {}, {
        templates: action.payload
      });

    case GET_FORM_TEMPLATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        formTemplate: action.payload
      });

    case UPDATE_FORM_TEMPLATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        formTemplate: action.payload
      });

    case CLEAR_FORM_TEMPLATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        formTemplate: INITIAL_STATE.formTemplate
      });

    case GET_FORM:
      return _objectSpread(_objectSpread({}, state), {}, {
        form: action.payload
      });

    case CLEAR_FORM:
      return _objectSpread(_objectSpread({}, state), {}, {
        form: INITIAL_STATE.form,
        formTemplate: INITIAL_STATE.formTemplate
      });

    case GET_FORMS:
      return _objectSpread(_objectSpread({}, state), {}, {
        forms: action.payload
      });

    case GET_FORM_INSTANCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        instance: action.payload
      });

    case GET_MY_FORMS:
      return _objectSpread(_objectSpread({}, state), {}, {
        myForms: action.payload
      });

    case CREATE_FORM_INSTANCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        createdForm: action.payload.id,
        instance: action.payload
      });

    default:
      return state;
  }
}