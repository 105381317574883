import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { GET_SMS, GET_SMS_LIST, CREATE_SMS, UPDATE_SMS, GET_REMAINING_SMS, GET_SMS_STATISTICS, GET_SMS_EDUCATION_SECTIONS, GET_SMS_MENTOR_SECTIONS } from 'actions/sms';
var INITIAL_STATE = {
  single: null,
  list: null,
  remaining: null,
  limit: null,
  statistics: null,
  educationSections: null,
  mentorSections: null
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case GET_SMS:
      return _objectSpread(_objectSpread({}, state), {}, {
        single: action.payload
      });

    case GET_SMS_LIST:
      return _objectSpread(_objectSpread({}, state), {}, {
        list: action.payload
      });

    case CREATE_SMS:
      if (state.list == null) {
        return _objectSpread(_objectSpread({}, state), {}, {
          list: [action.payload]
        });
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        list: [action.payload].concat(_toConsumableArray(state.list))
      });

    case UPDATE_SMS:
      if (state.list != null) {
        // update sms in list as well
        return _objectSpread(_objectSpread({}, state), {}, {
          single: action.payload,
          list: [].concat(_toConsumableArray(state.list.filter(function (t) {
            return t.id != action.payload.id;
          })), [action.payload])
        });
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        single: action.payload
      });

    case GET_REMAINING_SMS:
      if (action.payload != null) {
        return _objectSpread(_objectSpread({}, state), {}, {
          remaining: action.payload.remainingAmount,
          limit: action.payload.totalAmount * action.payload.limit
        });
      }

      return state;

    case GET_SMS_STATISTICS:
      return _objectSpread(_objectSpread({}, state), {}, {
        statistics: action.payload
      });

    case GET_SMS_EDUCATION_SECTIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        educationSections: action.payload
      });

    case GET_SMS_MENTOR_SECTIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        mentorSections: action.payload
      });

    default:
      return state;
  }
}