import api from 'lib/api';
export var GET_VERDICT_SCALE = 'VERDICTS_VERDICT_SCALE';
export var GET_USER_VERDICTS = 'VERDICTS_GET_USER_VERDICTS';
export var SUBMIT_VERDICT = 'VERDICTS_POST_VERDICT';
export var UPDATE_VERDICT = 'VERDICTS_PUT_VERDICT';
export var GET_PLAN_ASSESSMENTS = 'VERDICTS_GET_ASSESSMENTS_PLANS';
export var GET_ASSIGNMENT_ASSESSMENTS = 'VERDICTS_GET_ASSESSMENTS_ASSIGNMENT';
export var GET_SECTION_VERDICTS = 'VERDICTS_GET_SECTION_VERDICTS';
export var DUPLICATE_STUDENT_VERDICT = 'VERDICTS_DUPLICATE_STUDENT_VERDICT';
export var DUPLICATE_STUDENTS_VERDICT = 'VERDICTS_DUPLICATE_MULTIPLE_STUDENTS_VERDICT';
export var GET_STUDY_PLAN = 'VERDICTS_GET_STUDY_PLAN_FOR_USER';
export var GET_STUDENT_LAST_VERDICT = 'VERDICTS_GET_STUDENT_LATEST_VERDICT';
export var CLEAR_VERDICTS = 'VERDICTS_CLEAR_VERDICTS';
export var CLEAR_VERDCIT_SCALE = 'VERDICTS_CLEAR_VERDCIT_SCALE';
export var REQUEST_STUDENT_VERDICT = 'VERDICTS_REQUEST_STUDENT_VERDICT';
export var DELETE_VERDICT = 'VERDICTS_DELETE_VERDICT';
export var SUBMIT_BULK_VERDICTS = 'VERDICTS_SUBMIT_BULK_VERDICTS';
export var GET_VERDICTS_FOR_STUDENTS_IN_SECTION = 'VERDICTS_GET_VERDICTS_FOR_STUDENTS_IN_SECTION';
export var GET_STUDENT_COURSES = 'GET_STUDENT_COURSES';
export var RESET_PLAN_ASSESSMENTS = 'RESET_PLAN_ASSESSMENTS';
export var RESET_ASSIGNMENT_ASSESSMENTS = 'RESET_ASSIGNMENT_ASSESSMENTS';
export var getVerdictScale = function getVerdictScale() {
  return {
    type: GET_VERDICT_SCALE,
    payload: new Promise(function (resolve) {
      api.get("verdict/scale").then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getVerdictsForUser = function getVerdictsForUser(userId, courseId, start, end) {
  return {
    type: GET_USER_VERDICTS,
    payload: new Promise(function (resolve) {
      var url = "users/".concat(userId, "/verdicts/course/").concat(courseId, "?_=1");

      if (start != null) {
        url += "&start=".concat(start);
      }

      if (end != null) {
        url += "&end=".concat(end);
      }

      api.get(url).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var createVerdict = function createVerdict(values) {
  return {
    type: SUBMIT_VERDICT,
    payload: new Promise(function (resolve) {
      api.post("verdict", values).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var updateVerdict = function updateVerdict(values) {
  return {
    type: SUBMIT_VERDICT,
    payload: new Promise(function (resolve) {
      api.put("verdict", values).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getPlanAssessments = function getPlanAssessments(user, courseId, start, end) {
  return {
    type: GET_PLAN_ASSESSMENTS,
    payload: new Promise(function (resolve) {
      var url = "assessments/".concat(user, "/course/").concat(courseId, "/plans?");

      if (start != null && end != null) {
        url = url + "startDate=".concat(start, "&endDate=").concat(end);
      }

      api.get(url).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var resetPlanAssessments = function resetPlanAssessments() {
  return {
    type: RESET_PLAN_ASSESSMENTS,
    payload: []
  };
};
export var getAssignmentAssessments = function getAssignmentAssessments(user, courseId, start, end) {
  return {
    type: GET_ASSIGNMENT_ASSESSMENTS,
    payload: new Promise(function (resolve) {
      var url = "assessments/".concat(user, "/course/").concat(courseId, "/assignments?");

      if (start != null && end != null) {
        url = url + "startDate=".concat(start, "&endDate=").concat(end);
      }

      api.get(url).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var resetAssignmentAssessments = function resetAssignmentAssessments() {
  return {
    type: RESET_ASSIGNMENT_ASSESSMENTS,
    payload: []
  };
};
export var getSectionVerdicts = function getSectionVerdicts(sectionId, courseId) {
  return {
    type: GET_SECTION_VERDICTS,
    payload: new Promise(function (resolve) {
      api.get("sections/".concat(sectionId, "/verdicts/course/").concat(courseId)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var duplicateStudentVerdict = function duplicateStudentVerdict(verdictId) {
  return {
    type: DUPLICATE_STUDENT_VERDICT,
    payload: new Promise(function (resolve) {
      var data = [verdictId];
      api.post("verdict/duplicate", data).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var duplicateStudentsVerdict = function duplicateStudentsVerdict(ids) {
  return {
    type: DUPLICATE_STUDENTS_VERDICT,
    payload: new Promise(function (resolve) {
      api.post("verdict/duplicate", ids).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getUserStudyPlan = function getUserStudyPlan(studentId) {
  return {
    type: GET_STUDY_PLAN,
    payload: new Promise(function (resolve) {
      api.get("users/".concat(studentId, "/studyplans")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getStudentVerdicts = function getStudentVerdicts(studentId, from, to) {
  return {
    type: GET_STUDENT_COURSES,
    payload: new Promise(function (resolve) {
      api.get("users/".concat(studentId, "/verdicts?from=").concat(from, "&to=").concat(to)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getStudentLatestVerdict = function getStudentLatestVerdict(studentId, courseId) {
  return {
    type: GET_STUDENT_LAST_VERDICT,
    payload: new Promise(function (resolve) {
      api.get("users/".concat(studentId, "/verdicts/course/").concat(courseId)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var deleteVerdict = function deleteVerdict(id) {
  return {
    type: DELETE_VERDICT,
    payload: new Promise(function (resolve) {
      api["delete"]("verdict/".concat(id)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var clearVerdicts = function clearVerdicts() {
  return {
    type: CLEAR_VERDICTS,
    payload: null
  };
};
export var clearVerdictScale = function clearVerdictScale() {
  return {
    type: CLEAR_VERDCIT_SCALE,
    payload: null
  };
};
export var requestVerdictForUser = function requestVerdictForUser(user, courseId) {
  return {
    type: REQUEST_STUDENT_VERDICT,
    payload: new Promise(function (resolve, reject) {
      api.post("verdict/notify?studentId=".concat(user, "&courseId=").concat(courseId), {}).then(function (response) {
        // TODO: Handle error here
        if (false) {
          console.log('action notify reject');
          reject(1);
          return;
        }

        resolve(response.data);
      });
    })
  };
};
export var submitBulkVerdicts = function submitBulkVerdicts(values) {
  return {
    type: SUBMIT_BULK_VERDICTS,
    payload: new Promise(function (resolve, reject) {
      api.post("verdict/bulk", values).then(function (response) {
        // TODO: Handle error here
        if (false) {
          reject(1);
          return;
        }

        resolve(response.data);
      });
    })
  };
};
export var getVerdictsForStudentsInSection = function getVerdictsForStudentsInSection(students, sectionId, start, end) {
  return {
    type: GET_VERDICTS_FOR_STUDENTS_IN_SECTION,
    payload: new Promise(function (resolve) {
      var data = JSON.stringify(students);
      api.post("sections/".concat(sectionId, "/verdicts?startDate=").concat(start, "&endDate=").concat(end), students).then(function (response) {
        resolve(response.data);
      });
    })
  };
};