import api from 'lib/api';
export var GET_DIRECTORY_CONTENTS = 'GET_DIRECTORY_CONTENTS';
export var SET_ACTIVE_DIRECTORY = 'SET_ACTIVE_DIRECTORY';
export var SET_SAVED_DIR_AS_ACTIVE = 'SET_SAVED_DIR_AS_ACTIVE';
export var GET_SECTION_DRIVES = 'GET_SECTION_DRIVES';
export var GET_SECTION_CONTENT = 'GET_SECTION_CONTENT';
export var getDirectoryContents = function getDirectoryContents(id, callback) {
  return [{
    type: SET_ACTIVE_DIRECTORY,
    folder: id
  }, {
    type: GET_DIRECTORY_CONTENTS,
    payload: new Promise(function (resolve) {
      var url = 'users/me/onedrivefiles';

      if (typeof id !== 'undefined' && id !== null) {
        url = "users/me/onedrivefiles/folder/".concat(id);
      }

      api.get(url).then(function (response) {
        resolve(response.data);

        if (callback) {
          callback(response.data);
        }
      });
    })
  }];
};
export var getDrivesForSection = function getDrivesForSection(id, callback) {
  return [{
    type: GET_SECTION_DRIVES,
    payload: null
  }, {
    type: GET_SECTION_DRIVES,
    payload: new Promise(function (resolve) {
      api.get("sections/".concat(id, "/drives")).then(function (response) {
        resolve(response.data);

        if (callback) {
          callback(response.data);
        }
      });
    })
  }];
};
export var getDriveSectionContent = function getDriveSectionContent(section, drive, folder, callback) {
  return [{
    type: GET_SECTION_CONTENT,
    payload: null
  }, {
    type: SET_ACTIVE_DIRECTORY,
    folder: folder ? folder : drive ? drive : null,
    root: folder ? false : true
  }, {
    type: GET_SECTION_CONTENT,
    payload: new Promise(function (resolve) {
      var url = "sections/".concat(section, "/drives/").concat(drive);

      if (typeof folder !== 'undefined' && folder !== null) {
        url = "sections/".concat(section, "/drives/").concat(drive, "/folder/").concat(folder);
      }

      api.get(url).then(function (response) {
        if (response.data.id == 'root') {
          if (folder) {
            response.data.id = folder;
          } else if (drive) {
            response.data.id = drive;
          } else {
            response.data.id = section;
          }
        }

        resolve(response.data);

        if (callback) {
          callback(response.data);
        }
      });
    })
  }];
};
export var setDirectoryActive = function setDirectoryActive(id, callback) {
  return [{
    type: SET_ACTIVE_DIRECTORY,
    folder: id
  }, {
    type: SET_SAVED_DIR_AS_ACTIVE,
    folder: id
  }];
};
export var setSectionContentActive = function setSectionContentActive(drive, folder) {
  return [{
    type: SET_ACTIVE_DIRECTORY,
    folder: folder ? folder : drive ? drive : null,
    root: folder ? false : true
  }, {
    type: SET_SAVED_DIR_AS_ACTIVE,
    folder: folder ? folder : drive ? drive : null
  }];
};