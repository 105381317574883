import api from 'lib/api';
export var GROUP_INFO = 'GROUP_INFO';
export var USER_INFO_BY_ID = 'USER_INFO_BY_ID';
export var getUserInfoById = function getUserInfoById(userid, callback) {
  return {
    type: USER_INFO_BY_ID,
    payload: new Promise(function (resolve) {
      api.get("users/".concat(userid, "/")).then(function (response) {
        resolve(response.data);
        callback(response.data);
      });
    })
  };
};
export var getGroupInfoById = function getGroupInfoById(id, callback) {
  return {
    type: GROUP_INFO,
    payload: new Promise(function (resolve) {
      api.get("groups/".concat(id, "/")).then(function (response) {
        resolve(response.data);
        callback(response.data);
      });
    })
  };
};