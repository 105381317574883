import api from 'lib/api';
import axios from 'axios';
import { http } from 'lib/msal';
export var GET_SERVICES = 'GET_SERVICES';
export var SET_SELECTED_SERVICES = 'SET_SELECTED_SERVICES';
export var POST_CREATE_TENANT = 'POST_CREATE_TENANT';
export var POST_CREATE_TENANT_SERVICES = 'POST_CREATE_TENANT_SERVICES';
export var PUT_UPDATE_TENANT_SERVICES = 'PUT_UPDATE_TENANT_SERVICES';
export var PUT_UPDATE_TENANT_SERVICE_CONSENT = 'PUT_UPDATE_TENANT_SERVICE_CONSENT';
export var POST_INVITE_ADMIN = 'POST_INVITE_ADMIN';
export var GET_CONSENT_INFO = 'GET_CONSENT_INFO';
export var GET_ADMIN_INVITATIONS = 'GET_ADMIN_INVITATIONS';
export var GET_CONSENT_PRESENT = 'GET_CONSENT_PRESENT';
export var POST_SELECTED_PRODUCTS_NOTES = 'POST_SELECTED_PRODUCTS_NOTES';
export var SET_EMAIL_SENT_LIST = 'SET_EMAIL_SENT_LIST';
export var PUT_UPDATE_TENANT_PRODUCTS = 'PUT_UPDATE_TENANT_PRODUCTS';
export var GET_TENANT_PRODUCTS = 'GET_TENANT_PRODUCTS';
export var PUT_UPDATE_TENANT = 'PUT_UPDATE_TENANT';
export var getServices = function getServices() {
  return {
    type: GET_SERVICES,
    payload: new Promise(function (resolve) {
      api.get("services/products").then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getTenantProducts = function getTenantProducts() {
  return {
    type: GET_TENANT_PRODUCTS,
    payload: new Promise(function (resolve) {
      api.get("tenants/products").then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getConsentInfo = function getConsentInfo() {
  return {
    type: GET_CONSENT_INFO,
    payload: new Promise(function (resolve) {
      api.get("services/consents").then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getAdminInvitations = function getAdminInvitations() {
  return {
    type: GET_ADMIN_INVITATIONS,
    payload: new Promise(function (resolve) {
      api.get("services/admin/invitations").then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var isConsentPresent = function isConsentPresent(tenantId) {
  return {
    type: GET_CONSENT_PRESENT,
    payload: new Promise(function (resolve, reject) {
      axios.get("".concat(http.defaults.baseURL, "services/tenant/").concat(tenantId)).then(function (response) {
        if (response.data == null) {
          reject(false);
          return;
        }

        if (response.status == 200) {
          resolve(response.data);
          return;
        }

        reject(false);
      })["catch"](function (error) {
        reject(false);
      });
    })
  };
};
export var saveSelectedProductsAsNotes = function saveSelectedProductsAsNotes() {
  var onTenantLevel = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  return {
    type: POST_SELECTED_PRODUCTS_NOTES,
    payload: new Promise(function (resolve, reject) {
      api.post("services/products/saveAsNote?onTenantLevel=" + onTenantLevel, null).then(function (response) {
        resolve(response.status);
      });
    })
  };
};
export var sendAdminInvitation = function sendAdminInvitation(recipients) {
  return {
    type: POST_INVITE_ADMIN,
    payload: new Promise(function (resolve, reject) {
      api.post("services/admin/invite", recipients).then(function (response) {
        resolve(response.status);
      })["catch"](function (error) {
        if (error.response) {
          resolve(error.response.status);
        }

        resolve(500);
      });
    })
  };
};
export var setEmailSentList = function setEmailSentList(recipients) {
  return {
    type: SET_EMAIL_SENT_LIST,
    payload: recipients
  };
};
export var createTenant = function createTenant(tenant) {
  var preOnboarding = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return {
    type: POST_CREATE_TENANT,
    payload: new Promise(function (resolve) {
      api.post("tenants?preOnboarding=" + preOnboarding, tenant).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var updateTenant = function updateTenant(tenant) {
  return {
    type: PUT_UPDATE_TENANT,
    payload: new Promise(function (resolve) {
      api.put("tenants", tenant).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var createTenantServices = function createTenantServices(tenantServices) {
  return {
    type: POST_CREATE_TENANT_SERVICES,
    payload: new Promise(function (resolve) {
      api.post("tenants/services", tenantServices).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var updateTenantServices = function updateTenantServices(tenantServices) {
  return {
    type: PUT_UPDATE_TENANT_SERVICES,
    payload: new Promise(function (resolve) {
      api.put("tenants/services", tenantServices).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var updateTenantProducts = function updateTenantProducts(products) {
  return {
    type: PUT_UPDATE_TENANT_PRODUCTS,
    payload: new Promise(function (resolve) {
      api.put("tenants/products", products).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var updateTenantServiceConsent = function updateTenantServiceConsent(tenantServices) {
  return {
    type: PUT_UPDATE_TENANT_SERVICE_CONSENT,
    payload: new Promise(function (resolve) {
      api.put("tenants/service/consent", tenantServices).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var setSelectedServices = function setSelectedServices(selectedServices) {
  return {
    type: SET_SELECTED_SERVICES,
    payload: new Promise(function (resolve) {
      resolve(selectedServices);
    })
  };
};