import _typeof from "@babel/runtime/helpers/typeof";

var getObjectKeys = function getObjectKeys() {
  var keys = [];

  for (var _len = arguments.length, objects = new Array(_len), _key = 0; _key < _len; _key++) {
    objects[_key] = arguments[_key];
  }

  objects.forEach(function (obj) {
    Object.keys(obj).forEach(function (key) {
      if (keys.indexOf(key) == -1) {
        keys.push(key);
      }
    });
  });
  return keys;
};

var isPropertyDifferent = function isPropertyDifferent(a, b, property) {
  return a[property] != b[property];
};

var isObjectDifferent = function isObjectDifferent(a, b) {
  if (_typeof(a) != 'object' || _typeof(b) != 'object') {
    // One of the provided values are not objects, cannot compare
    return false;
  }

  var result = false;
  var keys = getObjectKeys(a, b);
  keys.forEach(function (key) {
    // TODO: Figure out what tyhis does
    // if (typeof a[key] == 'object' && typeof b[key] == 'object' && a[key] != null && b[key] != null) {
    // 	return isObjectDifferent(a[key], b[key]);
    // }
    if (isPropertyDifferent(a, b, key)) {
      result = true;
    }
  });
  return result;
};

export var get_operations = function get_operations(old, changes) {
  var a = JSON.parse(JSON.stringify(old));
  var b = JSON.parse(JSON.stringify(changes));
  var operations = [];
  var keys = getObjectKeys(a, b);
  var changedKeys = [];
  keys.forEach(function (key) {
    var inOld = Object.keys(a).find(function (oldKey) {
      return oldKey == key;
    }) != null;
    var inNew = Object.keys(b).find(function (newKey) {
      return newKey == key;
    }) != null;

    if (inOld && inNew) {
      switch (_typeof(a[key])) {
        case 'number':
        case 'string':
          if (isPropertyDifferent(a, b, key)) {
            changedKeys.push(key);
          }

          break;

        case 'object':
          if (a[key] == null || b[key] == null) {
            if (isPropertyDifferent(a, b, key)) {
              changedKeys.push(key);
            }

            break;
          }

          if (a[key].length != null) {
            // Is array, check for new or changed items
            b[key].forEach(function (item) {
              if (item.id == null) {
                // New item (no id)
                operations.push({
                  op: 'add',
                  path: '/' + key,
                  value: item
                });
                return true;
              }

              var foundInA = a[key].find(function (aItem) {
                return aItem.id == item.id;
              });

              if (foundInA != null) {
                if (isObjectDifferent(item, foundInA)) {
                  operations.push({
                    op: 'replace',
                    path: '/' + key,
                    value: item
                  });
                }
              }
            }); // Check for removed items

            a[key].forEach(function (item) {
              var foundInB = b[key].find(function (bItem) {
                return bItem.id == item.id;
              });

              if (foundInB == null) {
                operations.push({
                  op: 'remove',
                  path: '/' + key,
                  value: item
                });
              }
            });
            break;
          }

          if (isObjectDifferent(a, b)) {
            operations.push({
              op: 'replace',
              path: '/' + key,
              value: b
            });
          }

        default:
          break;
      }

      return true;
    }

    changedKeys.push(key);
  });

  if (changedKeys.length > 0) {
    var value = {};
    changedKeys.forEach(function (key) {
      value[key] = b[key];
    });
    operations.unshift({
      op: 'replace',
      path: '/',
      value: value
    });
  }

  return operations;
};