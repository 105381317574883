import axios from 'axios';
import moment from 'moment';
import { http } from 'lib/msal';
import { saveWithExpiration, getWithExpiration } from 'helpers/localstorage';
export var REGISTER_LANGUAGES = 'LANGUAGES_REGISTER_LANGUAGES';
export var GET_TRANSLATIONS_FOR_LANGUAGE = 'LANGUAGES_GET_TRANSLATIONS_FOR_LANGUAGE';
export var LOAD_TRANSLATIONS_FILE = 'LANGUAGES_LOAD_TRANSLATIONS_FILE';
export var registerLanguages = function registerLanguages() {
  return {
    type: REGISTER_LANGUAGES,
    payload: new Promise(function (resolve) {
      var languages = ['sv-se', 'en-us', 'da-dk', 'nb-no'];
      resolve(languages);
    })
  };
};
export var getTranslations = function getTranslations(lcid) {
  return {
    type: GET_TRANSLATIONS_FOR_LANGUAGE,
    payload: new Promise(function (resolve) {
      if (getWithExpiration("localizations-".concat(lcid)) != null) {
        resolve(JSON.parse(getWithExpiration("localizations-".concat(lcid))));
        return true;
      }

      axios.get("".concat(http.defaults.baseURL, "localization/").concat(lcid, "?service=HALDOR_EDUCATION")).then(function (response) {
        if (response.status == 200) {
          saveWithExpiration("localizations-".concat(lcid), JSON.stringify(response.data), moment().add('8', 'hours'));
          resolve(response.data);
          return;
        }

        resolve([]);
      })["catch"](function () {
        resolve([]);
      });
    })
  };
};