import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { GET_ADDITIONAL_ADJUSTMENTS, GET_STUDENT_ADJUSTMENTS, ADD_ADJUSTMENT_COMMENT, GET_SCHOOL_ADJUSTMENTS, CREATE_ADDITIONAL_ADJUSTMENT, SWITCH_ACTIVE_ADDITIONAL_ADJUSTMENT, UPDATE_ADDITIONAL_ADJUSTMENT, CREATE_STUDENT_ADJUSTMENT, UPDATE_STUDENT_ADJUSTMENT, DELETE_ADDITIONAL_ADJUSTMENT, DELETE_STUDENT_ADDITIONAL_ADJUSTMENT, DELETE_STUDENT_ADDITIONAL_ADJUSTMENT_COMMENT, UPDATE_STUDENT_ADDITIONAL_ADJUSTMENT_COMMENT, GET_STUDENT_ADJUSTMENTS_BY_COURSE, GET_STUDENT_ADJUSTMENTS_BY_SUBJECT } from 'actions/additional_adjustments';
import { GET_GROUP_STUDENT_ADJUSTMENTS, GET_GROUP_STUDENT_ADJUSTMENTS_MULTIPLE } from 'actions/sections';
import { GET_ASSIGNMENT_STUDENT_ADJUSTMENTS } from 'actions/assignments';
import { GET_PLAN_STUDENT_ADDITIONAL_ADJUSTMENTS } from 'actions/plans';
var INITIAL_STATE = {
  adjustments: [],
  studentAdjustments: [],
  studentAdjustmentsByCourse: [],
  studentAdjustmentsBySubject: [],
  schoolAdjustments: [],
  createdAdjustment: null,
  assignment: null,
  plan: null,
  groupAdjustments: [],
  multipleGroupAdjustments: []
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  var studentAdjustments = _toConsumableArray(state.studentAdjustments);

  var studentAdjustmentsByCourse = _toConsumableArray(state.studentAdjustmentsByCourse);

  var studentAdjustmentsBySubject = _toConsumableArray(state.studentAdjustmentsBySubject);

  var schoolAdjustments = _toConsumableArray(state.schoolAdjustments);

  var assignmentAdjustments = JSON.parse(JSON.stringify(state.assignment));
  var planAdjustments = JSON.parse(JSON.stringify(state.plan));
  var groupAdjustments = JSON.parse(JSON.stringify(state.groupAdjustments));
  var multipleGroupAdjustments = JSON.parse(JSON.stringify(state.multipleGroupAdjustments));

  switch (action.type) {
    case GET_ADDITIONAL_ADJUSTMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        adjustments: action.payload
      });

    case GET_STUDENT_ADJUSTMENTS:
      if (action.payload != null) {
        return _objectSpread(_objectSpread({}, state), {}, {
          studentAdjustments: action.payload
        });
      }

      return state;

    case GET_STUDENT_ADJUSTMENTS_BY_COURSE:
      return _objectSpread(_objectSpread({}, state), {}, {
        studentAdjustmentsByCourse: action.payload
      });

    case GET_STUDENT_ADJUSTMENTS_BY_SUBJECT:
      return _objectSpread(_objectSpread({}, state), {}, {
        studentAdjustmentsBySubject: action.payload
      });

    case ADD_ADJUSTMENT_COMMENT:
      var foundAdjustment = studentAdjustments.findIndex(function (adj) {
        return adj.id == action.payload.studentAdjustmentID;
      });
      var foundCourseAdjustment = studentAdjustmentsByCourse.findIndex(function (adj) {
        return adj.id == action.payload.studentAdjustmentID;
      });
      var foundSubjectAdjustmentComment = studentAdjustmentsBySubject.findIndex(function (adj) {
        return adj.id == action.payload.studentAdjustmentID;
      });
      var foundSchoolAdjustmentComment = schoolAdjustments.findIndex(function (adj) {
        return adj.id == action.payload.studentAdjustmentID;
      });
      var foundGroupAdjustmentComment = groupAdjustments.findIndex(function (adj) {
        return adj.id == action.payload.studentAdjustmentID;
      });
      var foundAssignmentAdjustmentComment = -1;

      if (assignmentAdjustments != null) {
        foundAssignmentAdjustmentComment = assignmentAdjustments.findIndex(function (adj) {
          return adj.id == action.payload.studentAdjustmentID;
        });
      }

      var foundPlanAdjustmentComment = -1;

      if (planAdjustments != null) {
        foundPlanAdjustmentComment = planAdjustments.findIndex(function (adj) {
          return adj.id == action.payload.studentAdjustmentID;
        });
      }

      if (foundAdjustment > -1) {
        var newAdjustment = JSON.parse(JSON.stringify(studentAdjustments[foundAdjustment]));
        newAdjustment.comments.unshift(action.payload);
        studentAdjustments.splice(foundAdjustment, 1, newAdjustment);
      }

      if (foundCourseAdjustment > -1) {
        var newCourseAdjustment = JSON.parse(JSON.stringify(studentAdjustmentsByCourse[foundCourseAdjustment]));
        newCourseAdjustment.comments.unshift(action.payload);
        studentAdjustmentsByCourse.splice(foundCourseAdjustment, 1, newCourseAdjustment);
      }

      if (foundSubjectAdjustmentComment > -1) {
        var newSchoolAdjustment = JSON.parse(JSON.stringify(studentAdjustmentsBySubject[foundSubjectAdjustmentComment]));
        newSchoolAdjustment.comments.unshift(action.payload);
        studentAdjustmentsBySubject.splice(foundSubjectAdjustmentComment, 1, newSchoolAdjustment);
      }

      if (foundSchoolAdjustmentComment > -1) {
        var _newSchoolAdjustment = JSON.parse(JSON.stringify(schoolAdjustments[foundSchoolAdjustmentComment]));

        _newSchoolAdjustment.comments.unshift(action.payload);

        schoolAdjustments.splice(foundSchoolAdjustmentComment, 1, _newSchoolAdjustment);
      }

      if (foundAssignmentAdjustmentComment > -1) {
        var newAssignmentAdjustment = JSON.parse(JSON.stringify(assignmentAdjustments[foundAssignmentAdjustmentComment]));
        newAssignmentAdjustment.comments.unshift(action.payload);
        assignmentAdjustments.splice(foundAssignmentAdjustmentComment, 1, newAssignmentAdjustment);
      }

      if (foundPlanAdjustmentComment > -1) {
        var _newAssignmentAdjustment = JSON.parse(JSON.stringify(planAdjustments[foundPlanAdjustmentComment]));

        _newAssignmentAdjustment.comments.unshift(action.payload);

        assignmentAdjustments.splice(foundPlanAdjustmentComment, 1, _newAssignmentAdjustment);
      }

      if (foundGroupAdjustmentComment > -1) {
        var newGroupAdjustment = JSON.parse(JSON.stringify(groupAdjustments[foundGroupAdjustmentComment]));
        newGroupAdjustment.comments.unshift(action.payload);
        groupAdjustments.splice(foundGroupAdjustmentComment, 1, newGroupAdjustment);
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        studentAdjustments: studentAdjustments,
        studentAdjustmentsByCourse: studentAdjustmentsByCourse,
        studentAdjustmentsBySubject: studentAdjustmentsBySubject,
        schoolAdjustments: schoolAdjustments,
        assignment: assignmentAdjustments,
        plan: planAdjustments,
        groupAdjustments: groupAdjustments
      });

    case CREATE_STUDENT_ADJUSTMENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        studentAdjustments: [].concat(_toConsumableArray(state.studentAdjustments), [action.payload]),
        studentAdjustmentsBySubject: [].concat(_toConsumableArray(state.studentAdjustmentsBySubject), [action.payload])
      });

    case GET_SCHOOL_ADJUSTMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        schoolAdjustments: action.payload
      });

    case CREATE_ADDITIONAL_ADJUSTMENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        adjustments: [].concat(_toConsumableArray(state.adjustments), [action.payload]),
        createdAdjustment: action.payload
      });

    case SWITCH_ACTIVE_ADDITIONAL_ADJUSTMENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        adjustments: [].concat(_toConsumableArray(state.adjustments.filter(function (t) {
          return t.id != action.payload.id;
        })), [action.payload])
      });

    case UPDATE_ADDITIONAL_ADJUSTMENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        adjustments: [].concat(_toConsumableArray(state.adjustments.filter(function (t) {
          return t.id != action.payload.id;
        })), [action.payload]) //schoolAdjustments: [...state.schoolAdjustments.filter(t => t.id != action.payload.id), action.payload],

      });

    case UPDATE_STUDENT_ADJUSTMENT:
      if (assignmentAdjustments != null) {
        assignmentAdjustments = [].concat(_toConsumableArray(assignmentAdjustments.filter(function (t) {
          return t.id != action.payload.oldId;
        })), [action.payload]);
      }

      if (planAdjustments != null) {
        planAdjustments = [].concat(_toConsumableArray(planAdjustments.filter(function (t) {
          return t.id != action.payload.oldId;
        })), [action.payload]);
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        studentAdjustments: [].concat(_toConsumableArray(state.studentAdjustments.filter(function (t) {
          return t.id != action.payload.oldId;
        })), [action.payload]),
        schoolAdjustments: [].concat(_toConsumableArray(state.schoolAdjustments.filter(function (t) {
          return t.id != action.payload.oldId;
        })), [action.payload]),
        studentAdjustmentsByCourse: [].concat(_toConsumableArray(studentAdjustmentsByCourse.filter(function (t) {
          return t.id != action.payload.oldId;
        })), [action.payload]),
        studentAdjustmentsBySubject: [].concat(_toConsumableArray(studentAdjustmentsBySubject.filter(function (t) {
          return t.id != action.payload.oldId;
        })), [action.payload]),
        groupAdjustments: [].concat(_toConsumableArray(groupAdjustments.filter(function (t) {
          return t.id != action.payload.oldId;
        })), [action.payload]),
        multipleGroupAdjustments: [].concat(_toConsumableArray(multipleGroupAdjustments.filter(function (t) {
          return t.id != action.payload.oldId;
        })), [action.payload]),
        assignment: assignmentAdjustments,
        plan: planAdjustments
      });

    case DELETE_ADDITIONAL_ADJUSTMENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        adjustments: _toConsumableArray(state.adjustments.filter(function (t) {
          return t.id != action.payload;
        })),
        schoolAdjustments: _toConsumableArray(state.schoolAdjustments.filter(function (t) {
          return t.id != action.payload;
        }))
      });

    case DELETE_STUDENT_ADDITIONAL_ADJUSTMENT:
      var foundAdjustmentToBeDeleted = studentAdjustments.findIndex(function (adj) {
        return adj.id == action.payload;
      });
      var foundAdjustmentToBeDeletedFromSchool = schoolAdjustments.findIndex(function (adj) {
        return adj.id == action.payload;
      });
      var foundAdjustmentToBeDeletedFromCourse = studentAdjustmentsByCourse.findIndex(function (adj) {
        return adj.id == action.payload;
      });
      var foundAdjustmentToBeDeletedFromSubject = studentAdjustmentsBySubject.findIndex(function (adj) {
        return adj.id == action.payload;
      });
      var foundAdjustmentToBeDeletedFromAssignment = -1;
      var foundAdjustmentToBeDeletedFromPlan = -1;

      if (assignmentAdjustments != null) {
        foundAdjustmentToBeDeletedFromAssignment = assignmentAdjustments.findIndex(function (adj) {
          return adj.id == action.payload;
        });
      }

      if (planAdjustments != null) {
        foundAdjustmentToBeDeletedFromPlan = planAdjustments.findIndex(function (adj) {
          return adj.id == action.payload;
        });
      }

      if (foundAdjustmentToBeDeleted > -1) {
        if (studentAdjustments[foundAdjustmentToBeDeleted].status == 'DELETED') {
          studentAdjustments.splice(foundAdjustmentToBeDeleted, 1);
        } else {
          var _newAdjustment = _objectSpread({}, studentAdjustments[foundAdjustmentToBeDeleted]);

          _newAdjustment.status = 'DELETED';
          studentAdjustments.splice(foundAdjustmentToBeDeleted, 1, _newAdjustment);
        }
      }

      if (foundAdjustmentToBeDeletedFromSchool > -1) {
        if (schoolAdjustments[foundAdjustmentToBeDeletedFromSchool].status == 'DELETED') {
          schoolAdjustments.splice(foundAdjustmentToBeDeletedFromSchool, 1);
        } else {
          var _newAdjustment2 = _objectSpread({}, studentAdjustments[foundAdjustmentToBeDeletedFromSchool]);

          _newAdjustment2.status = 'DELETED';
          schoolAdjustments.splice(foundAdjustmentToBeDeletedFromSchool, 1, _newAdjustment2);
        }
      }

      if (foundAdjustmentToBeDeletedFromCourse > -1) {
        if (studentAdjustmentsByCourse[foundAdjustmentToBeDeletedFromCourse].status == 'DELETED') {
          studentAdjustmentsByCourse.splice(foundAdjustmentToBeDeletedFromCourse, 1);
        } else {
          var _newAdjustment3 = _objectSpread({}, studentAdjustmentsByCourse[foundAdjustmentToBeDeletedFromCourse]);

          _newAdjustment3.status = 'DELETED';
          studentAdjustmentsByCourse.splice(foundAdjustmentToBeDeletedFromCourse, 1, _newAdjustment3);
        }
      }

      if (foundAdjustmentToBeDeletedFromSubject > -1) {
        if (studentAdjustmentsBySubject[foundAdjustmentToBeDeletedFromSubject].status == 'DELETED') {
          studentAdjustmentsBySubject.splice(foundAdjustmentToBeDeletedFromSubject, 1);
        } else {
          var _newAdjustment4 = _objectSpread({}, studentAdjustmentsBySubject[foundAdjustmentToBeDeletedFromSubject]);

          _newAdjustment4.status = 'DELETED';
          studentAdjustmentsBySubject.splice(foundAdjustmentToBeDeletedFromSubject, 1, _newAdjustment4);
        }
      }

      if (foundAdjustmentToBeDeletedFromAssignment > -1) {
        if (assignmentAdjustments[foundAdjustmentToBeDeletedFromAssignment].status == 'DELETED') {
          assignmentAdjustments.splice(foundAdjustmentToBeDeletedFromAssignment, 1);
        } else {
          var _newAdjustment5 = _objectSpread({}, assignmentAdjustments[foundAdjustmentToBeDeletedFromAssignment]);

          _newAdjustment5.status = 'DELETED';
          assignmentAdjustments.splice(foundAdjustmentToBeDeletedFromAssignment, 1, _newAdjustment5);
        }
      }

      if (foundAdjustmentToBeDeletedFromPlan > -1) {
        if (planAdjustments[foundAdjustmentToBeDeletedFromPlan].status == 'DELETED') {
          planAdjustments.splice(foundAdjustmentToBeDeletedFromPlan, 1);
        } else {
          var _newAdjustment6 = _objectSpread({}, planAdjustments[foundAdjustmentToBeDeletedFromPlan]);

          _newAdjustment6.status = 'DELETED';
          assignmentAdjustments.splice(foundAdjustmentToBeDeletedFromPlan, 1, _newAdjustment6);
        }
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        studentAdjustments: studentAdjustments,
        schoolAdjustments: schoolAdjustments,
        studentAdjustmentsByCourse: studentAdjustmentsByCourse,
        studentAdjustmentsBySubject: studentAdjustmentsBySubject,
        assignment: assignmentAdjustments,
        plan: planAdjustments
      });

    case DELETE_STUDENT_ADDITIONAL_ADJUSTMENT_COMMENT:
      var studentAdjustment = studentAdjustments.findIndex(function (adj) {
        return adj.id == action.payload.studentAdjustmentID;
      });
      var foundCourseAdjustmentComment = studentAdjustmentsByCourse.findIndex(function (adj) {
        return adj.id == action.payload.studentAdjustmentID;
      });
      var foundSubjectAdjustmentCommentDelete = studentAdjustmentsBySubject.findIndex(function (adj) {
        return adj.id == action.payload.studentAdjustmentID;
      });
      var foundSchoolAdjustmentCommentDelete = schoolAdjustments.findIndex(function (adj) {
        return adj.id == action.payload.studentAdjustmentID;
      });
      var foundAssignmentAdjustmentCommentDelete = -1;

      if (assignmentAdjustments != null) {
        foundAssignmentAdjustmentCommentDelete = assignmentAdjustments.findIndex(function (adj) {
          return adj.id == action.payload.studentAdjustmentID;
        });
      }

      var foundPlanAdjustmentCommentDelete = -1;

      if (planAdjustments != null) {
        foundPlanAdjustmentCommentDelete = planAdjustments.findIndex(function (adj) {
          return adj.id == action.payload.studentAdjustmentID;
        });
      }

      var foundGroupAdjustmentCommentDelete = -1;

      if (groupAdjustments != null) {
        foundGroupAdjustmentCommentDelete = groupAdjustments.findIndex(function (adj) {
          return adj.id == action.payload.studentAdjustmentID;
        });
      }

      if (action.payload.status == 'DELETED') {
        if (studentAdjustment > -1) {
          var _newAdjustment7 = _objectSpread({}, studentAdjustments[studentAdjustment]);

          _newAdjustment7.comments = _newAdjustment7.comments.filter(function (t) {
            return t.id != action.payload.id;
          });
          studentAdjustments.splice(studentAdjustment, 1, _newAdjustment7);
        }

        if (foundCourseAdjustmentComment > -1) {
          var _newCourseAdjustment = _objectSpread({}, studentAdjustmentsByCourse[foundCourseAdjustmentComment]);

          _newCourseAdjustment.comments = _newCourseAdjustment.comments.filter(function (t) {
            return t.id != action.payload.id;
          });
          studentAdjustmentsByCourse.splice(foundCourseAdjustmentComment, 1, _newCourseAdjustment);
        }

        if (foundSchoolAdjustmentCommentDelete > -1) {
          var _newCourseAdjustment2 = _objectSpread({}, schoolAdjustments[foundSchoolAdjustmentCommentDelete]);

          _newCourseAdjustment2.comments = _newCourseAdjustment2.comments.filter(function (t) {
            return t.id != action.payload.id;
          });
          schoolAdjustments.splice(foundSchoolAdjustmentCommentDelete, 1, _newCourseAdjustment2);
        }

        if (foundSubjectAdjustmentCommentDelete > -1) {
          var _newCourseAdjustment3 = _objectSpread({}, studentAdjustmentsBySubject[foundSubjectAdjustmentCommentDelete]);

          _newCourseAdjustment3.comments = _newCourseAdjustment3.comments.filter(function (t) {
            return t.id != action.payload.id;
          });
          studentAdjustmentsBySubject.splice(foundSubjectAdjustmentCommentDelete, 1, _newCourseAdjustment3);
        }

        if (foundSchoolAdjustmentCommentDelete > -1) {
          var _newCourseAdjustment4 = _objectSpread({}, schoolAdjustments[foundSchoolAdjustmentCommentDelete]);

          _newCourseAdjustment4.comments = _newCourseAdjustment4.comments.filter(function (t) {
            return t.id != action.payload.id;
          });
          schoolAdjustments.splice(foundSchoolAdjustmentCommentDelete, 1, _newCourseAdjustment4);
        }

        if (foundAssignmentAdjustmentCommentDelete > -1) {
          var _newCourseAdjustment5 = _objectSpread({}, assignmentAdjustments[foundAssignmentAdjustmentCommentDelete]);

          _newCourseAdjustment5.comments = _newCourseAdjustment5.comments.filter(function (t) {
            return t.id != action.payload.id;
          });
          assignmentAdjustments.splice(foundAssignmentAdjustmentCommentDelete, 1, _newCourseAdjustment5);
        }

        if (foundPlanAdjustmentCommentDelete > -1) {
          var _newCourseAdjustment6 = _objectSpread({}, planAdjustments[foundPlanAdjustmentCommentDelete]);

          _newCourseAdjustment6.comments = _newCourseAdjustment6.comments.filter(function (t) {
            return t.id != action.payload.id;
          });
          planAdjustments.splice(foundPlanAdjustmentCommentDelete, 1, _newCourseAdjustment6);
        }

        if (foundGroupAdjustmentCommentDelete > -1) {
          var _newGroupAdjustment = _objectSpread({}, groupAdjustments[foundGroupAdjustmentCommentDelete]);

          _newGroupAdjustment.comments = _newGroupAdjustment.comments.filter(function (t) {
            return t.id != action.payload.id;
          });
          groupAdjustments.splice(foundGroupAdjustmentCommentDelete, 1, _newGroupAdjustment);
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          studentAdjustments: studentAdjustments,
          studentAdjustmentsByCourse: studentAdjustmentsByCourse,
          studentAdjustmentsBySubject: studentAdjustmentsBySubject,
          schoolAdjustments: schoolAdjustments,
          assignment: assignmentAdjustments,
          plan: planAdjustments,
          groupAdjustments: groupAdjustments
        });
      } else {
        if (studentAdjustment > -1) {
          var _newAdjustment8 = _objectSpread({}, studentAdjustments[studentAdjustment]);

          var newComment = _objectSpread({}, action.payload);

          newComment.status = 'DELETED';
          _newAdjustment8.comments = _newAdjustment8.comments.filter(function (t) {
            return t.id != action.payload.id;
          });

          _newAdjustment8.comments.push(newComment);

          studentAdjustments.splice(studentAdjustment, 1, _newAdjustment8);
        }

        if (foundCourseAdjustmentComment > -1) {
          var _newCourseAdjustment7 = _objectSpread({}, studentAdjustmentsByCourse[foundCourseAdjustmentComment]);

          var _newComment = _objectSpread({}, action.payload);

          _newComment.status = 'DELETED';
          _newCourseAdjustment7.comments = _newCourseAdjustment7.comments.filter(function (t) {
            return t.id != action.payload.id;
          });

          _newCourseAdjustment7.comments.push(_newComment);

          studentAdjustmentsByCourse.splice(foundCourseAdjustmentComment, 1, _newCourseAdjustment7);
        }

        if (foundSchoolAdjustmentCommentDelete > -1) {
          var _newCourseAdjustment8 = _objectSpread({}, schoolAdjustments[foundSchoolAdjustmentCommentDelete]);

          var _newComment2 = _objectSpread({}, action.payload);

          _newComment2.status = 'DELETED';
          _newCourseAdjustment8.comments = _newCourseAdjustment8.comments.filter(function (t) {
            return t.id != action.payload.id;
          });

          _newCourseAdjustment8.comments.push(_newComment2);

          schoolAdjustments.splice(foundSchoolAdjustmentCommentDelete, 1, _newCourseAdjustment8);
        }

        if (foundSubjectAdjustmentCommentDelete > -1) {
          var _newCourseAdjustment9 = _objectSpread({}, studentAdjustmentsBySubject[foundSubjectAdjustmentCommentDelete]);

          var _newComment3 = _objectSpread({}, action.payload);

          _newComment3.status = 'DELETED';
          _newCourseAdjustment9.comments = _newCourseAdjustment9.comments.filter(function (t) {
            return t.id != action.payload.id;
          });

          _newCourseAdjustment9.comments.push(_newComment3);

          studentAdjustmentsBySubject.splice(foundSubjectAdjustmentCommentDelete, 1, _newCourseAdjustment9);
        }

        if (foundAssignmentAdjustmentCommentDelete > -1) {
          var _newCourseAdjustment10 = _objectSpread({}, assignmentAdjustments[foundAssignmentAdjustmentCommentDelete]);

          var _newComment4 = _objectSpread({}, action.payload);

          _newComment4.status = 'DELETED';
          _newCourseAdjustment10.comments = _newCourseAdjustment10.comments.filter(function (t) {
            return t.id != action.payload.id;
          });

          _newCourseAdjustment10.comments.push(_newComment4);

          assignmentAdjustments.splice(foundAssignmentAdjustmentCommentDelete, 1, _newCourseAdjustment10);
        }

        if (foundPlanAdjustmentCommentDelete > -1) {
          var _newCourseAdjustment11 = _objectSpread({}, planAdjustments[foundPlanAdjustmentCommentDelete]);

          var _newComment5 = _objectSpread({}, action.payload);

          _newComment5.status = 'DELETED';
          _newCourseAdjustment11.comments = _newCourseAdjustment11.comments.filter(function (t) {
            return t.id != action.payload.id;
          });

          _newCourseAdjustment11.comments.push(_newComment5);

          planAdjustments.splice(foundPlanAdjustmentCommentDelete, 1, _newCourseAdjustment11);
        }

        if (foundGroupAdjustmentCommentDelete > -1) {
          var _newCourseAdjustment12 = _objectSpread({}, groupAdjustments[foundGroupAdjustmentCommentDelete]);

          var _newComment6 = _objectSpread({}, action.payload);

          _newComment6.status = 'DELETED';
          _newCourseAdjustment12.comments = _newCourseAdjustment12.comments.filter(function (t) {
            return t.id != action.payload.id;
          });

          _newCourseAdjustment12.comments.push(_newComment6);

          groupAdjustments.splice(foundGroupAdjustmentCommentDelete, 1, _newCourseAdjustment12);
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          studentAdjustments: studentAdjustments,
          studentAdjustmentsByCourse: studentAdjustmentsByCourse,
          studentAdjustmentsBySubject: studentAdjustmentsBySubject,
          schoolAdjustments: schoolAdjustments,
          assignment: assignmentAdjustments,
          plan: planAdjustments,
          groupAdjustments: groupAdjustments
        });
      }

    case UPDATE_STUDENT_ADDITIONAL_ADJUSTMENT_COMMENT:
      var studentAdjustmentObject = studentAdjustments.findIndex(function (adj) {
        return adj.id == action.payload.studentAdjustmentID;
      });
      var foundCourseAdjustmentCommentObject = studentAdjustmentsByCourse.findIndex(function (adj) {
        return adj.id == action.payload.studentAdjustmentID;
      });
      var foundSubjectAdjustmentCommentUpdate = studentAdjustmentsBySubject.findIndex(function (adj) {
        return adj.id == action.payload.studentAdjustmentID;
      });
      var foundSchoolAdjustmentCommentUpdate = schoolAdjustments.findIndex(function (adj) {
        return adj.id == action.payload.studentAdjustmentID;
      });
      var foundGroupAdjustmentCommentUpdate = groupAdjustments.findIndex(function (adj) {
        return adj.id == action.payload.studentAdjustmentID;
      });
      var foundAssignmentAdjustmentCommentUpdate = -1;

      if (assignmentAdjustments != null) {
        foundAssignmentAdjustmentCommentUpdate = assignmentAdjustments.findIndex(function (adj) {
          return adj.id == action.payload.studentAdjustmentID;
        });
      }

      var foundPlanAdjustmentCommentUpdate = -1;

      if (planAdjustments != null) {
        foundPlanAdjustmentCommentUpdate = planAdjustments.findIndex(function (adj) {
          return adj.id == action.payload.studentAdjustmentID;
        });
      }

      if (studentAdjustmentObject > -1) {
        var newAdjustmentTwo = _objectSpread({}, studentAdjustments[studentAdjustmentObject]);

        newAdjustmentTwo.comments = newAdjustmentTwo.comments.filter(function (t) {
          return t.id != action.payload.id;
        });
        newAdjustmentTwo.comments.push(action.payload);
        studentAdjustments.splice(studentAdjustmentObject, 1, newAdjustmentTwo);
      }

      if (foundCourseAdjustmentCommentObject > -1) {
        var _newAdjustmentTwo = _objectSpread({}, studentAdjustmentsByCourse[foundCourseAdjustmentCommentObject]);

        _newAdjustmentTwo.comments = _newAdjustmentTwo.comments.filter(function (t) {
          return t.id != action.payload.id;
        });

        _newAdjustmentTwo.comments.push(action.payload);

        studentAdjustmentsByCourse.splice(foundCourseAdjustmentCommentObject, 1, _newAdjustmentTwo);
      }

      if (foundSubjectAdjustmentCommentUpdate > -1) {
        var _newAdjustmentTwo2 = _objectSpread({}, studentAdjustmentsBySubject[foundSubjectAdjustmentCommentUpdate]);

        _newAdjustmentTwo2.comments = _newAdjustmentTwo2.comments.filter(function (t) {
          return t.id != action.payload.id;
        });

        _newAdjustmentTwo2.comments.push(action.payload);

        studentAdjustmentsBySubject.splice(foundSubjectAdjustmentCommentUpdate, 1, _newAdjustmentTwo2);
      }

      if (foundSchoolAdjustmentCommentUpdate > -1) {
        var _newAdjustmentTwo3 = _objectSpread({}, schoolAdjustments[foundSchoolAdjustmentCommentUpdate]);

        _newAdjustmentTwo3.comments = _newAdjustmentTwo3.comments.filter(function (t) {
          return t.id != action.payload.id;
        });

        _newAdjustmentTwo3.comments.push(action.payload);

        schoolAdjustments.splice(foundSchoolAdjustmentCommentUpdate, 1, _newAdjustmentTwo3);
      }

      if (foundAssignmentAdjustmentCommentUpdate > -1) {
        var _newAdjustmentTwo4 = _objectSpread({}, assignmentAdjustments[foundAssignmentAdjustmentCommentUpdate]);

        _newAdjustmentTwo4.comments = _newAdjustmentTwo4.comments.filter(function (t) {
          return t.id != action.payload.id;
        });

        _newAdjustmentTwo4.comments.push(action.payload);

        assignmentAdjustments.splice(foundAssignmentAdjustmentCommentUpdate, 1, _newAdjustmentTwo4);
      }

      if (foundPlanAdjustmentCommentUpdate > -1) {
        var _newAdjustmentTwo5 = _objectSpread({}, planAdjustments[foundPlanAdjustmentCommentUpdate]);

        _newAdjustmentTwo5.comments = _newAdjustmentTwo5.comments.filter(function (t) {
          return t.id != action.payload.id;
        });

        _newAdjustmentTwo5.comments.push(action.payload);

        planAdjustments.splice(foundPlanAdjustmentCommentUpdate, 1, _newAdjustmentTwo5);
      }

      if (foundGroupAdjustmentCommentUpdate > -1) {
        var _newAdjustmentTwo6 = _objectSpread({}, groupAdjustments[foundGroupAdjustmentCommentUpdate]);

        _newAdjustmentTwo6.comments = _newAdjustmentTwo6.comments.filter(function (t) {
          return t.id != action.payload.id;
        });

        _newAdjustmentTwo6.comments.push(action.payload);

        groupAdjustments.splice(foundGroupAdjustmentCommentUpdate, 1, _newAdjustmentTwo6);
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        studentAdjustments: studentAdjustments,
        studentAdjustmentsByCourse: studentAdjustmentsByCourse,
        studentAdjustmentsBySubject: studentAdjustmentsBySubject,
        schoolAdjustments: schoolAdjustments,
        assignment: assignmentAdjustments,
        plan: planAdjustments,
        groupAdjustments: groupAdjustments
      });

    case GET_ASSIGNMENT_STUDENT_ADJUSTMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        assignment: action.payload
      });

    case GET_PLAN_STUDENT_ADDITIONAL_ADJUSTMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        plan: action.payload
      });

    case GET_GROUP_STUDENT_ADJUSTMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        groupAdjustments: action.payload
      });

    case GET_GROUP_STUDENT_ADJUSTMENTS_MULTIPLE:
      if (action.payload != null) {
        var multipleAdjustments = state.multipleGroupAdjustments;
        action.payload.forEach(function (adjustment) {
          var found = multipleAdjustments.findIndex(function (adj) {
            return adj.id == adjustment.id;
          });
          if (found == -1) multipleAdjustments.push(adjustment);
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          multipleGroupAdjustments: multipleAdjustments
        });
      }

      return state;

    default:
      return state;
  }
}