import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

export var getConversationParticipants = function getConversationParticipants(conversation, user) {
  if (conversation == null) {
    return null;
  }

  var users = [];
  conversation.memberships.forEach(function (membership) {
    if (user != null && user.id == membership.membershipID) {
      return true;
    }

    if (membership.membershipType.indexOf('SECTION') > -1) {
      users.push(_objectSpread({
        id: membership.membershipID,
        membershipType: membership.membershipType
      }, membership.section));
    } else {
      users.push(_objectSpread({
        id: membership.membershipID,
        membershipType: membership.membershipType
      }, membership.user));
    }
  });
  return users;
};