import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import api from 'lib/api';
export var SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export var SEARCH_COURSE = 'SEARCH_COURSE';
export var SEARCH_SECTION = 'SEARCH_SECTION';
export var SEARCH_ASSIGNMENTS = 'SEARCH_ASSIGNMENTS';
export var SEARCH_PLANS = 'SEARCH_PLANS';
export var SEARCH_MENTOR_GROUPS = 'SEARCH_MENTOR_GROUPS';
export var SEARCH_EDUCATION_GROUPS = 'SEARCH_EDUCATION_GROUPS';
export var SEARCH_GROUPS = 'SEARCH_SEARCH_GROUPS';
export var SEARCH_MENTORS = 'SEARCH_MENTORS';
export var SEARCH_HALDOR_ASSIGNMENTS_IN_GROUP = 'SEARCH_HALDOR_ASSIGNMENTS_IN_GROUP';
export var SEARCH_MICROSOFT_ASSIGNMENTS_IN_GROUP = 'SEARCH_MICROSOFT_ASSIGNMENTS_IN_GROUP';
export var SEARCH_CLEAR_RESULTS = 'SEARCH_CLEAR_SEARCH_RESULTS';
export var SEARCH_STUDENTS = 'SEARCH_STUDENTS';
export var SEARCH_PEOPLE = 'SEARCH_PEOPLE';
export var SEARCH_STAFF = 'SEARCH_STAFF';
export var SEARCH_TEACHER = 'SEARCH_TEACHER';
export var SEARCH_GUARDIANS = 'SEARCH_GUARDIANS';
export var SEARCH_POSTS = 'SEARCH_POSTS';
export var SEARCH_ALL = 'SEARCH_ALL';
export var CLEAR_SEARCH = 'SEARCH_CLEAR_SEARCH';
export var SEARCH_MY_SECTIONS = 'SEARCH_MY_SECTIONS';
export var CLEAR_COURSE_SEARCH = 'SEARCH_CLEAR_COURSE_SEARCH';
export var setCurrentSearchTerm = function setCurrentSearchTerm(term) {
  return {
    type: SET_SEARCH_TERM,
    payload: term
  };
};
export var searchCourseByTitle = function searchCourseByTitle(title, schoolType, year, callback) {
  return {
    type: SEARCH_COURSE,
    payload: new Promise(function (resolve) {
      var url = "courses/search/".concat(title, "?");

      if (schoolType != null) {
        url += "typeOfSchooling=".concat(schoolType);
      }

      if (year != null) {
        url += "&year=".concat(year);
      }

      api.get(url).then(function (response) {
        resolve(response.data);

        if (callback != null) {
          callback(response.data);
        }
      });
    })
  };
};
export var clearCourseSearch = function clearCourseSearch() {
  return {
    type: CLEAR_COURSE_SEARCH,
    payload: true
  };
};
export var searchSectionByTitle = function searchSectionByTitle(title, filtercreated, callback) {
  return {
    type: SEARCH_SECTION,
    payload: new Promise(function (resolve) {
      api.get("sections?title=".concat(title, "&created=").concat(filtercreated)).then(function (response) {
        resolve(response.data);

        if (callback != null) {
          callback(response.data);
        }
      });
    })
  };
}; // state.assignments.search_results

export var searchAssignment = function searchAssignment(value) {
  return {
    type: SEARCH_ASSIGNMENTS,
    payload: new Promise(function (resolve) {
      api.get("assignments/search/".concat(value)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var searchMicrosoftAssignmentsInGroup = function searchMicrosoftAssignmentsInGroup(value, group) {
  return {
    type: SEARCH_MICROSOFT_ASSIGNMENTS_IN_GROUP,
    payload: new Promise(function (resolve) {
      api.get("assignments/search/?search=".concat(value, "&graphId=").concat(group, "&origin=MS_ASSIGNMENTS")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var searchHaldorAssignmentsInGroup = function searchHaldorAssignmentsInGroup(value, group) {
  return {
    type: SEARCH_HALDOR_ASSIGNMENTS_IN_GROUP,
    payload: new Promise(function (resolve) {
      api.get("assignments/search/?search=".concat(value, "&graphId=").concat(group, "&origin=HALDOR_ASSIGNMENTS")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var searchPlans = function searchPlans(value, group) {
  return {
    type: SEARCH_PLANS,
    payload: new Promise(function (resolve) {
      var url = "planning/search/".concat(value);

      if (group != null) {
        url += "?graphId=".concat(group);
      }

      api.get(url).then(function (response) {
        resolve(response.data);
      });
    })
  };
}; // state.Search.mentors

export var searchMentorGroups = function searchMentorGroups(query) {
  return {
    type: SEARCH_MENTOR_GROUPS,
    payload: new Promise(function (resolve) {
      api.get("users/me/sections/?query=".concat(query, "&filter=MENTOR_GROUP;")).then(function (response) {
        resolve(response.data);
      });
    })
  };
}; // state.Search.educationGroups

export var searchEducationGroups = function searchEducationGroups(query) {
  return {
    type: SEARCH_EDUCATION_GROUPS,
    payload: new Promise(function (resolve) {
      api.get("users/me/sections/?query=".concat(query, "&filter=EDUCATION_GROUP;")).then(function (response) {
        resolve(response.data);
      });
    })
  };
}; // state.Search.groups

export var searchGroups = function searchGroups(parameters) {
  return {
    type: SEARCH_GROUPS,
    payload: new Promise(function (resolve) {
      var url = '';

      var urlParams = _objectSpread({
        pageSize: 99,
        pageIndex: 1,
        filter: '',
        sortBy: 'TITLE_DESC'
      }, parameters);

      for (var key in urlParams) {
        url += "&".concat(key, "=").concat(urlParams[key]);
      }

      api.get("sections/search/?_=".concat(url)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var searchMySections = function searchMySections(parameters) {
  return {
    type: SEARCH_MY_SECTIONS,
    payload: new Promise(function (resolve) {
      var url = '';

      var urlParams = _objectSpread({
        pageSize: 99,
        pageIndex: 1,
        filter: '',
        sortBy: 'TITLE_DESC'
      }, parameters);

      for (var key in urlParams) {
        url += "&".concat(key, "=").concat(urlParams[key]);
      }

      api.get("users/me/sections?_=".concat(url)).then(function (response) {
        resolve(response.data);
      });
    })
  };
}; // state.Search.mentors

export var searchMentors = function searchMentors(query) {
  return {
    type: SEARCH_MENTORS,
    payload: new Promise(function (resolve) {
      api.put("users/?search=".concat(query, "&userType=mentor"), []).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var clearSearchResults = function clearSearchResults() {
  return {
    type: SEARCH_CLEAR_RESULTS,
    payload: true
  };
}; // state.Search.students

export var searchStudents = function searchStudents(query) {
  return {
    type: SEARCH_STUDENTS,
    payload: new Promise(function (resolve) {
      api.put("users/?search=".concat(query, "&userType=student"), []).then(function (response) {
        resolve(response.data);
      });
    })
  };
}; // state.Search.people

export var searchPeople = function searchPeople(query) {
  return {
    type: SEARCH_PEOPLE,
    payload: new Promise(function (resolve) {
      api.put("users/?search=".concat(query, "&userType=student,teacher"), []).then(function (response) {
        resolve(response.data);
      });
    })
  };
}; //usertype => guardians, staff (reducers, actions)

export function searchUsers(name, studentIds) {
  return {
    type: SEARCH_ALL,
    payload: new Promise(function (resolve) {
      var url = "users?search=".concat(name, "&userType=guardian,staff");

      if (name == '' || name == null) {
        resolve([]);
        return true;
      }

      api.put(url, studentIds).then(function (response) {
        resolve(response.data);
      });
    })
  };
} // state.Search.guardians

export var searchGuardians = function searchGuardians(query) {
  return {
    type: SEARCH_GUARDIANS,
    payload: new Promise(function (resolve) {
      api.get("users/?search=".concat(query, "&userType=guardian")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export function searchStaff(name) {
  return {
    type: SEARCH_STAFF,
    payload: new Promise(function (resolve) {
      var url = "users?search=".concat(name, "&userType=staff");

      if (name == '' || name == null) {
        resolve([]);
        return true;
      }

      api.put(url, []).then(function (response) {
        resolve(response.data);
      });
    })
  };
}
export function searchTeacher(name) {
  return {
    type: SEARCH_TEACHER,
    payload: new Promise(function (resolve) {
      var url = "users?query=".concat(name, "&filter=teacher&sortby=firstname");
      api.get(url).then(function (response) {
        resolve(response.data);
      });
    })
  };
}
export var searchPosts = function searchPosts(query) {
  return {
    type: SEARCH_POSTS,
    payload: new Promise(function (resolve) {
      api.get("posts/search/".concat(query)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var clearSearch = function clearSearch() {
  return {
    type: CLEAR_SEARCH,
    payload: true
  };
};