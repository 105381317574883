import DateTime from '_class/DateTime';
import api from 'lib/api';
export var PUT_DATA = 'PUT_DATA';
export var ADD_ERROR = 'ADD_ERROR';
export var CREATE_TASK = 'CREATE_TASK';
export var DELETE_REQUEST = 'DELETE_REQUEST';
export var postData = function postData(data, endpoint, callback) {
  return {
    type: CREATE_TASK,
    payload: new Promise(function (resolve) {
      api.post(endpoint, data).then(function (response) {
        resolve(response.data);

        if (callback != null) {
          callback(response.data);
        }
      });
    })
  };
};
export var putData = function putData(endpoint, data, callback) {
  return {
    type: PUT_DATA,
    payload: new Promise(function (resolve) {
      api.put(endpoint, data).then(function (response) {
        resolve(response.data);

        if (callback != null) {
          callback(response.data);
        }
      });
    })
  };
};
export function addError(message, type) {
  var errorType = type || 'warning';
  var error = {
    msg: message,
    type: errorType,
    target: 'API',
    time: new DateTime()
  };
  console.log('running action addError payload', error);
  return {
    type: ADD_ERROR,
    payload: error
  };
}
export var deleteRequest = function deleteRequest(endpoint, id, callback) {
  return {
    type: DELETE_REQUEST,
    payload: new Promise(function (resolve, reject) {
      api["delete"]("".concat(endpoint, "/").concat(id)).then(function (response) {
        if (response.status > 300) {
          reject(err);

          if (callback != null) {
            callback(null, err);
          }

          return false;
        }

        resolve(response.data);

        if (callback != null) {
          callback(response.data);
        }
      });
    })
  };
};