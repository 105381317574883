import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { SET_PAGE_ACTIONS, SET_PAGE_TITLE, ON_ROUTE_CHANGE, ON_ROUTE_BACK } from 'actions/header';
import { isSupported } from 'helpers/localstorage';
import { backButtonEnable } from 'helpers/teams';
var hydrateHistory = null;

if (isSupported()) {
  hydrateHistory = localStorage.getItem('history') != null ? JSON.parse(localStorage.getItem('history')) : null;
}

var pathname = window.location.pathname;

if (pathname.indexOf('/landingpage') > -1) {
  pathname = pathname.replace('/landingpage', '');
} // Init history with current route as first item


var history = [pathname];

if (backButtonEnable() && hydrateHistory != null && hydrateHistory.length > 0) {
  // Only load history from local storage if on page that should show back button
  if (hydrateHistory[0] == pathname) {
    // If first item in hydrated history matches current route, set hydrated history as history
    history = hydrateHistory;
  } else {
    // If it doesnt match we clear the item since we dont need the data
    localStorage.removeItem('history');
  }
}

if (!backButtonEnable() && isSupported()) {
  // If user loads on a page that shouldnt display the back button we clear history item in LS to avoid big items
  localStorage.removeItem('history');
}

var INITIAL_STATE = {
  actions: null,
  title: null,
  backButton: backButtonEnable() && history.length > 1,
  pathname: pathname,
  history: history
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_PAGE_ACTIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        actions: action.payload
      });

    case SET_PAGE_TITLE:
      return _objectSpread(_objectSpread({}, state), {}, {
        title: action.payload
      });

    case ON_ROUTE_CHANGE:
      // Dispatched from routes/index.js
      if (window.location.pathname == state.pathname) {
        return state;
      }

      var _history = JSON.parse(localStorage.getItem('history'));

      if (_history == null) {
        _history = [pathname];
      }

      if (_history[0] != window.location.pathname) {
        _history.unshift(window.location.pathname);
      }

      if (_history.length > 0) {
        localStorage.setItem('history', JSON.stringify(_history));
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        title: null,
        actions: null,
        history: _history,
        pathname: window.location.pathname,
        backButton: backButtonEnable() && _history.length > 1
      });

    case ON_ROUTE_BACK:
      var newHistory = JSON.parse(localStorage.getItem('history'));

      if (newHistory == null) {
        newHistory = [pathname];
      }

      newHistory.shift();

      if (newHistory.length > 0) {
        localStorage.setItem('history', JSON.stringify(newHistory));
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        history: newHistory
      });

    default:
      return state;
  }
}