import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

export var GetGroupedByStudent = function GetGroupedByStudent(adjustments) {
  var filterStatus = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var subjects = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var groupedBy = [];

  if (adjustments === undefined || adjustments == null || adjustments.length <= 0) {
    return groupedBy;
  }

  if (filterStatus != null) {
    adjustments = adjustments.filter(function (adjustment) {
      return adjustment.status == filterStatus;
    });
  }

  adjustments.forEach(function (adjustment) {
    var foundAdjustment = groupedBy.find(function (t) {
      var _adjustment$additiona;

      return t.id == (adjustment === null || adjustment === void 0 ? void 0 : (_adjustment$additiona = adjustment.additionalAdjustment) === null || _adjustment$additiona === void 0 ? void 0 : _adjustment$additiona.id);
    });

    var student = _objectSpread({}, adjustment.student);

    if (foundAdjustment != null) {
      var foundStudent = foundAdjustment.items.find(function (t) {
        return t.userId == adjustment.studentID;
      });

      if (foundStudent != null) {
        foundStudent.items.push(adjustment);
      } else {
        student.items = [];
        student.items.push(adjustment);
        foundAdjustment.items.push(student);
      }
    } else {
      if (adjustment.additionalAdjustment != null) {
        var obj = {
          id: adjustment.additionalAdjustment.id,
          type: adjustment.additionalAdjustment.type,
          title: adjustment.additionalAdjustment.title,
          description: adjustment.additionalAdjustment.description,
          subTitle: adjustment.additionalAdjustment.subTitle,
          status: adjustment.additionalAdjustment.status,
          deletable: adjustment.additionalAdjustment.deletable,
          items: []
        };
        student.items = [];
        student.items.push(adjustment);
        obj.items.push(student);
        groupedBy.push(obj);
      }
    }
  });

  if (subjects != null) {
    groupedBy = groupedBy.filter(function (additionalAdjustment) {
      additionalAdjustment.items = additionalAdjustment.items.filter(function (student) {
        // Remove item if no adjustments containing courses exists
        if (student.items == null) {
          return false;
        }

        return student.items.find(function (adjustment) {
          if (adjustment == null || adjustment.subjects == null) {
            return false;
          }

          return adjustment.subjects.find(function (subject) {
            return subjects.indexOf(subject.id) > -1;
          }) != null;
        }) != null;
      });
      return additionalAdjustment.items.length > 0;
    });
  }

  groupedBy = groupedBy.sort(function (a, b) {
    return a.title.toUpperCase() > b.title.toUpperCase() ? 1 : b.title.toUpperCase() > a.title.toUpperCase() ? -1 : 0;
  });
  return groupedBy;
};
export var AddUnusedAdditionalAdjustments = function AddUnusedAdditionalAdjustments(arrayToAddIn, allAdjustments) {
  allAdjustments.forEach(function (adjustment) {
    var found = arrayToAddIn.find(function (t) {
      return t.id == adjustment.id;
    });

    if (found == null) {
      var obj = {
        id: adjustment.id,
        title: adjustment.title,
        type: adjustment.type,
        subTitle: adjustment.subTitle,
        status: adjustment.status,
        description: adjustment.description,
        deletable: adjustment.deletable,
        items: []
      };
      arrayToAddIn.push(obj);
    }
  });
  arrayToAddIn.sort(function (a, b) {
    return a.title.toUpperCase() > b.title.toUpperCase() ? 1 : b.title.toUpperCase() > a.title.toUpperCase() ? -1 : 0;
  });
  return arrayToAddIn;
};