import api from 'lib/api';
export var GET_TAGS = 'TAGS_GET_TAGS';
export var ADD_TAGS_ON_FILE = 'TAGS_ADD_TAGS_ON_FILES';
export var REMOVE_TAG_ON_FILE = 'TAGS_REMOVE_TAG_ON_FILES';
export var getTags = function getTags() {
  return {
    type: GET_TAGS,
    payload: new Promise(function (resolve) {
      api.get("").then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var addTagsOnFile = function addTagsOnFile(fileId, tags) {
  return {
    type: ADD_TAGS_ON_FILE,
    payload: new Promise(function (resolve, reject) {
      api.post("fileItems/".concat(fileId, "/tags"), tags).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var removeTagOnFile = function removeTagOnFile(fileId, tagId) {
  return {
    type: REMOVE_TAG_ON_FILE,
    payload: new Promise(function (resolve) {
      api["delete"]("fileItems/".concat(fileId, "/tags/").concat(tagId)).then(function (response) {
        resolve(1);
      });
    })
  };
};